import styled from "styled-components";
import { CollapseThemeType } from "../../types";

type CollapseStylesType = {
  open?: boolean;
  styles: CollapseThemeType;
};

export const CollapseHeader = styled.div.attrs(() => ({
  className: "bcr-collapse-header",
}))<CollapseStylesType>`
  color: ${(props) => props.styles?.header?.color};
  font-size: ${(props) => (props.styles?.header ? props.styles.header["font-size"] : "1rem")};
  background: ${(props) => (props.styles?.header ? props.styles.header.background : props.theme["white"])};
  border: ${(props) => (props.styles?.header ? props.styles.header.border : "none")};
  padding: ${(props) => (props.styles?.header ? props.styles?.header.padding : 0)};
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const CollapseBody = styled.div.attrs(() => ({
  className: "bcr-collapse-body",
}))<CollapseStylesType>`
  color: ${(props) => props.styles?.body?.color};
  font-size: ${(props) => (props.styles?.body ? props.styles.body["font-size"] : "1rem")};
  background: ${(props) => (props.styles?.body ? props.styles.body.background : props.theme["white"])};
  border: ${(props) => (props.styles?.body ? props.styles.body.border : "none")};
  padding: ${(props) => (props.styles?.body ? props.styles?.body.padding : 0)};
  width: 100%;
  overflow: hidden;
`;
