import React, { createContext, useContext, useState } from "react";

type CollapseContextType = {
  open: object;
  setOpen: (value: Record<string, any>) => void;
  singleOpen: boolean;
  animation: boolean;
  setSingleOpen: (value: boolean) => void;
  register: (key: string, startOpen?: boolean) => void;
  toggle: (key: string, override?: boolean) => void;
  setAnimation: (value: boolean) => void;
};

const CollapseContext = createContext<CollapseContextType | undefined>(undefined);

const CollapseProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState({});
  const [singleOpen, setSingleOpen] = useState(false);
  const [animation, setAnimation] = useState(true);

  const toggle = (key: string, override?: boolean): void => {
    const newStatus: boolean = override ? override : !open[key];
    const newOpen: object = newStatus && singleOpen ? { [key]: newStatus } : { ...open, [key]: newStatus };
    setOpen(newOpen);
  };

  const register = (key: string, startOpen?: boolean) => {
    setOpen({
      ...open,
      [key]: !!startOpen,
    });
  };

  return (
    <CollapseContext.Provider
      value={{ open, singleOpen, setSingleOpen, animation, toggle, register, setAnimation, setOpen }}
    >
      {children}
    </CollapseContext.Provider>
  );
};

const useCollapse = (): CollapseContextType => {
  const context = useContext<CollapseContextType | undefined>(CollapseContext);
  if (!context) {
    throw new Error("You must use a collapse outside of all Collapse Items!");
  }
  return context;
};

export { CollapseProvider, useCollapse };
