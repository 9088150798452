import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { IconThemeType } from "../../types";
import { Icon, IconProps } from "../index";

const IconAdorner: React.FC<IconProps> = ({ styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: IconThemeType = { ...Theme.iconAdorner, ...styles };
  return <Icon {...rest} styles={StylesOverride} />;
};

export default IconAdorner;
