import { ValueOpt } from "best-common-react";
import { GameDataDTO } from "./DailyAssignmentTypes";
import { PlayCategory } from "./SettingsTypes";

export const Statuses = {
  IDLE: "IDLE",
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
};
export type Status = "IDLE" | "FETCHING" | "FETCHED" | "ERROR";

export interface LookupData {
  id?: string | number;
  personId?: string;
  name: string;
}

export interface Lookup {
  status: Status;
  error: string;
  // TODO: How to make this dynamic e.g. [key: string]: LookupData[]
  teams: Team[];
  positions: Position[];
  operations: Operation[];
  category: PlayCategory[];
  games: Record<string, GameDataDTO[]>;
  stations: number[];
  venueInfo: VenueInfo[];
}

export interface Team extends LookupData {
  orgId: number;
  code: string;
  abbreviation: string;
}

export interface Position extends LookupData {
  value: string;
}

export type Operation = LookupData;

export interface Game {
  gamePk: string;
  teams: Teams;
  gameDate: string;
  status?: GameStatus;
  venue?: Venue;
  gameType: string;
}

export type Venue = LookupData;

export interface Teams {
  home: TeamHomeAway;
  away: TeamHomeAway;
}

export interface TeamHomeAway {
  team: Team;
}

export interface GameStatus {
  detailedState: string;
}

export interface VenueInfo {
  id: number;
  orgId: number;
  venueId: number;
  jkAudioPhone: string;
  venue: string;
}

export interface ValueKeyOpt<T> extends ValueOpt<T> {
  key: string;
}
