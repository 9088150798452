export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  if (removed) {
    result.splice(endIndex, 0, removed);
  }

  return result;
}

export function addToList<T>(list: T[], item: T, index = 0): T[] {
  const result = [...list];
  if (!index) {
    result.unshift(item);
  } else {
    result.splice(index, 0, item);
  }

  return result;
}

export function removeFromList<T>(list: T[], index = 0): T[] {
  const result = [...list];
  result.splice(index, 1);
  return result;
}

/**
 * Monkey patch: this sets the max width of the dragged DOM element
 * to something arbitrarily small (200px). This is useful
 * when dragging to a different list that is significantly
 * smaller than the source list
 */
type HandleProps = {
  onMouseDown: (e: EventTarget) => void;
};

export const draggableMouseDown = (id: string, dragHandleProps: HandleProps, size = 200) => {
  const { onMouseDown } = dragHandleProps || {};

  return (e: EventTarget) => {
    if (!onMouseDown) return;

    const el = document.getElementById(id);
    if (el) el.style.maxWidth = `${size}px`;

    onMouseDown(e);
  };
};

/**
 * Monkey patch: resets the maxWidth of the particular element
 */
export const draggableMouseUp = (id: string): (() => void) => {
  return () => {
    const el = document.getElementById(id);
    if (el) el.style.maxWidth = `initial`;
  };
};
