import styled from "styled-components";
import { HrThemeType } from "../../types";

type HrStylesProps = {
  styles: HrThemeType;
};

export const StyledHr = styled.hr<HrStylesProps>`
  &&& {
    color: ${(props) => props.styles.color};
    opacity: 1;
  }
`;
