import styled from "styled-components";
import { ModalThemeType } from "../../../types";

type BackdropStyleProps = {
  styles: ModalThemeType;
};

// HEXA - #RRGGBBAA
// To Calculate AA we need to take our passed in backdropOpacity (e.g. 0.75) and convert to base 16 hex value
export const Backdrop = styled.div.attrs(() => ({
  id: "bcd-modal-backdrop",
}))<BackdropStyleProps>`
  overflow: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) =>
    props.styles.backdropOpacity
      ? `${props.styles.backdrop}${Math.round(props.styles.backdropOpacity * 255).toString(16)}`
      : props.styles.backdrop};
  z-index: 1055;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export type ModalSizes = "sm" | "md" | "lg" | "xl";

type ModalStyles = {
  size?: ModalSizes;
};

const getSize = (size?: ModalSizes): string => {
  switch (size) {
    case "xl":
      return "60rem";
    case "lg":
      return "50rem";
    case "sm":
      return "30rem";
    case "md":
    default:
      return "40rem";
  }
};

export const ModalDialog = styled.div.attrs(() => ({
  className: "bcr-modal-dialog",
}))<ModalStyles>`
  &&& {
    width: ${(props) => getSize(props.size)};
  }
`;
