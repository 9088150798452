import styled from "styled-components";
import { NavigationValueThemeType } from "../../../types";

type DropdownItemStyleProps = {
  styles: NavigationValueThemeType;
};

const DropdownItem = styled.a.attrs(() => ({
  className: "dropdown-item",
  id: "best-dropdown-item",
}))<DropdownItemStyleProps>`
  &&& {
    background-color: #ffffff;
    color: ${(props) => props.styles.color};
    &:hover {
      cursor: pointer;
      color: ${(props) => props.styles?.hover?.color};
    }
  }
`;

export default DropdownItem;
