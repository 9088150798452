import styled from "styled-components";
import { InputThemeType, ToggleButtonsThemeType } from "../../../types";

type ToggleButtonStylesProps = {
  styles: ToggleButtonsThemeType;
  input: InputThemeType;
};

export const ToggleButtonGroup = styled.div<ToggleButtonStylesProps>`
  display: inline-flex;
  gap: 6px;
  padding: ${(props) => (props.styles.padding !== undefined ? props.styles.padding : "6px")};
  background: ${(props) => props.styles.background};
  border: ${(props) => props.styles.border};
  height: ${(props) => (!!props.styles.height ? props.styles.height : "auto")};
  border-radius: 0.25rem;

  &.is-invalid {
    border-color: ${(props) => props.input.required!.color} !important;
  }
`;
