import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconAdorner from "../../../components/icon/IconAdorner";
import { useDevices, useTheme } from "../../../hooks";
import { Typography } from "../../../layout";
import { DateInputThemeType } from "../../../types";
import { defaultMonthsValueOptions, MonthsValueOptions } from "../../../types/DateTypes";
import { BaseInputProps } from "../../../types/InputTypes";
import {
  calculateMaxDay,
  calculateMaxMonth,
  calculateMaxYear,
  DATE_FORMATTER_STR,
  DATE_FORMATTER_STR_WITH_TIME,
  formatDate,
  HTML5_DATETIME_STRING,
  HTML5_DATE_STRING,
  isAfter,
  isBefore,
} from "../../../utils";
import ValidationMessages from "../../validation/ValidationMessages";
import Input from "../input/Input";
import Label from "../label/Label";
import { Select, ValueOpt } from "../select";
import "./DateInput.css";
import { DatePickerStylesWrapper, SelectsHolder } from "./DateInputStyles";

export const DATE_FORMAT_STR = "MM/dd/yyyy";
export const DATE_FORMAT_STR_WITH_TIME = "MM/dd/yyyy hh:mm a";

export type AsSelectPlaceholders = {
  day: string;
  month: string;
  year: string;
};

const defaultAsSelectPlaceholder: AsSelectPlaceholders = {
  day: "day",
  month: "month",
  year: "year",
};

export type DateInputProps = BaseInputProps & {
  value?: Date | "N/A";
  onChange: (value?: Date | "N/A") => void;
  format?: string;
  minDate?: Date;
  maxDate?: Date;
  yearOnly?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  asSelects?: boolean;
  asSelectsPlaceholders?: AsSelectPlaceholders;
  monthsValueOptions?: MonthsValueOptions;
  styles?: DateInputThemeType;
  inline?: boolean;
  invalidDateMessage?: string;
  clearable?: boolean;
  renderCustomHeader?: (params: ReactDatePickerCustomHeaderProps) => React.ReactNode;
};

export const DateInput: React.FC<DateInputProps> = ({
  id,
  value,
  onChange,
  format,
  label,
  className = "",
  yearOnly = false,
  required = false,
  disabled = false,
  readOnly = false,
  missed = false,
  minDate,
  maxDate,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  timeIntervals = 15,
  asSelects = false,
  asSelectsPlaceholders = defaultAsSelectPlaceholder,
  monthsValueOptions = defaultMonthsValueOptions,
  styles,
  gutterBottom = false,
  inline = false,
  invalidDateMessage = "Invalid Date",
  showNA = false,
  clearable = false,
  renderCustomHeader,
}) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.datepicker, ...styles };
  const { isMobile, isComputer } = useDevices();
  const datePicker = useRef<DatePicker | null>(null);
  const [validDate, setValidDate] = useState<boolean>(true);
  const [daySelectValue, setDaySelectValue] = useState<ValueOpt<number> | undefined>(undefined);
  const [monthSelectValue, setMonthSelectValue] = useState<ValueOpt<string> | undefined>(undefined);
  const [yearSelectValue, setYearSelectValue] = useState<ValueOpt<number> | undefined>(undefined);
  const [yearOptions, setYearOptions] = useState<ValueOpt<number>[]>([]);
  const [monthOptions, setMonthOptions] = useState<ValueOpt<string>[]>([]);
  const [dayOptions, setDayOptions] = useState<ValueOpt<number>[]>([]);

  const iconClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!datePicker.current) {
      datePicker.current.setOpen(true);
    }
  };

  const formatForHTML5 = (value?: Date, showTimeSelect?: boolean): string => {
    return !!value
      ? !!showTimeSelect
        ? formatDate(value, HTML5_DATETIME_STRING)
        : formatDate(value, HTML5_DATE_STRING)
      : "";
  };

  const finalDateFormat = !!yearOnly
    ? "yyyy"
    : !!format
    ? format
    : showTimeSelect
    ? DATE_FORMAT_STR_WITH_TIME
    : DATE_FORMAT_STR;
  const mobileType = showTimeSelect ? "datetime-local" : "date";
  const isInvalid = !!(className && className.includes("is-invalid"));

  const selectOnChange = (day?: ValueOpt<number>, month?: ValueOpt<string>, year?: ValueOpt<number>) => {
    if (!!day && !!month && !!year) {
      const newDate: Date = new Date();
      newDate.setDate(day.value);
      const monthIndex: number = monthsValueOptions?.findIndex((value) => value === month?.value);
      newDate.setMonth(monthIndex);
      newDate.setFullYear(year.value);
      if (!!maxDate && !!minDate) {
        if (isAfter(newDate, minDate) && isBefore(newDate, maxDate)) {
          onChange(newDate);
          setValidDate(true);
        } else {
          setValidDate(false);
        }
      } else if (!!maxDate) {
        if (isBefore(newDate, maxDate)) {
          onChange(newDate);
          setValidDate(true);
        } else {
          setValidDate(false);
        }
      } else if (!!minDate) {
        if (isAfter(newDate, minDate)) {
          onChange(newDate);
          setValidDate(true);
        } else {
          setValidDate(false);
        }
      } else {
        onChange(newDate);
        setValidDate(true);
      }
    } else {
      onChange(undefined);
      setValidDate(true);
    }
  };

  const changeDay = (day?: ValueOpt<number> | ValueOpt<number>[]) => {
    setDaySelectValue(day as ValueOpt<number>);
    selectOnChange(day as ValueOpt<number>, monthSelectValue, yearSelectValue);
  };

  const getDayValue = (value?: Date): ValueOpt<number> | undefined => {
    if (!!value) {
      const day = value.getDate();
      return dayOptions.find((d) => day === d.value);
    } else {
      return undefined;
    }
  };

  const changeMonth = (month?: ValueOpt<string> | ValueOpt<string>[]) => {
    setMonthSelectValue(month as ValueOpt<string>);
    selectOnChange(daySelectValue, month as ValueOpt<string>, yearSelectValue);
  };

  const getMonthValue = (value?: Date): ValueOpt<string> | undefined => {
    if (!!value) {
      const monthIndex = value.getMonth();
      const month = monthsValueOptions[monthIndex];
      return monthOptions.find((m) => month === m.value);
    } else {
      return undefined;
    }
  };

  const changeYear = (year?: ValueOpt<number> | ValueOpt<number>[]) => {
    setYearSelectValue(year as ValueOpt<number>);
    selectOnChange(daySelectValue, monthSelectValue, year as ValueOpt<number>);
  };

  const getYearValue = (value?: Date): ValueOpt<number> | undefined => {
    if (!!value) {
      const year = value.getFullYear();
      return yearOptions.find((y) => year === y.value);
    } else {
      return undefined;
    }
  };

  const onChangeLocal = (value: Date | null) => {
    onChange(!!value ? value : undefined);
  };

  const toggleNA = (value: boolean) => {
    if (value) {
      onChange("N/A");
    } else {
      if (asSelects) {
        onChange(new Date());
      } else {
        onChange(undefined);
      }
    }
  };

  const isDate: boolean = value instanceof Date;
  const isNA: boolean = showNA && !isDate && value === "N/A";

  const calculateSelectValues = (
    shouldFilter: boolean,
    monthsValueOptions: MonthsValueOptions,
    yearSelectValue?: ValueOpt<number>,
    monthSelectValue?: ValueOpt<string>,
    maxDate?: Date,
    minDate?: Date,
  ) => {
    if (value === "N/A") {
      return;
    } else {
      setMonthOptions(calculateMaxMonth(shouldFilter, monthsValueOptions, yearSelectValue, minDate, maxDate));
      setDayOptions(
        calculateMaxDay(shouldFilter, monthsValueOptions, yearSelectValue, monthSelectValue, minDate, maxDate),
      );
      setYearOptions(calculateMaxYear(shouldFilter, minDate, maxDate));
    }
  };

  useEffect(() => {
    if (asSelects && !!value && isDate) {
      setDaySelectValue(getDayValue(value as Date));
      setMonthSelectValue(getMonthValue(value as Date));
      setYearSelectValue(getYearValue(value as Date));
    }
  }, [value, asSelects, isDate]);

  useEffect(() => {
    if (!!asSelects) {
      //remove filtering for now
      calculateSelectValues(false, monthsValueOptions, yearSelectValue, monthSelectValue, maxDate, minDate);
    }
  }, [monthsValueOptions, maxDate, minDate, yearSelectValue, monthSelectValue, asSelects]);

  return (
    <div className={clsx("w-100", { "mb-2": gutterBottom })}>
      {!!label && (
        <Label
          htmlFor={id}
          required={required}
          missed={missed}
          showNA={showNA && !readOnly}
          isNA={isNA}
          onChange={toggleNA}
        >
          {label}
        </Label>
      )}
      <ValidationMessages
        validationMessages={[
          {
            type: "error",
            message: invalidDateMessage,
            validationFunction: () => !validDate,
          },
        ]}
      />
      {readOnly ? (
        <Typography id={id}>
          {isDate
            ? formatDate(value as Date, showTimeSelect ? DATE_FORMATTER_STR_WITH_TIME : DATE_FORMATTER_STR)
            : value}
        </Typography>
      ) : !asSelects ? (
        <div id="best-datepicker" className={clsx({ "mb-2": gutterBottom })}>
          {(isComputer || yearOnly) && (
            <DatePickerStylesWrapper>
              <DatePicker
                ref={datePicker}
                id={id}
                selected={isDate ? (value as Date) : undefined}
                onChange={onChangeLocal}
                required={required}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                dateFormat={finalDateFormat}
                disabled={disabled || isNA}
                className={className}
                peekNextMonth
                showMonthDropdown
                showYearDropdown={!yearOnly}
                showYearPicker={yearOnly}
                dropdownMode="select"
                minDate={minDate}
                maxDate={maxDate}
                inline={inline}
                isClearable={clearable}
                clearButtonClassName="date-clearable"
                renderCustomHeader={renderCustomHeader}
                customInput={
                  <Input
                    id={`date-input-${id}`}
                    onChange={() => {}}
                    value=""
                    failedRequired={isInvalid}
                    readOnly={false}
                    missed={missed}
                    required={required}
                    endAdorner={
                      <IconAdorner
                        iconName={showTimeSelectOnly ? "fa-clock" : "fa-calendar-alt"}
                        styles={{
                          color: StylesOverride.input.actionColor,
                          hoverColor: StylesOverride.input.actionHoverColor,
                        }}
                        onClick={iconClicked}
                        disabled={disabled}
                      />
                    }
                  />
                }
              />
            </DatePickerStylesWrapper>
          )}
          {isMobile && !yearOnly && (
            <Input
              id={id}
              type={mobileType}
              required={required}
              missed={missed}
              disabled={disabled || isNA}
              onChange={(value: Date) => {
                onChange(value as Date);
              }}
              value={isDate ? formatForHTML5(value as Date, !!showTimeSelect) : undefined}
              endAdorner={
                <IconAdorner
                  iconName="fa-calendar-alt"
                  styles={{ color: StylesOverride.input.actionColor }}
                  onClick={iconClicked}
                  disabled={disabled}
                />
              }
              clearable={clearable}
            />
          )}
        </div>
      ) : (
        <div className={clsx({ "mb-2": gutterBottom })}>
          <SelectsHolder>
            <Select
              id="day"
              placeholder={asSelectsPlaceholders.day}
              className="me-2"
              value={daySelectValue}
              onChange={changeDay}
              options={dayOptions}
              disabled={disabled || isNA}
              clearable={clearable}
            />
            <Select
              id="month"
              placeholder={asSelectsPlaceholders.month}
              className="me-2"
              value={monthSelectValue}
              onChange={changeMonth}
              options={monthOptions}
              clearable={clearable}
              disabled={disabled || isNA}
            />
            <Select
              id="year"
              placeholder={asSelectsPlaceholders.year}
              className="me-2"
              value={yearSelectValue}
              onChange={changeYear}
              options={yearOptions}
              clearable={clearable}
              disabled={disabled || isNA}
            />
          </SelectsHolder>
        </div>
      )}
    </div>
  );
};

export default DateInput;
