import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { PaperThemeType } from "../../types";
import { Icon } from "../icon";
import { PaperStyled } from "./PaperStyles";

type PaperShowType = {
  visible: boolean;
  setShow: (value: boolean) => void;
};

type PaperProps = {
  show?: PaperShowType;
  className?: string;
  styles?: PaperThemeType;
};

const Paper: React.FC<PaperProps> = ({ children, show, className = "", styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: PaperThemeType = { ...Theme.paper, ...styles };

  return !show || !!show?.visible ? (
    <PaperStyled className={clsx("px-3 py-2", className)} styles={StylesOverride}>
      {!!show && (
        <div className="float-end">
          <Icon iconName="fa-times-circle" onClick={() => show.setShow(false)} />
        </div>
      )}
      {children}
    </PaperStyled>
  ) : null;
};

export default Paper;
