import { EventSourcePolyfill } from "event-source-polyfill";
import { useEffect, useState } from "react";

export type UseSseConfig = {
  sseUrl: string;
  channel: string;
  eventType?: string;
  groups?: string[];
  onOpen?: () => void;
  onClose?: () => void;
  onError?: (error: any) => void;
  accessToken?: string;
  idToken?: string;
};

type UseSseProps = {
  config: UseSseConfig;
};

type SseHeaders = { [name: string]: string };

const getHeader = (accessToken?: string, idToken?: string): SseHeaders => {
  const headers = {};
  if (!!accessToken) {
    headers["Authorization"] = `${accessToken}`;
  }
  if (!!idToken) {
    headers["x-id-token"] = `${idToken}`;
  }
  return headers;
};

const buildUrlParams = (channel: string, eventType: string, groups?: string[]): string => {
  let urlParams = `?channel=${channel}&eventType=${eventType}`;
  if (!!groups?.length) {
    urlParams += `&groups=${groups.join(",")}`;
  }
  return urlParams;
};

export function useSse<T>({ config }: UseSseProps): [data: T | null, closeSse: () => void] {
  const { sseUrl, channel, eventType = "message", groups, onOpen, onError, accessToken, idToken } = config;
  const [data, setData] = useState(null);
  const [sse, setSse] = useState<EventSourcePolyfill | null>(null);
  const [sseOpened, setSseOpened] = useState<boolean>(false);

  const closeSse = (): void => {
    if (!!sse) {
      sse.close();
    }
  };

  useEffect(() => {
    const headers: SseHeaders = getHeader(accessToken, idToken);
    try {
      const sse = new EventSourcePolyfill(sseUrl + buildUrlParams(channel, eventType, groups), { headers });
      setSse(sse);
      setSseOpened(true);
    } catch (e) {
      debugger;
      console.error("Error creating sse");
    }
    return () => {
      setSseOpened(false);
      if (!!sse) {
        sse?.close();
      }
    };
  }, [sseUrl, accessToken, idToken, groups, eventType]);

  useEffect(() => {
    if (!!sseOpened && !!sse) {
      sse.addEventListener(
        eventType,
        (e: any) => {
          setData(JSON.parse(e.data));
        },
        false,
      );

      sse.onopen = function () {
        if (onOpen && typeof onOpen === "function") {
          onOpen();
        }
      };

      sse.onerror = function (error: any) {
        console.error(error);
        try {
          if (!!sse && (error.status === 401 || error.status === 403)) {
            sse?.close();
          }
        } catch (e) {
          console.error(e);
        }
        if (onError && typeof onError === "function") {
          onError(error);
        }
      };
      setSse(sse);
    }
  }, [sseOpened, onOpen, onError]);

  return [data, closeSse];
}
