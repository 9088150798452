import { ValueOpt } from "best-common-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLookup } from "../hooks/useLookup";
import {
  primitiveToOption,
  primitivesToOptions,
  lookupToOption,
  lookupsToOptions,
  numberToOption,
  getTeamOption,
} from "../modules/common/ValueOptHelper";
import { Position, Team, VenueInfo } from "../types/LookupTypes";
import { PlayCategory } from "../types/SettingsTypes";

type DropdownsContext = {
  teamsOptions: ValueOpt<any>[];
  teamsAbbrOptions: ValueOpt<any>[];
  positionsOptions: ValueOpt<any>[];
  playCategoryOptions: ValueOpt<any>[];
  stationOptions: ValueOpt<any>[];
  venueOptions: ValueOpt<any>[];
};

const DropdownsContext = createContext<DropdownsContext>({
  teamsOptions: null,
  teamsAbbrOptions: null,
  positionsOptions: null,
  playCategoryOptions: null,
  stationOptions: null,
  venueOptions: null,
});

const AllOrgsOpt: ValueOpt<any> = { label: "All Orgs", value: {} };
const AllPositionsOpt: ValueOpt<any> = { label: "All Positions", value: {} };

const sortAlphabetic = (a: ValueOpt<any>, b: ValueOpt<any>) => {
  return a.label.toString().toLowerCase() < b.label.toString().toLowerCase()
    ? -1
    : a.label.toString().toLowerCase() > b.label.toString().toLowerCase()
    ? 1
    : 0;
};

const DropdownsProvider: React.FC = (props: any) => {
  const { teams } = useLookup("teams");
  const { positions } = useLookup("positions");
  const { category } = useLookup("category", "", "settings/playTypes");
  const { stations } = useLookup("stations");
  const { venueInfo } = useLookup("venueInfo");

  const [teamsOptions, setTeamsOptions] = useState<ValueOpt<any>[]>([]);
  const [teamsAbbrOptions, setTeamsAbbrOptions] = useState<ValueOpt<any>[]>([]);
  const [positionsOptions, setPositionsOptions] = useState<ValueOpt<any>[]>([]);
  const [playCategoryOptions, setPlayCategoryOptions] = useState<ValueOpt<any>[]>([]);
  const [stationOptions, setStationOptions] = useState<ValueOpt<any>[]>([]);
  const [venueOptions, setVenueOptions] = useState<ValueOpt<any>[]>([]);

  useEffect(() => {
    setTeamsOptions([
      ...teams.map((lk: Team): ValueOpt<any> => ({ label: lk.name, id: lk.id, value: lk.name })).sort(sortAlphabetic),
    ]);
  }, [teams]);

  useEffect(() => {
    setTeamsAbbrOptions([...teams.map(getTeamOption).sort(sortAlphabetic)]);
  }, [teams]);

  useEffect(() => {
    setPositionsOptions([
      ...positions
        .map((lk: Position): ValueOpt<any> => ({ label: lk.name, id: lk.id, value: lk.name }))
        .sort(sortAlphabetic),
    ]);
  }, [positions]);

  useEffect(() => {
    setPlayCategoryOptions([
      ...category
        .map((lk: PlayCategory): ValueOpt<any> => ({ label: lk.name, id: lk.id, value: lk.name }))
        .sort(sortAlphabetic),
    ]);
  }, [category]);

  useEffect(() => {
    setStationOptions([
      ...stations.map((lk: number): ValueOpt<any> => numberToOption(lk)).sort((a, b) => a.value - b.value),
    ]);
  }, [stations]);

  useEffect(() => {
    setVenueOptions([
      ...venueInfo
        .map((lk: VenueInfo): ValueOpt<any> => ({ label: lk.venue, id: lk.id, value: lk.venue, data: lk }))
        .sort(sortAlphabetic),
    ]);
  }, [venueInfo]);

  return (
    <DropdownsContext.Provider
      value={{
        teamsOptions,
        teamsAbbrOptions,
        positionsOptions,
        playCategoryOptions,
        stationOptions,
        venueOptions,
      }}
      {...props}
    />
  );
};

const useDropdowns = (): DropdownsContext => {
  const context = useContext<DropdownsContext>(DropdownsContext);
  if (context === undefined) {
    throw new Error(`useDropdowns must be used within a DropdownsProvider`);
  }
  return context;
};

export {
  AllOrgsOpt,
  AllPositionsOpt,
  DropdownsProvider,
  useDropdowns,
  sortAlphabetic,
  // TODO: Update downstream imports of these functions to just use ValueOptHelper directly
  primitiveToOption,
  primitivesToOptions,
  lookupToOption,
  lookupsToOptions,
};
