import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { TableThemeType } from "../../types";
import { TableTdInfoStyled } from "./TableStyles";

export type TableTdInfoProps = {
  /** overrideable styles */
  styles?: TableThemeType;
};

const TableTdInfo: React.FC<TableTdInfoProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: TableThemeType = { ...Theme.table, ...styles };

  return (
    <TableTdInfoStyled styles={StylesOverride} {...rest}>
      {children}
    </TableTdInfoStyled>
  );
};

export default TableTdInfo;
