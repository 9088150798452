import React from "react";
import { ClipLoader } from "react-spinners";
import { Container } from "./BCRSuspenseStyles";

const BCRSuspense: React.FC = () => {
  return (
    <Container>
      <ClipLoader />
    </Container>
  );
};

export default BCRSuspense;
