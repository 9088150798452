import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../hooks";
import { DateInputThemeType } from "../../../types";
import { defaultMonthsValueOptions, MonthsValueOptions } from "../../../types/DateTypes";
import { BaseInputProps } from "../../../types/InputTypes";
import { isString } from "../../../utils";
import { Checkbox } from "../checkbox";
import Label from "../label/Label";
import DatePickerInput from "./DateInput";
import { Spacer } from "./DateInputStyles";

export type DateRange = {
  start?: Date;
  end?: Date;
};

type DateRangeInputProps = BaseInputProps & {
  value: DateRange | "N/A";
  onChange: (value: DateRange | "N/A") => void;
  showTimeSelect?: boolean;
  asSelects?: boolean;
  monthsValueOptions?: MonthsValueOptions;
  minDate?: Date;
  maxDate?: Date;
  styles?: DateInputThemeType;
  inline?: boolean;
  rangeToggle?: boolean;
  stacked?: boolean;
  rangeLabel?: string;
};

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  id,
  label,
  value,
  onChange,
  showTimeSelect = false,
  className = "",
  required = false,
  disabled = false,
  missed = false,
  readOnly = false,
  asSelects = false,
  monthsValueOptions = defaultMonthsValueOptions,
  minDate,
  maxDate,
  gutterBottom = false,
  styles = {},
  inline = false,
  rangeToggle = false,
  stacked = false,
  showNA = false,
  rangeLabel = "Range",
  clearable = false,
}) => {
  const { Theme } = useTheme();
  const StylesOverride: DateInputThemeType = { ...Theme.datepicker, ...styles };
  const [showRange, setShowRange] = useState<boolean>(!rangeToggle);

  const updateStart = (newValue?: Date | "N/A") => {
    onChange({ start: newValue as Date, end: showRange ? (value as DateRange).end : undefined });
  };

  const updateEnd = (newValue?: Date | "N/A") => {
    onChange({ start: (value as DateRange).start, end: newValue as Date });
  };

  const isValidDateRange = (value: DateRange): boolean => {
    if (!!value?.start && !!value?.end) {
      return value.end!.getTime() > value.start!.getTime();
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (rangeToggle && !showRange) {
      onChange({ start: (value as DateRange)?.start, end: undefined });
    }
  }, [rangeToggle, showRange]);

  const toggleNA = (value: boolean) => {
    if (value) {
      onChange("N/A");
    } else {
      onChange({ start: undefined, end: undefined });
    }
  };

  const isDate = !isString(value);
  const isNA: boolean = showNA && !isDate && value === "N/A";
  const isInvalid: string = isDate && !isValidDateRange(value as DateRange) ? "is-invalid" : "";

  return (
    <div className={clsx("w-100", { "mb-2": gutterBottom })}>
      <div className="d-flex justify-content-between w-100">
        <Label
          className="w-100"
          htmlFor={id}
          required={required}
          missed={missed}
          showNA={showNA && !readOnly}
          isNA={isNA}
          onChange={toggleNA}
        >
          {label}
        </Label>
        {!!rangeToggle && (
          <Checkbox
            id={`${id}-range-toggle`}
            checked={showRange}
            onChange={setShowRange}
            label={rangeLabel}
            className="ms-2"
          />
        )}
      </div>
      <div className={clsx("d-flex align-items-center", { "flex-wrap": stacked }, isInvalid, className)} id={id}>
        <DatePickerInput
          id="date-range-start"
          value={isDate ? (value as DateRange).start : undefined}
          onChange={updateStart}
          minDate={minDate}
          maxDate={maxDate}
          className={clsx(isInvalid, className)}
          showTimeSelect={showTimeSelect}
          required={required}
          disabled={disabled || isNA}
          readOnly={readOnly}
          missed={missed}
          asSelects={asSelects}
          monthsValueOptions={monthsValueOptions}
          styles={StylesOverride}
          inline={inline}
          clearable={clearable}
        />
        {showRange && (
          <>
            {!stacked && <Spacer> - </Spacer>}
            <div className={clsx("w-100", { "mt-1": stacked })}>
              <DatePickerInput
                id="date-range-end"
                value={isDate ? (value as DateRange).end : undefined}
                onChange={updateEnd}
                minDate={minDate}
                maxDate={maxDate}
                className={clsx(isInvalid, className)}
                showTimeSelect={showTimeSelect}
                required={required}
                disabled={disabled || isNA}
                readOnly={readOnly}
                missed={missed}
                asSelects={asSelects}
                monthsValueOptions={monthsValueOptions}
                styles={StylesOverride}
                inline={inline}
                clearable={clearable}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DateRangeInput;
