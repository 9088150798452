import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StickyContainer = styled.div<{ isSticky: boolean }>`
  ${props =>
    props.isSticky
      ? `
    position: sticky;
    top: 0px;
  `
      : ""};
`;

const TOP = 60; // 60 is header height;

type Props = {
  scrollParent: HTMLElement;
};
const StickyComponent: React.FC<Props> = ({ scrollParent, children }) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  // need to capture the initial offset since the value changes when fixed is applied
  const [initialTopOffset, setInitialTopOffset] = useState<number>(null);
  const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!scrollParent) {
      const offset = stickyRef.current.offsetTop - scrollParent?.offsetTop;
      setInitialTopOffset(offset);
    }
  }, [scrollParent]);

  useEffect(() => {
    const determineIsSticky = () => {
      setIsSticky(scrollParent.scrollTop >= initialTopOffset);
    };
    if (!scrollParent) return;
    scrollParent.addEventListener("scroll", determineIsSticky);
    return () => {
      scrollParent.removeEventListener("scroll", determineIsSticky);
    };
  }, [scrollParent, initialTopOffset]);

  return (
    <div>
      <StickyContainer ref={stickyRef} isSticky={isSticky}>
        {children}
      </StickyContainer>
    </div>
  );
};

export default StickyComponent;
