import React from "react";

type RequiredProps = {
  validateFunc?: (value: any) => boolean;
  requiredText?: string;
  children: React.ReactElement;
  required?: boolean;
  isNA?: boolean;
};

type ElementProps = {
  className?: string;
  values?: any;
  value?: any;
  isMulti?: boolean;
  failedRequired?: boolean;
  required?: boolean;
};

export const validationDefaultFunction = (value: any) => {
  if (!value) {
    // number would only fall in here if 0, otherwise it's not a number and is invalid
    return typeof value === typeof -1;
  } else {
    if (typeof value === typeof "") {
      return !!value.trim().length;
    } else if (value instanceof Array) {
      return !!value.length;
    } else if (typeof value === typeof {}) {
      return !!Object.keys(value).length;
    } else if (typeof value === typeof -1) {
      //default value for not set
      return !!(value !== -1);
    } else {
      return true;
    }
  }
};

const RequiredInput: React.FC<RequiredProps> = ({
  children,
  validateFunc,
  requiredText,
  required = true,
  isNA = false,
}) => {
  const checkRequired = (
    input: React.ReactElement<ElementProps>,
    validateFunc: (value: any) => boolean,
    required: boolean,
    isNA: boolean,
  ): React.ReactElement => {
    const props = Object.assign({}, input.props);
    let className = !!input.props.className ? input.props.className : "";
    const value = input.props.value;
    if (required && !validateFunc(value) && !isNA) {
      className += " is-invalid";
      props.required = true;
      props.failedRequired = true;
    }
    props.className = className;
    return React.cloneElement(input, props);
  };

  return (
    <>
      {React.Children.map(children, (child: React.ReactElement<ElementProps>) =>
        checkRequired(child, validateFunc ? validateFunc : validationDefaultFunction, required, isNA),
      )}
      {requiredText ? <div className="invalid-feedback">{requiredText}</div> : null}
    </>
  );
};

export default RequiredInput;
