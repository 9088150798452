import React from "react";
import { useTheme } from "../../hooks";
import { TextThemeType } from "../../types";
import { Override } from "../../utils";
import TextElement, { TextElementProps } from "../text/TextElement";

type HintProps = Override<
  TextElementProps,
  {
    styles?: TextThemeType;
  }
>;

/**
 * Display a Hint
 */
const Hint: React.FC<HintProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: TextThemeType = { ...Theme.hint, ...styles };
  return (
    <TextElement styles={StylesOverride} {...rest}>
      {children}
    </TextElement>
  );
};

export default Hint;
