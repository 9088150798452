import styled from "styled-components";

export const BestHR = styled.div.attrs(() => ({
  className: "my-3",
  id: "best-hr",
}))`
  width: 100%;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1rem;
`;
