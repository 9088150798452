import styled from "styled-components";
import { TabSwitcherThemeType } from "types";

type TabSwitcherStylesType = {
  active?: boolean;
  dirty?: boolean;
  styles: TabSwitcherThemeType;
};

export const TabSwitcherContainerStyles = styled.div<TabSwitcherStylesType>`
  &&& {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const TabContainerStyles = styled.ul<TabSwitcherStylesType>`
  &&& {
    background-color: ${(props) => props.styles.container.background};
    list-style: none;
    display: flex;
    margin: 21px 0 0 0;
    padding: 0;
    flex-wrap: wrap;
  }
`;

export const TabStyles = styled.li<TabSwitcherStylesType>`
  &&& {
    min-width: 107px;
    height: 31px;
    padding: 7px 19px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    background: ${(props) => (props.active ? props.styles.tab.backgroundColor : undefined)};

    :first-child {
      border-top: ${(props) => `1px solid ${props.styles.tab.firstChildBorderColor}`};
      border-left: ${(props) => `1px solid ${props.styles.tab.firstChildBorderColor}`};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const IndicatorStyles = styled.span<TabSwitcherStylesType>`
  &&& {
    font-size: 12px;
    display: ${(props) => (props.dirty ? "inline-block" : "none")};
    color: ${(props) => props.styles.indicator.color};
  }
`;

export const ComponentContainerStyles = styled.div<TabSwitcherStylesType>`
  &&& {
    background-color: ${(props) => props.styles.componentContainer.backgroundColor};
    border-left: 1px solid ${(props) => props.styles.componentContainer.borderColor};
    border-right: 1px solid ${(props) => props.styles.componentContainer.borderColor};
    border-bottom: 1px solid ${(props) => props.styles.componentContainer.borderColor};
  }
`;
