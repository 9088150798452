import React from "react";
import { useAlerts } from "./AlertContext";
import AlertHolder from "./AlertHolder";

const AlertsFromProvider: React.FC = () => {
  const { alerts, removeAlert } = useAlerts();
  return <AlertHolder alerts={alerts} onRemove={removeAlert} />;
};

export default AlertsFromProvider;
