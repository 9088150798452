import { CountryCode } from "libphonenumber-js";
import React, { useEffect } from "react";
import IconAdorner from "../../../components/icon/IconAdorner";
import ValidationConstants from "../../../constants/ValidationConstants";
import { useTheme } from "../../../hooks";
import { ReadOnlyFormatterProps } from "../../../types/InputTypes";
import { convertPhoneValueAsYouType, isNumber, isValidPhoneNumber } from "../../../utils";
import { Input, InputProps } from "../input";
import { PhoneNumberDisplay } from "./index";

type PhoneNumberProps = InputProps & {
  value?: string;
  invalidMessage?: string;
  setValidPhoneNumber?: (value: boolean) => void;
  countryCode?: CountryCode;
};

export { CountryCode };

//export is valid phone number
const PhoneNumber: React.FC<PhoneNumberProps> = ({
  id,
  value = "",
  onChange,
  placeholder = "(XXX) XXX-XXXX",
  disabled = false,
  invalidMessage = "Invalid Phone Number",
  setValidPhoneNumber,
  countryCode = "US",
  ...rest
}) => {
  const { Theme } = useTheme();
  const disabledColor: string | undefined = Theme.icon.disabledColor;

  const handleChange = (value: string | number) => {
    if (isNumber(value)) {
      if (!isNaN(value as number)) {
        return;
      }
    } else {
      value = value.toString();
      if (value.includes("(") && !value.includes(")")) {
        value = value.substring(0, value.length - 1);
      }
      value = value.replace("(", "");
      value = value.replace(")", "");
      value = value.replace("-", "");
      value = value.replace(/\s/g, "");
      if (value.length <= 10) {
        onChange(value);
      }
    }
  };

  const convertValue = (value: string | number): string => {
    return convertPhoneValueAsYouType(value, countryCode);
  };

  useEffect(() => {
    const validPhoneNumber = isValidPhoneNumber(value, countryCode);
    if (!!setValidPhoneNumber) {
      setValidPhoneNumber(validPhoneNumber);
    }
  }, [value]);

  const ReadOnlyFormatter: React.FC<ReadOnlyFormatterProps> = ({ value, id }) => {
    return <PhoneNumberDisplay phoneNumber={value as string} countryCode={countryCode} />;
  };

  return (
    <Input
      id={id}
      validationMessages={[
        {
          type: ValidationConstants.TYPES.ERROR,
          message: invalidMessage,
          validationFunction: () => {
            return value !== "" && !isValidPhoneNumber(value, countryCode);
          },
        },
      ]}
      endAdorner={
        <IconAdorner
          iconName="fa-phone"
          disabled={disabled}
          styles={{ color: !value.length ? disabledColor : isValidPhoneNumber(value, countryCode) ? "green" : "red" }}
        />
      }
      value={convertValue(value)}
      placeholder={placeholder}
      onChange={handleChange}
      readOnlyFormatter={ReadOnlyFormatter}
      {...rest}
    />
  );
};

export default PhoneNumber;
