import React from "react";
import { useTheme } from "../../../hooks";
import { LabelThemeType } from "../../../types";
import { LabelStyles } from "../label/LabelStyles";
import { RequiredIndicatorStyled } from "./RequiredStyles";

type RequiredIndicatorProps = {
  /** Overrideable styles */
  styles?: LabelStyles;
};

const RequiredIndicator: React.FC<RequiredIndicatorProps> = ({ styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: LabelThemeType = { ...Theme.label, ...styles };
  return <RequiredIndicatorStyled styles={StylesOverride}>*</RequiredIndicatorStyled>;
};

export default RequiredIndicator;
