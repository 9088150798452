import React, { useState } from "react";
import { useTheme } from "../../../hooks";
import { CollapseThemeType } from "../../../types";
import Collapse from "../../collapse/Collapse";
import Icon from "../../icon/Icon";

type SectionProps = {
  startsOpen?: boolean;
  header: React.ReactNode;
  sectionKey: string;
  styles?: CollapseThemeType;
};

const Section: React.FC<SectionProps> = ({ children, header, sectionKey, startsOpen = true, styles }) => {
  const [open, setOpen] = useState<boolean>(startsOpen);
  const { Theme } = useTheme();
  const StylesOverride: CollapseThemeType = { ...Theme.section, ...styles };

  return (
    <Collapse singleOpen={false}>
      <Collapse.Item
        header={
          <div>
            <Icon iconName={open ? "fa-minus-circle" : "fa-plus-circle"} className="me-2" />
            {header}
          </div>
        }
        onToggle={setOpen}
        collapseKey={sectionKey}
        startsOpen={startsOpen}
        styles={StylesOverride}
      >
        {children}
      </Collapse.Item>
    </Collapse>
  );
};

export default Section;
