import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { HrThemeType } from "../../types";
import { StyledHr } from "./HrStyles";

type HrProps = {
  className?: string;
  styles?: HrThemeType;
};

const Hr: React.FC<HrProps> = ({ className = "", styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: HrThemeType = { ...Theme.hr, ...styles };
  return <StyledHr styles={StylesOverride} />;
};

export default Hr;
