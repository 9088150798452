import React from "react";
import { FormatterType } from "../../../../types";
import { DATE_FORMATTER_STR, formatDate } from "../../../../utils";
import { DataTableDataType } from "../DataTable";
import TextFormatter from "./TextFormatter";

const DateFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value, ...rest } = props;
  if (!!value) {
    const dateValue = formatDate(value as Date, DATE_FORMATTER_STR);
    return <TextFormatter value={dateValue} {...rest} />;
  } else {
    return <span />;
  }
};

export default DateFormatter;
