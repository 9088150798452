import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { TableThemeType } from "../../types";
import TableHeader, { TableHeaderProps } from "./TableHeader";
import { TableStyled } from "./TableStyles";
import TableTd, { TableTdProps } from "./TableTd";
import TableTdInfo, { TableTdInfoProps } from "./TableTdInfo";

type TableProps = {
  /** overrideable styles */
  styles?: TableThemeType;
};

export interface TableType extends React.FC<TableProps> {
  Header: React.FC<TableHeaderProps>;
  Td: React.FC<TableTdProps>;
  TdInfo: React.FC<TableTdInfoProps>;
}

const Table: TableType = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: TableThemeType = { ...Theme.table, ...styles };

  return (
    <TableStyled styles={StylesOverride} {...rest}>
      {children}
    </TableStyled>
  );
};

Table.Header = TableHeader;
Table.Td = TableTd;
Table.TdInfo = TableTdInfo;

export default Table;
