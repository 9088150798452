import styled from "styled-components";
import { TableThemeType, VerticalTableThemeType } from "../../types";

type TableStyles = {
  styles: TableThemeType;
};

export const TableStyled = styled.table.attrs(() => ({
  className: "table table-sm table-bordered",
  name: "best-table",
}))<TableStyles>`
  &&& {
    margin: 0 !important;

    font-weight: ${(props) => props.styles.table.fontWeight};
    font-size: ${(props) => props.styles.table.fontSize};
  }
`;

export const TableHeaderStyled = styled.thead.attrs(() => ({
  id: "best-table-header",
}))<TableStyles>`
  &&& {
    background: ${(props) => props.styles.th.background};
    color: ${(props) => props.styles.th.color};
    font-weight: ${(props) => props.styles.th.fontWeight};
    th {
      border: none;
    }
  }
`;

export const TableTdStyled = styled.td.attrs(() => ({
  id: "best-table-td",
}))<TableStyles>`
  &&& {
    background: ${(props) => props.styles.td.background};
    color: ${(props) => props.styles.td.color};
    font-weight: ${(props) => props.styles.td.fontWeight};
  }
`;

export const TableTdInfoStyled = styled.td.attrs(() => ({
  id: "best-table-td-info",
}))<TableStyles>`
  &&& {
    background: ${(props) => props.styles.tdInfo.background};
    font-weight: ${(props) => props.styles.tdInfo.fontWeight};
    color: ${(props) => props.styles.tdInfo.color};
    width: ${(props) => props.styles.tdInfo.width};
    vertical-align: middle;
    padding: 0.5rem;
`;

type VerticalTableStyles = {
  styles: VerticalTableThemeType;
};

export const VerticalTh = styled.th<VerticalTableStyles>`
  &&& {
    width: ${(props) => props.styles.th.width};
    color: ${(props) => props.styles.th.color};
    font-weight: ${(props) => props.styles.th.fontWeight};
    vertical-align: center;
  }
`;

export const VerticalTd = styled(TableTdInfoStyled)<VerticalTableStyles>`
  &&& {
    background: ${(props) => props.styles.td.background};
    color: ${(props) => props.styles.td.color};
    font-weight: ${(props) => props.styles.td.fontWeight};
  }
`;

export const VerticalHeaderTable = styled.table.attrs(() => ({
  className: "table table-sm",
  name: "best-table",
}))<VerticalTableStyles>`
  &&& {
    margin: 2px;
    padding-bottom: 4px;
  }
`;

export const VerticalHeaderTh = styled(VerticalTh).attrs(() => ({
  className: "BCR-Vertical-Header-Th",
}))<VerticalTableStyles>`
  &&& {
    width: ${(props) => props.styles.th.width};
    background: ${(props) => props.styles.th.background};
    color: ${(props) => props.styles.th.color};
    border: none;
    padding-bottom: 0;
    font-size: 11px;
    font-weight: 300;
  }
`;
