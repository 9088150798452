import React from "react";
import Button from "../../form/buttons/Button";
import Modal from "../modal/modal/Modal";

type ConfirmationModalProps = {
  show: boolean;
  onConfirm?: () => any;
  onCancel?: () => any;
  header?: React.ReactNode;
  headerClose?: boolean;
  body?: React.ReactNode;
  confirmButtonText?: React.ReactNode;
  cancelButtonText?: React.ReactNode;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onConfirm,
  onCancel,
  header = "Are you sure?",
  headerClose = false,
  body = "Are you sure you want to complete this action?",
  cancelButtonText = "No",
  confirmButtonText = "Yes",
}) => {
  return (
    <Modal show={show}>
      <Modal.Header close={headerClose ? onCancel : undefined}>{header}</Modal.Header>
      <Modal.Body>
        <div>{body}</div>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-end">
        <div>
          {!!onConfirm && (
            <Button className="me-2" onClick={onConfirm} variant="primary">
              {confirmButtonText}
            </Button>
          )}
          {!!onCancel && (
            <Button onClick={onCancel} variant="secondary">
              {cancelButtonText}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
