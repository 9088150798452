import React from "react";
import { DataTableDataType, TextFormatter } from "../../components";
import { FormatterType } from "../../types";
import { convertToPrettyBytes } from "../../utils";

const FileAttachmentSizeFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value, ...rest } = props;
  if (!!value) {
    const sizeString: string = convertToPrettyBytes(value);
    return <TextFormatter value={sizeString} {...rest} />;
  } else {
    return <span />;
  }
};

export default FileAttachmentSizeFormatter;
