import React, { useEffect, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { FileAttachmentThemeType } from "types";
import { Icon, Modal, Table, TableHeader, TableTd } from "../../components";
import { useTheme } from "../../hooks/useTheme";
import { getExtensionFromMimeType, isString } from "../../utils";
import Button from "../buttons/Button";
import { ColumnType } from "./FileAttachment";
import { DownloadLink, DownloadLinkHolder, DropArea, DropAreaText, DropAreaWrapper } from "./FileAttachmentStyles";

type BrowseFilesProps = {
  files: File[];
  fileTypes: string | string[];
  maxFiles?: number;
  placeholder?: string;
  onFileAdd: (value: File[]) => void;
  onFileDelete: (index: number) => void;
  /** Columns to display in table */
  displayColumns?: ColumnType[];
  styles?: FileAttachmentThemeType;
  browseButtonText?: React.ReactNode;
  /** if the input is loading */
  disabled?: boolean;
};

type FileContentType = string | ArrayBuffer;

const BrowseFiles: React.FC<BrowseFilesProps> = ({
  files,
  fileTypes,
  onFileAdd,
  onFileDelete,
  placeholder = "drop files here or click browse",
  maxFiles = 1,
  styles,
  browseButtonText = "Browse",
  disabled = false,
}) => {
  const { Theme } = useTheme();
  const styleOverrides: FileAttachmentThemeType = {
    dropArea: { ...Theme.fileAttachment.dropArea, ...styles?.dropArea },
    dropAreaText: { ...Theme.fileAttachment.dropAreaText, ...styles?.dropAreaText },
    label: { ...Theme.fileAttachment.label, ...styles?.label },
  };

  const [filesContent, setFilesContent] = useState<FileContentType[]>([]);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
  const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false);
  const [acceptedFileTypes, setAcceptedFileTypes] = useState<string>("");

  const getFileTypes = (): string | undefined => {
    if (Array.isArray(fileTypes)) {
      // Given an array of file types, join them with commas
      return fileTypes.join(", ");
    } else if (isString(fileTypes)) {
      // Given a string, just use that as the file type
      return fileTypes;
    } else {
      // By passing null, it will allow all file types
      return undefined;
    }
  };

  const getFilesContent = (files: File[]) => {
    files.forEach((file: File, index: number) => {
      if (file && file.name) {
        const reader = new FileReader();
        reader.onload = () => {
          const newContents: FileContentType[] = [...filesContent];
          newContents[index] = !!reader.result ? reader.result : "";
          setFilesContent(newContents);
        };
        reader.readAsDataURL(file);
      } else {
        const newContents: FileContentType[] = [...filesContent];
        newContents[index] = "";
        setFilesContent(newContents);
      }
    });
  };

  const determineAcceptedTypes = (fileTypes: string | string[]) => {
    let types: string[];
    if (typeof fileTypes === "string") {
      types = fileTypes.split(",");
    } else {
      types = fileTypes;
    }
    let acceptedTypes = "";
    types.forEach((type) => {
      acceptedTypes += `${getExtensionFromMimeType(type)}, `;
    });
    if (acceptedTypes.length > 1) {
      acceptedTypes = acceptedTypes.substr(0, acceptedTypes.length - 2);
    }
    setAcceptedFileTypes(acceptedTypes);
  };

  useEffect(() => {
    getFilesContent(files);
  }, [files]);

  useEffect(() => {
    setRejectionModalOpen(!!fileRejections.length);
  }, [fileRejections]);

  useEffect(() => {
    determineAcceptedTypes(fileTypes);
  }, [fileTypes]);

  return (
    <div className="dropzone">
      <Modal show={rejectionModalOpen}>
        <Modal.Header>Files Rejected</Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <tr>
                <th>File Name</th>
                <th>Rejection Reason</th>
              </tr>
            </TableHeader>
            <tbody>
              {fileRejections.map((rej) => {
                let errorMessage = rej.errors[0].message;
                if (rej.errors[0].code === "file-invalid-type") {
                  errorMessage = `Only files in the following formats are accepted: ${acceptedFileTypes}`;
                }
                return (
                  <tr key={rej.file.name}>
                    <TableTd>{rej.file.name}</TableTd>
                    <TableTd>{errorMessage}</TableTd>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setRejectionModalOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Dropzone
        accept={getFileTypes()}
        onDrop={(accepted, rejected) => {
          onFileAdd(accepted);
          setFileRejections(rejected);
        }}
        noClick
        maxFiles={maxFiles}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps, open, isDragActive }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <DropAreaWrapper>
              <DropArea isDragActive={isDragActive} styles={styleOverrides}>
                {maxFiles === 1 && files.length === 1 && files[0]?.name ? (
                  <DownloadLinkHolder>
                    <DownloadLink href={filesContent[0] as string} download={files[0].name}>
                      {files[0].name}
                    </DownloadLink>
                    <Icon iconName="fa-trash-alt" onClick={() => onFileDelete(0)} styles={{ size: ".9rem" }} />
                  </DownloadLinkHolder>
                ) : (
                  <DropAreaText styles={styleOverrides}>{placeholder}</DropAreaText>
                )}
              </DropArea>
              <Button variant="secondary" onClick={open}>
                {browseButtonText}
              </Button>
            </DropAreaWrapper>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default BrowseFiles;
