import React from "react";
import styled from "styled-components";
import { FormatterType } from "../../../../types";
import { DataTableDataType } from "../DataTable";
import { getFormatter } from "./FormatterHelper";

const PrimaryInfo = styled.div`
  color: black;
  font-size: 14px;
`;

const SecondaryInfo = styled.div`
  color: #666666;
  font-size: 12px;
`;

type TabletCellFormatterProps<T> = FormatterType<T> & {
  primaryFormatter: React.FC<FormatterType<T>>;
  secondaryFormatter: React.FC<FormatterType<T>>;
  row: string;
  secondaryKey: string;
};

const TabletCellFormatter = <T extends DataTableDataType>(props: TabletCellFormatterProps<T>) => {
  const { primaryFormatter, secondaryFormatter, ...rest } = props;
  // Get the primary and secondary formatter, passing all props to the formatters.
  // The only props that are not sent is primaryFormatter and secondaryFormatter
  // Otherwise we get a cylical component error
  const pFormatter = getFormatter(primaryFormatter, rest);
  const sFormatter = getFormatter(secondaryFormatter, rest);

  const { value, row, secondaryKey } = rest;

  const primaryValue = value;

  if (secondaryKey !== undefined) {
    const secondaryValue = row[secondaryKey];

    return (
      <>
        {pFormatter !== undefined && primaryValue !== null && primaryValue !== "" ? (
          <PrimaryInfo>{pFormatter}</PrimaryInfo>
        ) : primaryValue !== null && primaryValue !== "" ? (
          <PrimaryInfo>{primaryValue}</PrimaryInfo>
        ) : (
          <PrimaryInfo>&nbsp;</PrimaryInfo>
        )}
        {sFormatter !== undefined && secondaryValue !== null && secondaryValue !== "" ? (
          <SecondaryInfo>{sFormatter}</SecondaryInfo>
        ) : secondaryValue !== null && secondaryValue !== "" ? (
          <SecondaryInfo>{secondaryValue}</SecondaryInfo>
        ) : (
          <SecondaryInfo>&nbsp;</SecondaryInfo>
        )}
      </>
    );
  } else {
    return (
      <>
        <PrimaryInfo>{pFormatter !== undefined ? pFormatter : value}</PrimaryInfo>
        <SecondaryInfo>&nbsp;</SecondaryInfo>
      </>
    );
  }
};

export default TabletCellFormatter;
