import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../hooks";
import { ToggleButtonsThemeType } from "../../../types";
import { BaseInputProps } from "../../../types/InputTypes";
import { Button, ButtonOverrideStyles } from "../../buttons";
import { RequiredInput } from "../../required";
import Label from "../label/Label";
import { ToggleButtonGroup } from "./ToggleButtonStyles";

export type ToggleButtonOption = {
  /** What to display in the button */
  display: React.ReactNode;
  /** Value of the button */
  value: number | string;
};

export type ToggleButtonProps = BaseInputProps & {
  /** options to display in the toggle */
  options: ToggleButtonOption[];
  /** current value of the toggle */
  value?: number | string | number[] | string[];
  /** if the user can select multiple */
  isMulti?: boolean;
  /** onChange functon when button is clicked */
  onChange: (value?: number | string | number[] | string[]) => void;
  /** overrideable styles */
  styles?: ToggleButtonsThemeType;
};

/**
 * Toggle buttons to display multiple data points
 */
const ToggleButtons: React.FC<ToggleButtonProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  className,
  isMulti = false,
  readOnly = false,
  disabled = false,
  required = false,
  missed = false,
  gutterBottom,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: ToggleButtonsThemeType = { ...Theme.toggleButtons, ...styles };

  const changeValue = (index: number) => {
    if (!disabled) {
      onChange(options[index].value);
    }
  };

  const getStyles = (active: boolean, styles: ToggleButtonsThemeType): ButtonOverrideStyles => {
    return {
      button: {
        ...Theme.button,
        "line-height": styles.buttonLineHeight !== undefined ? styles.buttonLineHeight : "1.5",
        default: {
          ...Theme.button.default,
          "background-color": styles.buttonBackgroundColor!,
          "border-color": styles.buttonBackgroundColor!,
          color: styles.buttonActiveColor!,
        },
        primary: {
          ...Theme.button.primary,
          "background-color": styles.buttonActiveColor!,
        },
      },
      link: Theme.link,
    };
  };

  const isActive = (option: ToggleButtonOption, value?: number | string | number[] | string[]): boolean =>
    //@ts-ignore
    isMulti && Array.isArray(value) ? (value as []).includes(option.value) : option.value === value;

  return (
    <>
      {!!label && (
        <Label htmlFor={id} required={required && !readOnly} missed={missed}>
          {label}
        </Label>
      )}
      {readOnly ? (
        <div>
          {options
            .filter((opt: ToggleButtonOption) => isActive(opt, value))
            .map((opt: ToggleButtonOption) => (
              <div key={opt.value}>{opt.display}</div>
            ))}
        </div>
      ) : (
        <RequiredInput required={required} validateFunc={() => !!value}>
          <ToggleButtonGroup
            styles={StylesOverride}
            input={Theme.input}
            className={clsx(className, { "mb-2": gutterBottom })}
          >
            {options.map((option: ToggleButtonOption, index: number) => {
              const active: boolean = isActive(option, value);
              const styles: ButtonOverrideStyles = getStyles(active, StylesOverride);
              return (
                <Button
                  key={option.value}
                  onClick={() => changeValue(index)}
                  styles={styles}
                  variant={active ? "primary" : "default"}
                  disabled={disabled}
                >
                  {option.display}
                </Button>
              );
            })}
          </ToggleButtonGroup>
        </RequiredInput>
      )}
    </>
  );
};

export default ToggleButtons;
