import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "../../../hooks";
import { CategoryResponses, NavigationValueThemeType } from "../../../types";
import Icon from "../../icon/Icon";
import { QuickLinkStyled } from "./NavigationElementStyles";

type QuickLinkType = {
  to?: string;
  icon?: string;
  iconCategory?: CategoryResponses;
  styles: NavigationValueThemeType;
};

const QuickLink: React.FC<QuickLinkType> = ({ to = "", icon = "fa-link", iconCategory, styles }) => {
  const location = useLocation();
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  const active = to === location?.pathname;
  return (
    <div>
      <QuickLinkStyled to={to} navisactive={active} styles={StylesOverride}>
        <Icon
          iconName={icon}
          iconCategory={iconCategory}
          styles={{ color: StylesOverride.color, hoverColor: StylesOverride.hover.color }}
        />
      </QuickLinkStyled>
    </div>
  );
};

export default QuickLink;
