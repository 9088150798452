import clsx from "clsx";
import { ContentBlock, EditorState, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import React, { useState } from "react";
import { Editor, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { WysiwygThemeType } from "types";
import { useTheme } from "../../hooks/useTheme";
import { WysiwygEditorStyles, WysiwygFooterStyles } from "./WysiwygStyles";

type WysiwygProps = {
  /** initial state for the editor */
  value: string;
  /** onChange for changing the state of the value passed in */
  onChange: (value: string) => void;
  /** placeholder text in the editor */
  placeholder?: string;
  /** customize the react-draft-wysiwyg toolbar options: https://jpuri.github.io/react-draft-wysiwyg/#/docs */
  toolbarOptionsProps?: any;
  /** className to pass to the component */
  className?: string;
  /** spacing on the bottom of the input */
  gutterBottom?: boolean;
  /** if the component is readOnly */
  readOnly?: boolean;
  /** Overrideable styles */
  styles?: WysiwygThemeType;
};

const defaultToolbarOptionsProps = {
  toolbarOptions: ["history", "blockType", "inline", "textAlign", "list"],
  blockTypeProps: {},
  fontSizeProps: {
    options: [16, 18, 24, 30, 36, 48],
  },
  inlineProps: {
    inDropdown: false,
    inlineOptions: ["bold", "italic"],
  },
  listProps: { inDropdown: false },
  textAlignProps: { inDropdown: false },
};

const blockStyleFn = (block: ContentBlock) => {
  const data = block.getData();
  if (data.size == 0) return;
  if (data.get("text-align")) {
    return {
      style: {
        textAlign: data.get("text-align"),
      },
    };
  } else {
    return {};
  }
};

const customBlockFn = (element: Element) => {
  //@ts-ignore
  if (element.style.textAlign) {
    return {
      data: {
        //@ts-ignore
        "text-align": element.style.textAlign,
      },
    };
  } else {
    return {};
  }
};

const Wysiwyg: React.FC<WysiwygProps> = ({
  onChange,
  placeholder,
  styles,
  toolbarOptionsProps,
  value,
  gutterBottom = false,
  readOnly = false,
  className = "",
}) => {
  const { Theme } = useTheme();
  const styleOverrides: WysiwygThemeType = {
    ...Theme.wysiwyg,
    ...styles,
  };

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(value, { customBlockFn: customBlockFn })),
  );

  const setEditorStateChanged = (editorState: EditorState) => {
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent().hasText()
      ? stateToHTML(editorState.getCurrentContent(), { blockStyleFn: blockStyleFn })
      : "";
    onChange(currentContent);
  };

  const tabAction = (e: SyntheticKeyboardEvent) => {
    if (e) {
      e.preventDefault();
    }

    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const blockType = content.getBlockForKey(selection.getStartKey()).getType();

    if (/(un)?ordered-list-item/i.test(blockType)) {
      setEditorState(RichUtils.onTab(e, editorState, 3));
    } else {
      const newState = Modifier.replaceText(content, selection, "    ");
      setEditorState(EditorState.push(editorState, newState, "insert-characters"));
    }
  };

  const Options = {
    ...defaultToolbarOptionsProps,
    ...toolbarOptionsProps,
  };

  return (
    <>
      {readOnly ? (
        /* eslint-disable-next-line */
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <WysiwygEditorStyles styles={styleOverrides} className={clsx(className, { "mb-2": gutterBottom })}>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorStateChanged}
            onTab={tabAction}
            placeholder={placeholder}
            toolbar={{
              options: Options.toolbarOptions,
              blockType: {
                options: Options.blockTypeProps.blockTypeOptions,
              },
              history: { options: ["undo", "redo"] },
              inline: {
                inDropdown: Options.inlineProps.inlineInDropdown,
                options: Options.inlineProps.inlineOptions,
                bold: {},
              },
              list: {
                inDropdown: Options.listProps.inDropdown,
                options: Options.listProps.listOptions,
              },
              fontSize: {
                options: Options.fontSizeProps.options,
              },
              textAlign: { inDropdown: Options.textAlignProps.inDropdown },
            }}
          />
          <WysiwygFooterStyles styles={styleOverrides} />
        </WysiwygEditorStyles>
      )}
    </>
  );
};

export default Wysiwyg;
