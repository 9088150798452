import qs from "query-string";

export const parseRouteParams = (search: string): object => (search ? qs.parse(search) : {});

export const createRouteSearch = (params: object): string => "?" + qs.stringify(params);

export const urlReplace = (str: string, data: object): string => {
  if (!str) {
    return "";
  }
  Object.entries(data).forEach(([key, value]) => (str = str.replace(`:${key}`, value)));
  return str;
};
