import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { TableThemeType } from "../../types";
import { TableTdStyled } from "./TableStyles";

export type TableTdProps = {
  /** overrideable styles */
  styles?: TableThemeType;
};

const TableTd: React.FC<TableTdProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: TableThemeType = { ...Theme.table, ...styles };

  return (
    <TableTdStyled styles={StylesOverride} {...rest}>
      {children}
    </TableTdStyled>
  );
};

export default TableTd;
