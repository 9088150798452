import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import Checkbox from "../../../form/inputs/checkbox/Checkbox";
import { useTheme } from "../../../hooks";
import {
  ActionType,
  DataTableColumn,
  DataTableRowThemeType,
  DatatableThemeType,
  ExpansionType,
  FormatterType,
  NO_DIRECTION,
  RowClick,
  SortDirection,
} from "../../../types";
import { areArraysEqual, areObjectsEqual, typedMemo } from "../../../utils";
import { DataTableDataType } from "./DataTable";
import { BodyCell, RowCheckboxHolder, RowDiv, RowExpansionHolder, SubRowButtonHolder } from "./DatatableStyles";
import ExpandButton from "./ExpandButton";
import TextFormatter from "./formatters/TextFormatter";
import MoreActionsButton from "./MoreActionsButton";
import SubrowButton from "./SubrowButton";

function hasEditColumns<T extends DataTableDataType>(columns: DataTableColumn<T>[]): boolean {
  return columns.filter((col: DataTableColumn<T>) => !!col.editFormatter).length > 0;
}

export function formatterPropsCompare<T extends DataTableDataType>(
  prevProps: Readonly<PropsWithChildren<FormatterType<T>>>,
  nextProps: Readonly<PropsWithChildren<FormatterType<T>>>,
): boolean {
  return (
    !nextProps.editable &&
    areObjectsEqual(prevProps.value, nextProps.value) &&
    areObjectsEqual(prevProps.styles, nextProps.styles) &&
    areObjectsEqual(prevProps.row, nextProps.row) &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.rowIndex === nextProps.rowIndex &&
    prevProps.className === nextProps.className &&
    prevProps.subrow === nextProps.subrow &&
    prevProps.icon === nextProps.icon
  );
}

function rowPropsCompare<T extends DataTableDataType>(
  prevProps: Readonly<PropsWithChildren<RowProps<T>>>,
  nextProps: Readonly<PropsWithChildren<RowProps<T>>>,
): boolean {
  return (
    !hasEditColumns(nextProps.columns) &&
    areObjectsEqual(prevProps.value, nextProps.value) &&
    areObjectsEqual(prevProps.rowStyle, nextProps.rowStyle) &&
    areObjectsEqual(prevProps.virtualizedStyles, nextProps.virtualizedStyles) &&
    areArraysEqual(prevProps.columnSizes, nextProps.columnSizes) &&
    prevProps.rowHeight === nextProps.rowHeight &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.expandable === nextProps.expandable &&
    prevProps.sortColumn === nextProps.sortColumn &&
    prevProps.sortDirection === nextProps.sortDirection &&
    prevProps.showCheckbox === nextProps.showCheckbox &&
    prevProps.hasSubRows === nextProps.hasSubRows &&
    prevProps.isRowDisabled === nextProps.isRowDisabled &&
    prevProps.displayExpansionButton === nextProps.displayExpansionButton &&
    prevProps.isRowSelected === nextProps.isRowSelected &&
    prevProps.expanded === nextProps.expanded
  );
}

type RowProps<T extends DataTableDataType> = {
  index: number;
  value: T;
  columns: DataTableColumn<T>[];
  rowPrefix: string;
  rowHeight?: number;
  expanded?: boolean;
  rowStyle?: DataTableRowThemeType;
  sortColumn?: string;
  sortDirection?: SortDirection;
  hasSubRows?: boolean;
  columnSizes?: number[];
  expansion?: React.FC<ExpansionType<T>>;
  displayExpansionButton?: boolean;
  recompute?: (value: number) => void;
  tableExpanded?: (index: number, expanded: boolean, value: any) => void;
  expandable?: boolean;
  subRowStylesGetter?: (index: number, value: T, subRowIndex: number, subValue: any) => any;
  showCheckbox?: boolean;
  isRowSelected?: boolean;
  onRowSelectionChange?: (value: T, selected: boolean) => void;
  isRowDisabled?: boolean;
  canSelect?: (value: T) => boolean;
  expandCallback: (index: number, value: T, expanded: boolean) => void;
  rowClick?: RowClick<T>;
  actions?: ActionType<T>[];
  virtualizedStyles?: object;
  styles?: DatatableThemeType;
};

const RowData = <T extends DataTableDataType>(props: RowProps<T>) => {
  const {
    index,
    value,
    rowPrefix = "",
    rowStyle = {},
    rowHeight = 35,
    expanded = false,
    columns,
    sortColumn,
    sortDirection,
    hasSubRows = false,
    columnSizes = [],
    expansion,
    displayExpansionButton = true,
    recompute = () => {},
    tableExpanded = () => false,
    expandable,
    subRowStylesGetter = () => {},
    showCheckbox = false,
    isRowSelected = false,
    onRowSelectionChange = () => {},
    isRowDisabled = false,
    canSelect = () => false,
    expandCallback = () => {},
    rowClick,
    actions,
    styles = {},
  } = props;
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  StylesOverride.row = { ...StylesOverride.row, ...rowStyle };
  const ExpansionElement: React.FC<ExpansionType<T>> | undefined = expansion;

  const expand = (): void => {
    recompute(index);
    tableExpanded(index, !expanded, value);
  };

  const shouldShowSubRows: boolean = (expanded && hasSubRows) || !expandable;

  return (
    <div
      onClick={
        isRowDisabled
          ? (event) => {
              event.preventDefault();
              event.stopPropagation();
            }
          : undefined
      }
    >
      <RowDiv
        height={rowHeight}
        styles={{ ...StylesOverride }}
        expanded={expanded}
        selected={isRowSelected}
        disabled={isRowDisabled}
        isClickable={rowClick ? !rowClick.isClickable || rowClick.isClickable(index, value) : undefined}
        onClick={
          !!rowClick
            ? () => {
                if (!rowClick.isClickable || rowClick.isClickable(index, value)) {
                  rowClick.onClick(index, value);
                }
              }
            : undefined
        }
      >
        {showCheckbox ? (
          <RowCheckboxHolder>
            <Checkbox
              id="data-table-row-select"
              checked={isRowSelected}
              onChange={() => {
                onRowSelectionChange(value, !isRowSelected);
              }}
              disabled={isRowDisabled || !canSelect(value)}
              styles={{
                ...Theme.checkbox,
              }}
            />
          </RowCheckboxHolder>
        ) : null}
        {!!expansion && displayExpansionButton && <ExpandButton expanded={expanded} onClick={() => expand()} />}
        {hasSubRows && !!expandable && (
          <SubrowButton
            expanded={expanded}
            onClick={() => {
              expandCallback(index, value, !expanded);
              recompute(index);
              tableExpanded(index, !expanded, value);
            }}
            disabled={isRowDisabled}
          />
        )}
        {!!actions?.length && <MoreActionsButton actions={actions} disabled={isRowDisabled} data={value} />}
        {columns.map((col, columnIndex) => {
          const editable = !!col.editFormatter;
          const Formatter: React.FC<FormatterType<T>> = !!col.editFormatter
            ? col.editFormatter
            : !!col.readonlyFormatter
            ? col.readonlyFormatter
            : TextFormatter;
          const MemoFormatter = React.memo(Formatter, formatterPropsCompare);
          const sorted = sortDirection !== NO_DIRECTION && sortColumn === col.key;
          const width = columnSizes[columnIndex] ? columnSizes[columnIndex] : col.width;
          return (
            <BodyCell
              height={rowHeight}
              width={width}
              key={`body-cell-${columnIndex}-${col.key}`}
              sorted={sorted}
              styles={StylesOverride}
              className={clsx("d-flex align-items-center bcr-table-body-cell", col.cellClass)}
            >
              <MemoFormatter
                className={col.cellClass}
                columnKey={col.key}
                row={value}
                value={value[col.key]}
                rowIndex={index}
                subrow={false}
                expand={expand}
                expanded={expanded}
                icon={col.icon}
                onClick={col.onClick}
                editable={editable}
              />
            </BodyCell>
          );
        })}
      </RowDiv>
      {shouldShowSubRows &&
        value?.subRows &&
        value?.subRows.map((subValue: any, subRowIndex: number) => {
          const override = subRowStylesGetter(index, value, subRowIndex, subValue) || rowStyle;
          return (
            <RowDiv
              key={`row-${index}-subrow-${subRowIndex}`}
              height={rowHeight}
              styles={{ ...StylesOverride, ...override }}
            >
              {expandable ? <SubRowButtonHolder /> : null}
              {showCheckbox ? <SubRowButtonHolder /> : null}
              {columns.map((col, index) => {
                const editable = !!col.editFormatter;
                const Formatter: React.FC<FormatterType<T>> = !!col.editFormatter
                  ? col.editFormatter
                  : !!col.readonlyFormatter
                  ? col.readonlyFormatter
                  : TextFormatter;
                const MemoFormatter = React.memo(Formatter, formatterPropsCompare);
                const sorted = sortDirection !== NO_DIRECTION && sortColumn === col.name;
                const width = columnSizes[index] ? columnSizes[index] : col.width;
                return (
                  <BodyCell
                    height={rowHeight}
                    width={width}
                    key={`body-cell-${index}-${col.key}`}
                    sorted={sorted}
                    styles={StylesOverride}
                    className={clsx("d-flex align-items-center bcr-table-body-cell", col.cellClass)}
                  >
                    <MemoFormatter
                      className={col.cellClass}
                      row={subValue}
                      columnKey={col.key}
                      value={subValue[col.key]}
                      rowIndex={index}
                      subrow={true}
                      editable={editable}
                      styles={StylesOverride}
                    />
                  </BodyCell>
                );
              })}
            </RowDiv>
          );
        })}
      {expanded && !!expansion && !!ExpansionElement && (
        <RowExpansionHolder id={`${rowPrefix}-${index}`} styles={StylesOverride}>
          <ExpansionElement value={value} styles={StylesOverride}>
            expansion
          </ExpansionElement>
        </RowExpansionHolder>
      )}
    </div>
  );
};

const Row = <T extends DataTableDataType>(props: RowProps<T>) => {
  const {
    index,
    rowPrefix = "",
    rowHeight = 35,
    rowStyle,
    rowClick,
    actions,
    virtualizedStyles = {},
    styles = {},
    ...rest
  } = props;
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };

  return (
    <div key={rowPrefix + index} style={virtualizedStyles}>
      <RowData
        index={index}
        rowPrefix={rowPrefix}
        rowHeight={rowHeight}
        rowClick={rowClick}
        actions={actions}
        rowStyle={rowStyle}
        styles={StylesOverride}
        {...rest}
      />
    </div>
  );
};

const MemoizedRow = typedMemo(Row, rowPropsCompare);

export default MemoizedRow;
