import styled from "styled-components";
import { CardThemeType } from "../../../types";

type CardThemeStyles = {
  styles: CardThemeType;
};

export const CardStyled = styled.div.attrs(() => {
  id: "best-card";
})`
  box-shadow: 0 0 4px 0 rgba(4, 30, 66, 0.2);
  border-radius: ${(props) => props.styles.borderRadius};
`;

export const CardHeaderDivStyled = styled.div.attrs(() => ({
  className: "card-header",
  id: "best-card-header",
}))<CardThemeStyles>`
  &&& {
    background: ${(props) => props.styles.header.backgroundColor};
    color: ${(props) => props.styles.header.color};
    border-bottom: ${(props) => props.styles.header.borderBottom};
    padding: ${(props) => props.styles.header.padding};
    font-size: ${(props) => props.styles.header.fontSize};
    border-radius: calc(${(props) => props.styles.borderRadius} - 1px)
      calc(${(props) => props.styles.borderRadius} - 1px) 0 0;
    @media (max-width: 767px) {
      padding: ${(props) => props.styles.header.mobilePadding};
    }
  }
`;

export const CarBodyStyled = styled.div.attrs(() => ({
  className: "",
  id: "best-card-body",
}))<CardThemeStyles>`
  &&& {
    border: ${(props) => props.styles.body.border};
    background: ${(props) => props.styles.body.background};
    padding: ${(props) => props.styles.body.padding};
    @media (max-width: 767px) {
      padding: ${(props) => props.styles.body.mobilePadding};
    }
  }
`;

export const CardFooterDiv = styled.div.attrs(() => ({
  className: "card-header",
  id: "best-card-footer",
}))<CardThemeStyles>`
  background: ${(props) => props.styles.footer.background};
  border-top: 1px solid ${(props) => props.styles.footer.borderColor};
  border-radius: 0 0 calc(${(props) => props.styles.borderRadius} - 1px)
    calc(${(props) => props.styles.borderRadius} - 1px);
`;
