import clsx from "clsx";
import React from "react";
import { DataTable, DataTableDataType, DateTimeFormatter, IconFormatter } from "../../components";
import { DataTableColumn, FileAttachmentThemeType } from "../../types";
import BrowseFiles from "./BrowseFiles";
import FileAttachmentMobile from "./FileAttachmentMobile";
import FileAttachmentSizeFormatter from "./FileAttachmentSizeFormatter";

export type ColumnType = "fileName" | "lastModified" | "size" | "fileType";

type FileAttachmentProps = {
  /** List of files currently selected */
  files: File[];
  /** Function for change of selected files */
  onChange: (value: File[]) => void;
  /** accepted file types: comma seperated string or array of strings */
  fileTypes?: string | string[];
  /** if the sub table should be shown */
  showTable?: boolean;
  /** if the input is loading */
  loading?: boolean;
  /** if the input is disabled */
  disabled?: boolean;
  /** max number of files allowed */
  maxFiles?: number;
  /** placeholder for the input */
  placeholder?: string;
  /** Columns to display in table */
  displayColumns?: ColumnType[];
  /** className to pass to the component */
  className?: string;
  /** if the component is read only */
  readOnly?: boolean;
  /** overrideable styles */
  styles?: FileAttachmentThemeType;
  /** Text to override browse button */
  browseButtonText?: React.ReactNode;
};

export type FileDTO = DataTableDataType & {
  name: string;
  lastModified: number;
  size: number;
  type: string;
};

const convertToJson = (files: File[]): FileDTO[] => {
  return files.map((file) => ({
    name: file.name,
    lastModified: file.lastModified,
    size: file.size,
    type: file.type,
    id: file.name,
    subRows: undefined,
    expanded: false,
  }));
};

/**
 * File Attachment component allowing for single file upload or multiple files
 */
const FileAttachment: React.FC<FileAttachmentProps> = ({
  files = [],
  onChange,
  fileTypes = [],
  disabled = false,
  showTable = true,
  loading = false,
  maxFiles = 1,
  placeholder,
  displayColumns = ["fileName", "lastModified", "size", "fileType"],
  className = "",
  styles,
  browseButtonText = "Browse",
  readOnly = false,
}) => {
  const height = 35 + (files ? files.length : 1) * 35;

  const onFileAdd = (newFiles: File[]) => {
    onChange(files.concat(newFiles));
  };

  const onFileDelete = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChange(newFiles);
  };

  const Columns: DataTableColumn<FileDTO>[] = [
    {
      key: "name",
      name: "File Name",
      width: 300,
      visible: displayColumns?.includes("fileName"),
    },
    {
      key: "lastModified",
      name: "Last Modified",
      width: 200,
      readonlyFormatter: DateTimeFormatter,
      visible: displayColumns?.includes("lastModified"),
    },
    {
      key: "size",
      name: "Size",
      width: 100,
      readonlyFormatter: FileAttachmentSizeFormatter,
      visible: displayColumns?.includes("size"),
    },
    {
      key: "type",
      name: "File Type",
      width: 300,
      visible: displayColumns?.includes("fileType"),
    },
  ];

  if (!readOnly) {
    Columns.unshift({
      key: "icon",
      name: "",
      readonlyFormatter: IconFormatter,
      icon: "fa-trash",
      onClick: onFileDelete,
      width: 50,
      cellClass: "text-center",
    });
  }

  const data: FileDTO[] = convertToJson(files);

  return (
    <div className={clsx(className)}>
      {!disabled && !readOnly && (
        <BrowseFiles
          files={files}
          fileTypes={fileTypes}
          onFileAdd={onFileAdd}
          onFileDelete={onFileDelete}
          maxFiles={maxFiles}
          placeholder={placeholder}
          styles={styles}
          browseButtonText={browseButtonText}
          disabled={loading}
        />
      )}
      {(maxFiles != 1 || readOnly) && showTable && (
        <DataTable
          data={data}
          columns={Columns}
          accordion={FileAttachmentMobile}
          tableHeights={{ maxTableHeight: height }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default FileAttachment;
