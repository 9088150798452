import React from "react";
import NoPermissionToView from "./NoPermissionToView";

type PermissionCheckProps = {
  permissions: boolean[];
  atLeastOnePermission?: boolean;
};

const PermissionCheck: React.FC<PermissionCheckProps> = ({ permissions, atLeastOnePermission = false, children }) => {
  let canView;
  if (atLeastOnePermission) {
    permissions.forEach((perm) => perm === true && (canView = true));
  } else {
    canView = permissions.every((perm) => perm === true);
  }
  return <>{canView ? children : <NoPermissionToView />}</>;
};

export default PermissionCheck;
