import React from "react";
import { CategoryResponses, NavigationLinkType, NavigationValueThemeType } from "../../../types";
import IconNavigationLinkNoSubLinks from "./IconNavigationNoSubLinks";
import IconNavigationWithSubLinks from "./IconNavigationWithSubLinks";
import { IconNavigationLinkStyled } from "./NavigationElementStyles";

type IconNavigationLinkProps = {
  name: string;
  navisactive: boolean;
  toggleMobileNav?: (value: boolean) => void;
  to?: string;
  icon?: string;
  iconCategory?: CategoryResponses;
  subLinks?: NavigationLinkType[];
  styles: NavigationValueThemeType;
  horizontal: boolean;
};

const IconNavigationLink: React.FC<IconNavigationLinkProps> = ({
  toggleMobileNav,
  to,
  name,
  navisactive,
  icon,
  iconCategory,
  subLinks = [],
  styles,
  horizontal,
}) => {
  const filteredSublinks: NavigationLinkType[] = subLinks
    ? subLinks.filter((sublink) => sublink.visible !== false)
    : [];
  return (
    <IconNavigationLinkStyled>
      {!!filteredSublinks?.length ? (
        <IconNavigationWithSubLinks
          toggleMobileNav={toggleMobileNav}
          subLinks={filteredSublinks}
          icon={icon}
          iconCategory={iconCategory}
          name={name}
          navisactive={navisactive}
          styles={styles}
          horizontal={horizontal}
        />
      ) : (
        <IconNavigationLinkNoSubLinks
          toggleMobileNav={toggleMobileNav}
          to={to}
          icon={icon}
          name={name}
          navisactive={navisactive}
          styles={styles}
        />
      )}
    </IconNavigationLinkStyled>
  );
};

export default IconNavigationLink;
