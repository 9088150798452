import { CalendarEvent } from "../../types";
import { getDaysInMonth } from "../../utils";

export const getDay = (index: number): string => {
  switch (index) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    default:
      return "";
  }
};

type CalendarDates = {
  daysInMonth: number;
  daysToRender: number[];
  startDayOfWeek: number;
};

export const useCalendarDates = (year: number, month: number): CalendarDates => {
  const date = new Date(year, month, 1);
  const startDayOfWeek: number = date.getDay();
  const daysInMonth: number = getDaysInMonth(year, month + 1);
  const numOfDaysToRender =
    (startDayOfWeek === 5 && daysInMonth >= 31) || (startDayOfWeek === 6 && daysInMonth >= 30) ? 49 : 42;
  const daysToRender = [...Array(numOfDaysToRender)];

  return {
    daysInMonth,
    daysToRender,
    startDayOfWeek,
  };
};

export const getEvent = <T>(events: CalendarEvent<T>[], dayOfMonth?: number): CalendarEvent<T> | undefined => {
  return !!dayOfMonth ? events.find((event) => event.dayOfMonth === dayOfMonth) : undefined;
};
