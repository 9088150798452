import React, { ErrorInfo } from "react";
import styled from "styled-components";
import Typography from "../../layout/typography/Typography";
import Icon from "../icon/Icon";

const Container = styled.div.attrs(() => ({
  className: "d-flex flex-column align-items-center",
}))`
  background-color: ${(props) => props.theme["light-grey"]};
  height: 100vh;
  width: 100%;
`;

const ErrorIcon = styled(Icon)`
  margin-top: 56px;
  margin-bottom: 16px;
  color: #bf0d3e;
  font-size: 50px;
`;

const Logo = styled.img`
  margin-top: 10px;
  width: 160px;
`;

type CatcherProps = {
  appName: string;
  hasError?: boolean;
};

type MyState = {
  hasError: boolean;
};

class Catcher extends React.Component<CatcherProps> {
  state: MyState = { hasError: this.props.hasError || false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Here we can log to a service now or email/slack if we need to see the errors that happen and crash prod if it ever happens
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Container>
            <ErrorIcon iconName="fa-exclamation-circle" />
            <Typography variant="h5" elementStyles={{ width: "" }}>
              Oops! Something went wrong...
            </Typography>
            <Typography elementStyles={{ width: "" }}>Please clear your cache and try again.</Typography>
            <Typography elementStyles={{ width: "" }}>
              If your problem persists, contact {this.props.appName} support.
            </Typography>
            <Logo src="https://www.mlbstatic.com/team-logos/league-on-light/1.svg" />
          </Container>
        </div>
      );
    }

    return this.props.children;
  }
}

export default Catcher;
