import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useBreakpoints, useTheme } from "../../../hooks";
import { NavigationConfigType, NavigationLinkType, NavigationValueThemeType } from "../../../types";
import MobileView from "../../view/MobileView";
import IconNavigationLink from "../elements/IconNavigationLink";
import NavBar from "../elements/NavBar";
import NavbarDropdown from "../elements/NavbarDropdown";
import NavbarSignout from "../elements/NavbarSignout";
import NavbarUsernameDropdown from "../elements/NavbarUsernameDropdown";
import NavigationDropdownLink from "../elements/NavigationDropdownLink";
import NavigationLinks from "../elements/NavigationLinks";
import HorizontalBarForVerticalNav from "./HorizontalBarForVerticalNav";
import NavigationVertical from "./NavigationVertical";
import { NavbarSignoutItem, NavbarSignoutLink } from "./VerticalNavStyles";

/**
 * A vertical navigation based on the navigation configuration
 */

type VerticalNavigationFromConfigProps = {
  config: NavigationConfigType;
  styles?: NavigationValueThemeType;
};

const VerticalNavigationFromConfig: React.FC<VerticalNavigationFromConfigProps> = ({
  config: {
    title,
    displayEnvIndicator,
    loggedIn,
    username,
    usernameDropdownLinks,
    navigationLinks,
    startLogo,
    builds,
    env,
    topBarComponent,
    displayPermissionsIndicator = false,
    permissions = "",
  },
  styles,
}) => {
  const location = useLocation();
  const { isMobile } = useBreakpoints();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState<string[]>([]);

  const toggleMobileNav = (value: boolean) => {
    if (isMobile) {
      setMobileNavOpen(!mobileNavOpen);
    } else {
      setMobileNavOpen(false);
    }
  };

  const checkForActive = (to?: string, sublinks?: NavigationLinkType[]): boolean => {
    if (to === location?.pathname) {
      return true;
    } else if (location?.pathname === "/") {
      return false;
    } else if (sublinks) {
      for (let i = 0; i < sublinks.length; i++) {
        const sublink = sublinks[i];
        if (sublink.to && sublink.to.startsWith(location?.pathname)) {
          return true;
        }
      }
    } else if (to) {
      return location?.pathname.startsWith(to);
    }
    return false;
  };

  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = !!styles
    ? { ...Theme.navigation.vertical, ...styles }
    : Theme.navigation.vertical;

  return (
    <div className="d-flex" style={{ background: Theme["white"] }}>
      <NavBar env={env} vertical displayEnvIndicator={displayEnvIndicator}>
        <NavbarSignout
          loggedIn={loggedIn}
          displayEnvIndicator={displayEnvIndicator}
          env={env}
          displayPermissionsIndicator={displayPermissionsIndicator}
          permissions={permissions}
          styles={StylesOverride}
          horizontal={false}
        >
          <NavbarUsernameDropdown username={username} vertical={true} styles={StylesOverride}>
            <>
              {!!usernameDropdownLinks &&
                usernameDropdownLinks.map(({ name, to, onClick, visible = true }) =>
                  visible && to && to.length ? (
                    <NavbarSignoutLink to={to} onClick={onClick} key={name}>
                      {name}
                    </NavbarSignoutLink>
                  ) : (
                    <NavbarSignoutItem onClick={onClick} key={name} styles={StylesOverride}>
                      {name}
                    </NavbarSignoutItem>
                  ),
                )}
            </>
          </NavbarUsernameDropdown>
        </NavbarSignout>
        <NavigationVertical loggedIn={loggedIn} styles={StylesOverride}>
          <NavigationLinks open={mobileNavOpen} isProd={env === "prod"} styles={StylesOverride}>
            {!!navigationLinks &&
              navigationLinks.map(({ to, icon, iconCategory, name, subLinks, visible = true }) =>
                visible ? (
                  <IconNavigationLink
                    key={name}
                    toggleMobileNav={toggleMobileNav}
                    to={to}
                    icon={icon}
                    iconCategory={iconCategory}
                    name={name}
                    navisactive={checkForActive(to, subLinks)}
                    subLinks={subLinks}
                    styles={StylesOverride}
                    horizontal={false}
                  />
                ) : null,
              )}
            <MobileView>
              <NavbarDropdown
                title={username}
                styles={StylesOverride}
                vertical={true}
                dropdownId="mobile-username-dropdown"
                openDropdowns={openDropdowns}
                setOpenDropdowns={setOpenDropdowns}
              >
                {usernameDropdownLinks &&
                  usernameDropdownLinks.map(
                    ({ name, to, onClick, visible = true }) =>
                      visible && (
                        <NavigationDropdownLink
                          key={name}
                          to={to}
                          name={name}
                          navisactive={checkForActive(to)}
                          toggleMobileNav={toggleMobileNav}
                          onClick={onClick}
                          styles={StylesOverride}
                        />
                      ),
                  )}
              </NavbarDropdown>
            </MobileView>
          </NavigationLinks>
        </NavigationVertical>
      </NavBar>
      <HorizontalBarForVerticalNav
        title={title}
        loggedIn={loggedIn}
        logo={startLogo?.logo ?? ""}
        builds={builds}
        toggleMobileNav={toggleMobileNav}
        mobileNavOpen={mobileNavOpen}
        styles={StylesOverride}
      >
        {topBarComponent}
      </HorizontalBarForVerticalNav>
    </div>
  );
};

export default VerticalNavigationFromConfig;
