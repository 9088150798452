import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTheme } from "../../hooks/useTheme";
import { CollapseThemeType } from "../../types";
import { useCollapse } from "./CollapseContext";
import { CollapseBody, CollapseHeader } from "./CollapseStyles";

export type CollapseItemProps = {
  children: React.ReactNode;
  header: string | number | React.ReactNode;
  collapseKey: string;
  startsOpen?: boolean;
  onToggle?: (open: boolean) => void;
  styles?: CollapseThemeType;
};

const CollapseItem: React.FC<CollapseItemProps> = ({ children, header, collapseKey, startsOpen, onToggle, styles }) => {
  const { Theme } = useTheme();
  const StylesOverride: CollapseThemeType = { ...Theme.collapse, ...styles };
  const { open, toggle, register, animation } = useCollapse();
  const [height, setHeight] = useState<0 | 1>(0);

  const onClick = (): void => {
    const newValue = !open[collapseKey];
    toggle(collapseKey);
    if (onToggle) {
      onToggle(newValue);
    }
  };

  useEffect(() => {
    register(collapseKey, !!startsOpen);
  }, [collapseKey, startsOpen]);

  useEffect(() => {
    const isOpen = open[collapseKey];
    if (!!isOpen) {
      setHeight(1);
    } else {
      setHeight(0);
    }
  }, [open, collapseKey]);

  return (
    <div className="mb-1">
      <CollapseHeader styles={StylesOverride} onClick={onClick}>
        {header}
      </CollapseHeader>
      {animation ? (
        <AnimateHeight duration={300} height={height === 1 ? "auto" : 0}>
          <CollapseBody styles={StylesOverride}>{children}</CollapseBody>
        </AnimateHeight>
      ) : (
        <div>{height === 1 && <CollapseBody styles={StylesOverride}>{children}</CollapseBody>}</div>
      )}
    </div>
  );
};

export default CollapseItem;
