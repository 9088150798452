import styled from "styled-components";

type ImagePhotoStyles = {
  imageWidth: number;
  imageHeight: number;
};

export const Img = styled.img<ImagePhotoStyles>`
  margin-left: 50%;
  transform: translateX(-50%);
  ${(props) => (props.imageWidth < props.imageHeight ? "max-width: 100%" : "max-height: 100%")}
`;
