import React from "react";
import { FormatterType } from "../../../../types";
import Icon from "../../../icon/Icon";
import Link from "../../../links/Link";
import { DataTableDataType } from "../DataTable";
import { formatterPropsCompare } from "../Row";

const IconLinkFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value, icon = "", className = "", rowIndex = -1 } = props;
  return (
    <Link to={value} id={rowIndex.toString()}>
      <Icon iconName={icon} className={className} />
    </Link>
  );
};

const MemoIconLinkFormatter = React.memo(IconLinkFormatter, formatterPropsCompare);

export default MemoIconLinkFormatter;
