import React from "react";
import { FormatterType } from "../../../../types";
import { DataTableDataType } from "../DataTable";
import TextFormatter from "./TextFormatter";

const PriceFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value = 0, ...rest } = props;
  return (
    <TextFormatter
      value={new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }).format(
        value,
      )}
      {...rest}
    />
  );
};

export default PriceFormatter;
