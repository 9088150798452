import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks";
import { PaperThemeType, ProgressThemeType } from "../../types";

export type ProgressSizes = "sm" | "md" | "lg";

export type ProgressVariants = "primary" | "secondary" | "success" | "error";

type ProgressProps = {
  /** value between 0 and 100 */
  value: number;
  /** if the progess is animated */
  animated?: boolean;
  /** if the percent should be shown */
  displayValue?: boolean;
  /** Variant to progress */
  variant?: ProgressVariants;
  /** size of the progress bar */
  size?: ProgressSizes;
  /** styles to override */
  styles?: PaperThemeType;
};

const Progress: React.FC<ProgressProps> = ({
  value,
  animated = true,
  displayValue = false,
  variant = "primary",
  size = "md",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: ProgressThemeType = { ...Theme.progress, ...styles };

  const getHeight = (value: ProgressSizes): string => {
    switch (value) {
      case "sm":
        return "8px";
      case "lg":
        return "32px";
      default:
      case "md":
        return "16px";
    }
  };

  const getColor = (value: ProgressVariants): string => {
    switch (value) {
      case "error":
        return StylesOverride.error.color;
      case "success":
        return StylesOverride.success.color;
      case "secondary":
        return StylesOverride.secondary.color;
      default:
      case "primary":
        return StylesOverride.primary.color;
    }
  };

  return (
    <div className={clsx("progress w-100")} style={{ height: getHeight(size) }}>
      <div
        className={clsx("progress-bar", { "progress-bar-striped": animated, "progress-bar-animated": animated })}
        style={{ width: `${value}%`, backgroundColor: getColor(variant) }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {displayValue ? `${value} %` : null}
      </div>
    </div>
  );
};

export default Progress;
