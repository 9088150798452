import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { useBreakpoints, useTheme } from "../../../hooks";
import Typography from "../../../layout/typography/Typography";
import { CategoryResponses, NavigationValueThemeType } from "../../../types";
import { Icon, MobileView } from "../../index";
import { NavbarDropdownDropdown, NavbarDropdownLinks, NavbarDropdownTitle } from "./NavigationElementStyles";

type NavbarDropdownProps = {
  dropdownId: string;
  title: string;
  icon?: string;
  iconCategory?: CategoryResponses;
  navisactive?: boolean;
  displayName?: boolean;
  vertical: boolean;
  styles: NavigationValueThemeType;
  openDropdowns: string[];
  setOpenDropdowns: React.Dispatch<React.SetStateAction<string[]>>;
};

const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  dropdownId,
  title,
  icon,
  iconCategory,
  children,
  navisactive = false,
  displayName = true,
  vertical,
  styles,
  openDropdowns,
  setOpenDropdowns,
}) => {
  const ref = useRef<HTMLAnchorElement>();
  const { isMobile } = useBreakpoints();
  const { Theme } = useTheme();
  const defaultStyles: NavigationValueThemeType = vertical
    ? { ...Theme.navigation.vertical }
    : { ...Theme.navigation.horizontal };
  const StylesOverride: NavigationValueThemeType = { ...defaultStyles, ...styles };

  useEffect(() => {
    setOpenDropdowns((previous: string[]) => [...previous, dropdownId]);
  }, [dropdownId]);

  const updateOpenDropdown = () => {
    if (openDropdowns.indexOf(dropdownId) !== -1) {
      setOpenDropdowns((previous: string[]) => previous.filter((p) => p !== dropdownId));
    } else {
      if (openDropdowns.length <= 1) {
        setOpenDropdowns([dropdownId]);
      } else {
        setOpenDropdowns((previous: string[]) => [...previous, dropdownId]);
      }
    }
  };

  return (
    <NavbarDropdownDropdown navisactive={navisactive} styles={StylesOverride}>
      {title && (
        <NavbarDropdownTitle
          //@ts-ignore
          ref={ref}
          navisactive={navisactive}
          styles={StylesOverride}
          id={`navbarDropdown-${title}`}
          className={clsx("nav-link dropdown-toggle d-flex", { show: isMobile })}
          aria-labelledby={`navbarDropdown-${title}`}
          onClick={updateOpenDropdown}
        >
          {!!icon && (
            <Icon
              iconName={icon}
              iconCategory={iconCategory}
              className={clsx({ "me-1": displayName })}
              styles={{ color: StylesOverride.color, hoverColor: StylesOverride.hover.color }}
            />
          )}
          {displayName && (
            <Typography
              elementStyles={{
                ...styles,
                size: styles.fontSize,
                color: isMobile ? StylesOverride.mobile.dropdownColor : StylesOverride.color,
              }}
              variant="div"
            >
              {title}
            </Typography>
          )}
          <MobileView>
            <div className="ms-auto pe-3">
              <Icon
                iconName={openDropdowns.indexOf(dropdownId) !== -1 ? "fa-angle-up" : "fa-angle-down"}
                styles={{ color: styles.color }}
              />
            </div>
          </MobileView>
        </NavbarDropdownTitle>
      )}
      <NavbarDropdownLinks
        styles={StylesOverride}
        aria-labelledby={`navbarDropdown-${title}`}
        className={clsx("dropdown-menu", { show: isMobile })}
      >
        {children}
      </NavbarDropdownLinks>
    </NavbarDropdownDropdown>
  );
};

export default NavbarDropdown;
