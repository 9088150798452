import React from "react";
import Icon from "../../../components/icon/Icon";
import { InputProps } from "../input";
import Input from "../input/Input";

const CurrencyInput: React.FC<InputProps> = ({ onChange, ...rest }) => {
  const ValidateOnChange = (newValue: string) => {
    if (newValue && newValue.length > 1) {
      newValue = newValue.replace(/^0+/, "");
      if (newValue.includes(".")) {
        const split = newValue.split(".");
        if (split.length === 2) {
          if (split[1].length <= 2) {
            onChange(newValue);
          }
        } else if (split.length <= 1) {
          onChange(newValue);
        }
      } else {
        onChange(newValue);
      }
    } else {
      onChange(newValue);
    }
  };

  return <Input endAdorner={<Icon iconName="fa-dollar-sign" />} {...rest} onChange={ValidateOnChange} type="text" />;
};

export default CurrencyInput;
