import React from "react";
import { ClipLoader } from "react-spinners";
import { useTheme } from "../../../hooks";
import { DatatableThemeType } from "../../../types";
import { TableLoadingDiv } from "./DatatableStyles";

type TableLoadingProps = {
  width: number;
  height: number;
  styles?: DatatableThemeType;
};

const TableLoading: React.FC<TableLoadingProps> = ({ width, height, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  return (
    <TableLoadingDiv height={height} width={width} styles={StylesOverride}>
      <ClipLoader />
    </TableLoadingDiv>
  );
};

export default TableLoading;
