//@ts-ignore @eslint-ignore
//ONLY WORKS FOR 1:1 MAPPINGS OF KEY:VALUE
export const getKeyByValue = (object: object, value: any): any => {
  //@ts-ignore @eslint-ignore
  return Object.keys(object).find((key) => object[key] === value);
};

export const isObject = (value: any): boolean => typeof value === "object";

export const areObjectsEqual = (obj1?: any, obj2?: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
