const scrollbarVisible = (elem: string | HTMLElement | null) => {
  const el: HTMLElement | null = typeof elem === "string" ? document.getElementById(elem) : elem;
  return !!el && el!.scrollHeight > el!.clientHeight;
};

export const calculateColumnWidth = (
  element: HTMLElement | null,
  numColumns: number,
  fixedColumnWidths = 0,
  subtractScrollbar = false,
): number[] => {
  if (!element) return [0, 0];
  const n: number = Math.max(numColumns, 1);
  const parent: HTMLElement | null = !!element ? element.parentElement : null;
  const totalWidth: number = element.offsetWidth - (subtractScrollbar && scrollbarVisible(parent) ? 15 : 0);
  const freeSpace: number = Math.max(totalWidth - fixedColumnWidths, 0);
  const baseWidth: number = Math.floor(freeSpace / n);
  const remaining: number = Math.floor(freeSpace % n);

  return [baseWidth, remaining];
};

export const distribute = (rem: number, numColumns: number): number[] => {
  const n: number = Math.max(numColumns, 1);
  const result: number[] = [...Array(n)].fill(0);
  const inc: number = Math.ceil(rem / n);
  let dec: number = rem;
  let i = 0;
  while (dec > 0 && i < n) {
    result[i] += inc;
    dec -= inc;
    i++;
  }

  return result.reverse();
};
