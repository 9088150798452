import clsx from "clsx";
import React, { useCallback } from "react";
import Webcam from "react-webcam";
import { BaseInputProps } from "../../../types/InputTypes";
import Button from "../../buttons/Button";
import { Label } from "../label";
import { Img } from "./PhotoStyles";

export type PhotoMode = "photo" | "video";

export type FacingModes = "environment" | "user";

export type PhotoConstraints = {
  width: number;
  height: number;
  facingMode: FacingModes;
};

type PhotoProps = BaseInputProps & {
  /** value of the input */
  value?: string;
  /** function for when the value is changed */
  onChange: (value?: string) => void;
  /** mode of the photo input */
  mode?: PhotoMode;
  /** constraints for the video */
  photoConstraints?: PhotoConstraints;
};

const Photo: React.FC<PhotoProps> = ({
  id,
  label,
  value,
  onChange,
  mode = "photo",
  photoConstraints = { height: 480, width: 640, facingMode: "user" },
  required = false,
  disabled = false,
  className = "",
}) => {
  const webcamRef = React.useRef<Webcam>(null);

  const takePhoto = useCallback(() => {
    const imageSrc: string | null | undefined = webcamRef?.current?.getScreenshot();
    onChange(imageSrc === null ? undefined : imageSrc);
  }, [webcamRef]);

  return (
    <div className={clsx("w-100", className)}>
      {!!label && (
        <Label htmlFor={id} required={required && !disabled}>
          {label}
        </Label>
      )}
      <div className="d-flex flex-column align-items-center w-100">
        {!!value?.length && (
          <Img
            src={value}
            imageWidth={photoConstraints.width}
            imageHeight={photoConstraints.height}
            alt="Not available"
          />
        )}
        <Webcam
          audio={false}
          height={photoConstraints.height}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={photoConstraints.width}
          videoConstraints={photoConstraints}
        />
        <Button onClick={takePhoto}>Take Photo</Button>
      </div>
    </div>
  );
};

export default Photo;
