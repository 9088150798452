import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavigationValueThemeType } from "../../../types";

type NavigationElementStylesType = {
  styles: NavigationValueThemeType;
  isProd?: boolean;
  navisactive?: boolean;
  navisactivestr?: string;
  horizontal?: boolean;
};

//Icon HorizontalNavigation With Sublinks
export const WithSublinksDropdownMenu = styled.div.attrs(() => ({
  className: "dropdown-menu",
}))<NavigationElementStylesType>`
  &&& {
    background: ${(props) => props.styles.sublinks.background} !important;
    border-radius: 0 0.25rem 0.25rem 0;
    border: none;
    margin-left: ${(props) => (props.horizontal ? "0" : "191px")};
    padding: ${(props) => (props.styles.sublinksMenu?.padding ? props.styles.sublinksMenu.padding : "8px 0")};
    position: absolute;
  }
`;

export const WithSublinksDropdownMenuItem = styled(Link).attrs(() => ({
  className: "dropdown-item",
}))<NavigationElementStylesType>`
  &&& {
    color: ${(props) => props.styles.sublinks.color};
    background: ${(props) => (props.navisactive ? props.styles.active.background : props.styles.sublinks.background)};
    height: 30px;
    &:hover {
      color: ${(props) => props.styles.sublinks.hover.color};
      background: ${(props) => props.styles.sublinks.hover.background};

      div,
      i {
        color: ${(props) => props.styles.sublinks.hover.color} !important;
        background: ${(props) => props.styles.sublinks.hover.background} !important;
      }
    }
  }
`;

export const WithSublinksDropdownHolder = styled.div.attrs(() => ({
  className: "btn-group dropright",
}))`
  &&& {
    width: 100%;
    &:hover > .dropdown-menu {
      display: block;
    }
  }
`;

export const IconNavigationLinkStyled = styled.li`
  border-bottom: 1px solid ${(props) => props.theme.navigation.vertical.borderColor};
`;

export const WithSublinksNavLink = styled.a.attrs(() => ({
  className: "d-flex align-items-center",
  role: "button",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": false,
}))<NavigationElementStylesType>`
  min-width: 100%;
  height: 30px;
  padding: 0 0 0 ${(props) => (props.styles.paddingLeft ? props.styles.paddingLeft : "15px")};
  font-size: ${(props) => (props.styles.fontSize ? props.styles.fontSize : "16px")};
  text-decoration: none;
  color: ${(props) => (props.navisactive ? props.styles.active.color : props.styles.color)};
  background: ${(props) => (props.navisactive ? props.styles.active.background : props.styles.background)};
  &:hover {
    color: ${(props) => props.styles.hover.color};
    background: ${(props) => props.styles.hover.background};

    div,
    i {
      color: ${(props) => props.styles.hover.color};
    }
  }
`;

export const MobileCategory = styled.div.attrs(() => ({
  className: "nav-link d-flex align-items-center",
}))`
  &&& {
    color: rgb(125, 124, 124) !important;
    background: rgb(4, 21, 44);
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
    font-size: 1.125rem;
    padding: 5px 0.875rem 5px 25px;
  }
`;

export const WithSublinksMobileHolder = styled.ul.attrs(() => ({
  className: "dropdown-menu",
  "aria-labelledby": "navbarDropdown",
}))<NavigationElementStylesType>`
  &&& {
    border: none;
    list-style-type: none;
    padding: 0;

    width: 100%;
    color: ${(props) => props.styles.color};
    background: ${(props) => props.styles.background};
    margin: 0 !important;
    padding: 0 !important;

    li > a {
      padding-left: 50px !important;
    }

    li:not(:last-child) > a {
      border-bottom: 1px solid #000000 !important;
    }
  }
`;

export const WithSublinksMobileLink = styled(Link).attrs(() => ({
  className: "nav-link",
}))<NavigationElementStylesType>`
  &&& {
    color: ${(props) => props.styles.color};
    &:hover {
      color: ${(props) => props.styles?.hover?.color};
    }
    font-size: 1.125rem;

    height: 100%;
    padding: 0.625rem 25px;
    border-top: 1px solid #000000;
  }
`;

//Icon navigation No Sublinks
// @ts-ignore
export const NoSublinkNavLink = styled(Link).attrs(() => ({
  className: "d-flex align-items-center nav-link",
}))<NavigationElementStylesType>`
  &&& {
    padding: 0 0 0 ${(props) => (props.styles.paddingLeft ? props.styles.paddingLeft : "15px")};
    color: ${(props) => (props.navisactive ? props.styles.active.color : props.styles.color)};
    background: ${(props) => (props.navisactive ? props.styles.active.background : props.styles.background)};
    height: 30px;
    &:hover {
      color: ${(props) => props.styles.hover.color};
      background: ${(props) => props.styles.hover.background};

      div,
      i {
        color: ${(props) => props.styles.hover.color};
      }
    }
  }
`;

export const NoSublinkMobileLink = styled(Link).attrs(() => ({
  className: "d-flex align-items-center nav-link",
}))<NavigationElementStylesType>`
  &&& {
    color: ${(props) => props.styles.mobile.linkColor};
    &:hover {
      color: ${(props) => props.styles?.hover?.color};
    }
    font-size: 1.125rem;

    height: 100%;
    padding: 0.625rem 25px;
    background: ${(props) => props.styles.mobile.linkBackground};
    border-top: 1px solid #000000;
  }
`;

//Mobile Nav Big Link
export const MobileNavBigLinkStyled = styled(Link).attrs(() => ({
  className: "d-flex justify-content-between",
}))<NavigationElementStylesType>`
  &&& {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 1rem 0.5rem 32px;
    color: ${(props) => props.styles.color};
    background-color: ${(props) => props.styles.background};
    border: 1px solid ${(props) => props.theme.black};
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: ${(props) => props.styles.color};
    }
  }
`;

// Hamburger
export const Hamburger = styled.button.attrs(() => ({
  id: "navButton best-nav-hamburger",
  type: "button",
  "data-toggle": "collapse",
  "data-target": "#navbarSupportedContent",
  "aria-controls": "navbarSupportedContent",
  "aria-expanded": "false",
  "aria-label": "Toggle navigation",
}))<NavigationElementStylesType>`
  &&& {
    border: none;
    background: transparent;
    outline: none !important;
    z-index: 1055;
    height: ${(props) => props.styles.mobile.size};
    &:focus {
      &&& {
        outline: none !important;
        background: transparent;
      }
    }
    &:hover > .icon-bar:nth-child(2) {
      width: 30px;
      transition: ease all 0.2s;
    }
    &:active > .icon-bar:nth-child(2) {
      width: 30px;
      transition: ease all 0.2s;
    }
    &:not(.mobile-nav-closed) .icon-bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 4px);
      transition: ease all 0.2s;
    }
    &:not(.mobile-nav-closed) .icon-bar:nth-child(2) {
      opacity: 0;
      transition: ease all 0.2s;
    }
    &:not(.mobile-nav-closed) .icon-bar:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -4px);
      transition: ease all 0.2s;
    }
    .icon-bar {
      background-color: ${(props) => props.styles.color};
      transform: rotate(0deg) translate(0px, 0px);
      transition: ease all 0.2s;
      display: block;
      width: 30px;
      height: 2px;
      border-radius: 1px;
      outline: none !important;
    }
    .icon-bar + .icon-bar {
      margin-top: 4px;
      outline: none !important;
    }
  }
`;

//Navbar
export const NavbarEnvHolder = styled.div.attrs(() => ({
  className: "fixed-top",
}))`
  width: 100%;
`;

//HorizontalNavigation Logo
export const NavigationLogoText = styled.div.attrs(() => ({
  className: "ms-2",
}))<NavigationElementStylesType>`
  &&& {
    font-size: 1.3125rem;
    font-weight: 500;
    color: ${(props) => props.styles.color};
  }
`;

export const NavigationLogoHolder = styled.div.attrs(() => ({
  className: "d-flex align-items-center",
}))<NavigationElementStylesType>`
  @media (max-width: 767px) {
    height: 26px;
  }
  @media (min-width: 768px) {
    height: ${(props) => props.styles!.size};
    margin-left: 1rem;
  }
`;

export const NavigationNavLogo = styled.img.attrs(() => ({
  alt: "Left Logo",
}))<NavigationElementStylesType>`
  height: ${(props) => props.styles!.leftLogo.height};
  width: ${(props) => props.styles!.leftLogo.width};
  margin: ${(props) => props.styles!.leftLogo.margin};
`;

//HorizontalNavigation Links
export const NavigationLinksNavbarHolder = styled.div.attrs(() => ({
  id: "navbarSupportedContent",
}))<NavigationElementStylesType>`
  @media (max-width: 767px) {
    height: ${(props) => (!!props.isProd ? "calc(100vh - 60px)" : `calc(100vh - 78px)`)};
    overflow: scroll;
    background-color: ${(props) => props.styles.color};
    margin-top: 0.375rem;
  }
  @media (min-width: 768px) {
    height: 100%;
    display: flex;
    flex-basis: auto;
  }
`;

export const NavigationLinksNavbar = styled.ul.attrs(() => ({
  className: "navbar-nav flex-column",
}))`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${(props) => props.theme.navigation.vertical.borderColor};
  border-right: 1px solid ${(props) => props.theme.navigation.vertical.borderColor};
  border-left: 1px solid ${(props) => props.theme.navigation.vertical.borderColor};
  @media (max-width: 767px) {
    margin-top: 2rem;
  }
`;

export const NavigationLinksWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    width: 100vw;
  }
`;

//HorizontalNavigation Link
export const NavLinkContainer = styled.li.attrs(() => ({
  className: "nav-item",
}))``;

export const NavLink = styled(Link).attrs(() => ({
  className: "nav-link d-flex align-items-center",
}))<NavigationElementStylesType>`
  &&& {
    font-size: 1.125rem;
    height: 100%;
    @media (min-width: 768px) {
      background: ${(props) =>
        "true" === (props.navisactivestr as string) ? props.styles.active.background : props.styles.background};
      color: ${(props) => props.styles.color} !important;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 5px solid ${(props) => props.styles.background};
      border-radius: 9px;
    }

    @media (max-width: 767px) {
      height: 2.5rem;
      padding: 0.625rem 0.625rem 0.625rem 2.25rem;
      color: ${(props) => props.styles.mobile.linkColor};
      background: ${(props) =>
        "true" === (props.navisactivestr as string)
          ? props.styles.active.background
          : props.styles.mobile.linkBackground};
      border-top: 1px solid #000000;
    }
    &:hover {
      color: ${(props) => props.styles.hover.color} !important;
      background: ${(props) => props.styles.active.background} !important;
    }
  }
`;

//HorizontalNavigation Dropdown Link

export const DropdownNavLink = styled(Link).attrs(() => ({ className: "nav-link" }))<NavigationElementStylesType>`
  &&& {
    padding: 8px 0.875rem;
    font-size: 1.125rem;

    @media (min-width: 768px) {
      &&& {
        color: ${(props) => (props.navisactive ? props.styles.active.color : props.styles.sublinks.color)};
        background: ${(props) =>
          props.navisactive ? props.styles.active.background : props.styles.sublinks.background};
      }
    }

    @media (max-width: 767px) {
      &&& {
        color: ${(props) => (props.navisactive ? props.styles.active.color : props.styles.mobile.sublinkColor)};
        background: ${(props) =>
          props.navisactive ? props.styles.active.background : props.styles.mobile.sublinkBackground};
      }

      &:hover {
        cursor: pointer;
        color: ${(props) => props.styles.color};
      }
    }

    &:hover {
      color: ${(props) => props.styles.hover.color} !important;
      background: ${(props) => props.styles.active.background} !important;
    }

    li > a {
      padding: 0 !important;
    }
  }
`;

//Navbar Signout
export const NavBarSignoutStyle = styled.div.attrs(() => ({
  className: "d-flex flex-row info-bar fixed-top align-items-center",
  id: "best-navbar-signout",
}))<NavigationElementStylesType>`
  &&& {
    background: ${(props) =>
      props.horizontal
        ? props.theme.navigation.horizontal.signout.background
        : props.theme.navigation.vertical.signout.background};
    color: ${(props) => props.styles.signout.color};
    z-index: 1052;
    height: 22px;
    width: 100%;
    border-bottom: 1px solid
      ${(props) =>
        props.horizontal
          ? props.theme.navigation.horizontal.signout.borderBottomColor
          : props.theme.navigation.vertical.signout.borderBottomColor};
  }
`;

export const NavbarSignoutEnvHolder = styled.div`
  width: 65px;
  height: 22px;
`;

export const NavbarSignoutPermissionsHolder = styled.div`
  width: 12rem;
  height: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
`;

//Navbar Dropdown
export const NavbarDropdownDropdown = styled.li.attrs(() => ({
  className: "nav-item dropdown",
}))<NavigationElementStylesType>`
  &&& {
    @media (min-width: 768px) {
      &:hover > .dropdown-menu {
        display: block;
      }
    }
  }
`;

export const NavbarDropdownTitle = styled.a.attrs(() => ({
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "true",
}))<NavigationElementStylesType>`
  &&& {
    font-size: 1.125rem;
    ::after {
      display: none;
    }
    align-items: center;
    color: ${(props) => props.styles.color};
    height: 100%;
    @media (min-width: 768px) {
      &&& {
        &:hover {
          cursor: pointer;
          color: ${(props) => props.styles.color};
          background: ${(props) => props.styles?.hover?.background};
          box-shadow: inset 0 0 1px #000000;
        }
        padding-left: 1rem;
        padding-right: 1rem;
        border: 5px solid ${(props) => props.styles.background};
        border-radius: 9px;
        background: ${(props) => (props.navisactive ? props.styles.active.background : props.styles.background)};
      }
    }
    @media (max-width: 767px) {
      &&& {
        height: 2.5rem;
        padding-left: 2.25rem;
        color: ${(props) => props.styles.mobile.dropdownColor};
        background: ${(props) => props.styles.mobile.dropdownBackground};
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
      }

      &:hover {
        cursor: pointer;
        color: ${(props) => props.styles.color};
      }
    }
  }
`;

export const NavbarDropdownLinks = styled.ul<NavigationElementStylesType>`
  &&& {
    list-style-type: none;
    padding: 0;
    border: none;
    z-index: 2000;

    @media (min-width: 768px) {
      background: ${(props) => props.styles.background};
      width: auto;
      border: 1px solid #000000 !important;
      margin: 0 !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      border-radius: 0 !important;

      li > a,
      div {
        white-space: nowrap;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      color: ${(props) => props.styles.color};
      background: ${(props) => props.styles.background};
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0;

      li > a,
      li > div {
        padding-left: 3.75rem !important;
      }

      li:not(:last-child) > a,
      li:not(:last-child) > div {
        border-bottom: 1px solid #000000 !important;
      }
    }
  }
`;

export const NavbarUsernameButton = styled.button.attrs(() => ({
  type: "button",
  className: "btn btn-sm btn-link",
  id: "dropdownMenuButton best-username-dropdown",
  "data-bs-toggle": "dropdown",
  "data-bs-offset": "0,-5",
  "aria-haspopup": "true",
  "aria-expanded": "false",
  "aria-labelledby": "username-dropdown-link",
}))<NavigationElementStylesType>`
  &&& {
    text-decoration: none !important;
    color: ${(props) => props.styles.signout.color};
    outline: 0 !important;
    &:focus {
      outline: 0 !important;
    }
  }
`;

export const UsernameDropdown = styled.div<NavigationElementStylesType>`
  background: ${(props) => props.styles.usernameDropdown.background};
  color: ${(props) => props.styles.usernameDropdown.color};
  //top: -7px !important;
`;

//HorizontalNavigation Dropdown Item
export const NavigationDropdownItemNavItem = styled.div<NavigationElementStylesType>`
  &&& {
    font-size: 1.125rem;
    padding: 8px 1rem;
    @media (min-width: 768px) {
      &&& {
        background: ${(props) => (props.navisactive ? props.styles.active.background : props.styles.background)};
        color: ${(props) => (props.navisactive ? props.styles.active.color : props.styles.color)};
      }
    }
    @media (max-width: 767px) {
      &&& {
        background: ${(props) =>
          props.navisactive ? props.styles.active.background : props.styles.mobile.dropdownBackground};
        color: ${(props) => (props.navisactive ? props.styles.active.color : props.styles.mobile.dropdownColor)};
      }
    }

    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }
`;

//Quick Link
export const QuickLinkStyled = styled(Link)<NavigationElementStylesType>`
  &&& {
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    color: ${(props) => props.styles.color};
    background: ${(props) => (props.navisactive ? props.styles.active.background : props.styles.background)};
    border-radius: 7px;
    &:hover {
      color: ${(props) => props.styles?.hover?.color};
      background: ${(props) => props.styles?.hover?.background};
    }
  }
`;
