import React, { useEffect, useState } from "react";
import { Button, Select, ValueOpt } from "../../form";
import { useTheme } from "../../hooks";
import { CalendarDate, CalendarEvent, CalendarThemeType, CalendarViewType } from "../../types";
import { defaultMonthsValueOptions } from "../../types/DateTypes";
import { Icon } from "../icon";
import CalendarGridView from "./CalendarGridView";
import CalendarListView from "./CalendarListView";
import { ActionsHolder } from "./CalendarStyles";
import { useCalendarDates } from "./CalendarUtil";

type CalendarProps<T> = {
  events: CalendarEvent<T>[];
  fetchEvents: (year: number, month: number) => Promise<any>;
  viewType: CalendarViewType;
  setViewType: (value: CalendarViewType) => void;
  loading?: boolean;
  startDate?: CalendarDate;
  minYear?: number;
  maxYear?: number;
  styles?: CalendarThemeType;
};

const Calendar = <T,>(props: CalendarProps<T>) => {
  const {
    events,
    fetchEvents,
    startDate,
    minYear = 2000,
    maxYear = new Date().getFullYear() + 2,
    loading,
    viewType,
    setViewType,
    styles,
  } = props;
  const { Theme } = useTheme();
  const StylesOverrides: CalendarThemeType = { ...Theme.calendar, ...styles };
  const [year, setYear] = useState<number>(startDate ? startDate.year : new Date().getFullYear());
  const [month, setMonth] = useState<number>(startDate ? startDate.month : new Date().getMonth());
  const [monthSelectValue, setMonthSelectValue] = useState<ValueOpt<number> | undefined>(undefined);
  const [yearSelectValue, setYearSelectValue] = useState<ValueOpt<number> | undefined>(undefined);
  const [yearOptions, setYearOptions] = useState<ValueOpt<number>[]>([]);
  const [monthOptions, setMonthOptions] = useState<ValueOpt<number>[]>([]);
  const { daysInMonth, daysToRender, startDayOfWeek } = useCalendarDates(year, month);

  const changeYear = (value?: ValueOpt<number> | ValueOpt<number>[]) => {
    setYear((value as ValueOpt<number>).value);
  };

  const changeMonth = (value?: ValueOpt<number> | ValueOpt<number>[]) => {
    setMonth((value as ValueOpt<number>).value);
  };

  useEffect(() => {
    setYearSelectValue(yearOptions.find((opt) => opt.value === year));
  }, [yearOptions, year]);

  useEffect(() => {
    setMonthSelectValue(monthOptions.find((opt) => opt.value === month));
  }, [monthOptions, month]);

  useEffect(() => {
    const years: ValueOpt<number>[] = [];
    for (let i = minYear; i <= maxYear; i++) {
      years.push({ value: i, label: i });
    }
    setYearOptions(years);
  }, [minYear, maxYear]);

  useEffect(() => {
    fetchEvents(year, month);
  }, [year, month]);

  useEffect(() => {
    const months: ValueOpt<number>[] = [];
    defaultMonthsValueOptions.forEach((opt: string, index: number) => {
      months.push({ value: index, label: opt });
    });
    setMonthOptions(months);
  }, [defaultMonthsValueOptions]);

  return (
    <div>
      <ActionsHolder>
        <div style={{ width: 100 }}>
          <Select
            id="year"
            placeholder="Year"
            className="me-2"
            value={yearSelectValue}
            onChange={changeYear}
            options={yearOptions}
            disabled={loading}
          />
        </div>
        <div className="d-flex me-2">
          <Button
            onClick={() => {
              setMonth(month - 1);
            }}
            disabled={loading || month === 0}
            styles={{
              button: {
                ...Theme.button,
                default: {
                  ...Theme.button.default,
                  color: StylesOverrides.actions.color,
                  "background-color": StylesOverrides.actions.background,
                },
              },
              link: { ...Theme.link },
            }}
          >
            <Icon iconName="fa-caret-left" styles={{ ...Theme.icon, color: StylesOverrides.actions.color }} />
          </Button>
          <div style={{ width: 100 }}>
            <Select
              id="month"
              placeholder="Month"
              value={monthSelectValue}
              onChange={changeMonth}
              options={monthOptions}
              disabled={loading}
            />
          </div>
          <Button
            onClick={() => {
              setMonth(month + 1);
            }}
            disabled={loading || month === 11}
            styles={{
              button: {
                ...Theme.button,
                default: {
                  ...Theme.button.default,
                  color: StylesOverrides.actions.color,
                  "background-color": StylesOverrides.actions.background,
                },
              },
              link: { ...Theme.link },
            }}
          >
            <Icon iconName="fa-caret-right" styles={{ ...Theme.icon, color: StylesOverrides.actions.color }} />
          </Button>
        </div>
        <Button
          onClick={() => {
            setViewType("list");
          }}
          styles={{
            button: {
              ...Theme.button,
              default: {
                ...Theme.button.default,
                color: StylesOverrides.actions.color,
                "background-color":
                  viewType === "list" ? StylesOverrides.actions.activeColor : StylesOverrides.actions.background,
              },
            },
            link: { ...Theme.link },
          }}
          className="me-2"
          disabled={loading}
        >
          <Icon iconName="fa-bars" styles={{ ...Theme.icon, color: StylesOverrides.actions.color }} />
        </Button>
        <Button
          onClick={() => {
            setViewType("grid");
          }}
          styles={{
            button: {
              ...Theme.button,
              default: {
                ...Theme.button.default,
                color: StylesOverrides.actions.color,
                "background-color":
                  viewType === "grid" ? StylesOverrides.actions.activeColor : StylesOverrides.actions.background,
              },
            },
            link: { ...Theme.link },
          }}
          className="me-2"
          disabled={loading}
        >
          <Icon iconName="fa-calendar" styles={{ ...Theme.icon, color: StylesOverrides.actions.color }} />
        </Button>
      </ActionsHolder>
      {viewType === "grid" ? (
        <CalendarGridView
          events={events}
          loading={loading}
          daysInMonth={daysInMonth}
          daysToRender={daysToRender}
          year={year}
          month={month}
          startDayOfWeek={startDayOfWeek}
          styles={StylesOverrides}
        />
      ) : (
        <CalendarListView
          events={events}
          loading={loading}
          daysInMonth={daysInMonth}
          daysToRender={daysToRender}
          year={year}
          month={month}
          styles={StylesOverrides}
        />
      )}
    </div>
  );
};

export default Calendar;
