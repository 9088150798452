import { ColorType, ThemeType } from "../types";
import Traditional, { TraditionalColors } from "./Traditional";

declare type BLUEColorType = ColorType & {
  lightblue: string;
  greyblue: string;
  cerulean: string;
  navy: string;
  th: string;
  "slate-blue": string;
  "ice-blue": string;
  "ocean-blue": string;
  "light-blue-grey": string;
  "core-icon-active": string;
  "core-icon-dark": string;
  "primary-button-border": string;
  "primary-button-background": string;
  "dark-red": string;
  placeholder: string;
};

export const BLUEColors: BLUEColorType = {
  ...TraditionalColors,
  lightblue: "#71c4fa",
  "ocean-blue": "#0363a2",
  "light-blue-grey": "#b8cde6",
  greyblue: "#8593a3",
  cerulean: "#0281d4",
  "ice-blue": "#ebf3f9",
  slate: "#455e6c",
  th: "#ccd9e7",
  navy: "#031e42",
  "mlb-navy": "#041e42",
  "mlb-red": "#bf0d3e",
  green: "#00a651",
  "core-icon-active": "#4c8cee",
  "core-icon-dark": "#002e6d",
  "slate-blue": "#819bba",
  "primary-button-background": "#01132c",
  "primary-button-border": "#354d6e",
  "dark-red": "#9a0b32",
  placeholder: "#92a5ba",
};

const FontWeights = {
  thin: "100",
  light: "300",
  normal: "400",
  bold: "500",
};

const Blue: ThemeType = {
  ...Traditional,
  name: "Blue",
  fontFamily: '"Roboto", sans-serif',
  colors: BLUEColors,
  background: BLUEColors.greyblue,
  label: {
    ...Traditional.label,
    color: BLUEColors["slate-blue"],
    "asterisk-color": BLUEColors["mlb-red"],
    "font-weight": "normal",
    size: "0.75rem",
  },
  placeholder: {
    color: BLUEColors.placeholder,
    "font-style": "italic",
    "font-weight": 300,
  },
  input: {
    color: BLUEColors.navy,
    size: "0.875rem",
    height: "auto",
    "border-color": BLUEColors["light-blue-grey"],
    background: BLUEColors.white,
    required: {
      color: BLUEColors.navy,
      background: BLUEColors.white,
      "border-color": BLUEColors["light-blue-grey"],
    },
    missed: {
      color: BLUEColors["mlb-red"],
      background: BLUEColors.white,
      "border-color": BLUEColors["mlb-red"],
    },
    disabled: {
      color: BLUEColors.black,
      background: BLUEColors.snow,
      "border-color": BLUEColors.fog,
    },
  },
  select: {
    ...Traditional.select,
    borderColor: BLUEColors["light-blue-grey"],
  },
  card: {
    ...Traditional.card,
    header: {
      ...Traditional.card.header,
      borderBottom: "0px",
      backgroundColor: BLUEColors["ice-blue"],
    },
    body: {
      ...Traditional.card.body,
      background: BLUEColors["ice-blue"],
    },
  },
  icon: {
    ...Traditional.icon,
    color: BLUEColors.navy,
    size: "16px",
  },
  accordion: {
    ...Traditional.accordion,
    header: {
      ...Traditional.accordion.header,
      background: BLUEColors.white,
      border: "solid 1px " + BLUEColors["light-blue-grey"],
      color: BLUEColors.navy,
      "font-size": "16px",
      padding: "10px 10px 10px 10px",
    },
    body: {
      ...Traditional.accordion.body,
      background: BLUEColors["ice-blue"],
      border: "0px",
    },
  },
  button: {
    "border-radius": "0.375rem",
    size: "0.875rem",
    padding: "0.3125rem .75rem",
    "line-height": "1.5",
    "font-weight": FontWeights.bold,
    primary: {
      "border-color": BLUEColors["primary-button-border"],
      "background-color": BLUEColors["primary-button-background"],
      color: BLUEColors.white,
    },
    disabled: {
      "border-color": BLUEColors["light-blue-grey"],
      "background-color": BLUEColors["ice-blue"],
      color: BLUEColors["light-blue-grey"],
    },
    //TODO: Set proper colors for other button types as needed
    secondary: {
      "border-color": BLUEColors.navy,
      "background-color": TraditionalColors.white,
      color: BLUEColors.navy,
    },
    danger: {
      "border-color": BLUEColors["primary-button-border"],
      "background-color": BLUEColors["primary-button-background"],
      color: BLUEColors["light-blue-grey"],
    },
    loading: {
      "border-color": BLUEColors["primary-button-border"],
      "background-color": BLUEColors["primary-button-background"],
      color: BLUEColors["light-blue-grey"],
    },
    default: {
      "border-color": BLUEColors["primary-button-border"],
      "background-color": BLUEColors["primary-button-background"],
      color: BLUEColors["light-blue-grey"],
    },
    success: {
      "border-color": BLUEColors["primary-button-border"],
      "background-color": BLUEColors["primary-button-background"],
      color: BLUEColors["light-blue-grey"],
    },
    small: {
      size: "0.75rem",
      padding: "0.25rem 1rem",
    },
    large: {
      size: "1.125rem",
      padding: "0.25rem 1rem",
    },
    spinner: {
      size: 15,
    },
  },
  typography: {
    span: {
      size: ".9rem",
      color: BLUEColors.navy,
      weight: FontWeights.normal,
      style: "normal",
      width: "100%",
    },
    div: {
      size: ".9rem",
      color: BLUEColors.navy,
      weight: FontWeights.normal,
      style: "normal",
      width: "100%",
    },
    body1: {
      size: ".9rem",
      color: BLUEColors.navy,
      weight: FontWeights.normal,
      style: "normal",
      width: "100%",
    },
    body2: {
      size: "1rem",
      color: BLUEColors.navy,
      weight: FontWeights.normal,
      style: "normal",
      width: "100%",
    },
    body3: {
      size: "1.1rem",
      color: BLUEColors.navy,
      weight: FontWeights.normal,
      style: "normal",
      width: "100%",
    },
    caption: {
      size: ".75rem",
      color: BLUEColors.navy,
      weight: FontWeights.normal,
      style: "italic",
      width: "100%",
    },
    h1: {
      size: "6rem",
      color: BLUEColors.navy,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h2: {
      size: "3.75rem",
      color: BLUEColors.navy,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h3: {
      size: "3rem",
      color: BLUEColors.navy,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h4: {
      size: "2.125rem",
      color: BLUEColors.navy,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h5: {
      size: "1.5rem",
      color: BLUEColors.navy,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h6: {
      size: "1.25rem",
      color: BLUEColors.navy,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    subtitle1: {
      size: ".8rem",
      color: BLUEColors.white,
      weight: FontWeights.normal,
      style: "normal",
      width: "100%",
    },
    subtitle2: {
      size: "1.125rem",
      color: BLUEColors["light-blue-grey"],
      weight: FontWeights.thin,
      style: "normal",
      width: "100%",
    },
  },
  navigation: {
    vertical: {
      ...Traditional.navigation.vertical,
      size: "0.875rem",
      fontSize: "0.875rem",
      background: BLUEColors.navy,
      borderColor: BLUEColors.black,
      color: BLUEColors.white,
      paddingLeft: "21px",
      active: {
        color: BLUEColors.navy,
        background: BLUEColors.lightblue,
      },
      hover: {
        color: BLUEColors.navy,
        background: BLUEColors.lightblue,
      },
      signout: {
        color: BLUEColors.navy,
        background: BLUEColors.lightblue,
        borderBottomColor: BLUEColors.white,
      },
      usernameDropdown: {
        color: BLUEColors.navy,
        background: BLUEColors.white,
        hover: {
          color: BLUEColors.navy,
          background: BLUEColors.white,
        },
      },
      sublinks: {
        color: BLUEColors.lightblue,
        background: BLUEColors.black,
        hover: {
          color: BLUEColors.navy,
          background: BLUEColors.lightblue,
        },
      },
      sublinksMenu: {
        padding: "0px",
      },
      topBar: {
        color: BLUEColors.white,
        background: BLUEColors.navy,
      },
      leftLogo: {
        height: "53px",
        width: "149px",
        margin: "0 21px",
      },
      centerLogo: {
        height: "35px",
        width: "93px",
        margin: "",
      },
    },
    horizontal: {
      ...Traditional.navigation.horizontal,
      centerLogo: {
        height: "35px",
        width: "93px",
        margin: "-3px 0 0 0",
      },
      active: {
        color: BLUEColors.navy,
        background: BLUEColors.lightblue,
      },
      mobile: {
        ...Traditional.navigation.horizontal.mobile,
        dropdownColor: BLUEColors.white,
        dropdownBackground: BLUEColors["mlb-navy"],
        sublinkColor: BLUEColors.lightblue,
        sublinkBackground: BLUEColors.black,
        permissionsBackground: BLUEColors.lightblue,
        permissionsColor: BLUEColors.navy,
      },
      sublinks: {
        color: BLUEColors.lightblue,
        background: BLUEColors.black,
        hover: {
          color: BLUEColors.lightblue,
          background: BLUEColors.black,
        },
      },
    },
  },
  paper: {
    ...Traditional.paper,
    borderColor: BLUEColors.slate,
    background: BLUEColors["ice-blue"],
  },
  table: {
    ...Traditional.table,
    th: {
      background: BLUEColors.th,
      color: BLUEColors.navy,
      fontWeight: 500,
    },
    tdInfo: {
      background: BLUEColors.white,
      color: BLUEColors.navy,
      width: "13.125rem",
      fontWeight: "normal",
    },
  },
  datatable: {
    ...Traditional.datatable,
    header: {
      ...Traditional.datatable.header,
      background: BLUEColors.th,
      color: BLUEColors.navy,
      fontWeight: 500,
    },
    row: {
      ...Traditional.datatable.row,
      color: BLUEColors.navy,
      separatorColor: BLUEColors["ice-blue"],
    },
  },
  datepicker: {
    ...Traditional.datepicker,
    input: {
      ...Traditional.datepicker.input,
      actionColor: BLUEColors.navy,
      actionHoverColor: BLUEColors.navy,
    },
    calendar: {
      borderColor: BLUEColors.black,
      borderRadius: "6px",
      header: {
        background: BLUEColors.th,
        color: BLUEColors.navy,
        borderColor: BLUEColors.black,
        // fontFamily: "Roboto",
        fontSize: "0.875rem",
        fontWeight: 500,
        select: {
          background: BLUEColors.white,
          color: BLUEColors.navy,
        },
        dayOfWeek: {
          color: BLUEColors.navy,
          fontSize: "0.6875rem",
          fontWeight: 500,
        },
        triangle: {
          visibility: "hidden",
        },
      },
      body: {
        background: BLUEColors.white,
        color: BLUEColors.navy,
        borderColor: BLUEColors.black,
        // fontFamily: "Roboto",
        fontSize: "0.75rem",
        fontWeight: 500,
        day: {
          color: BLUEColors.navy,
          colorOutsideMonth: BLUEColors["light-blue-grey"],
          active: {
            color: BLUEColors.white,
            background: BLUEColors.navy,
            borderColor: BLUEColors.navy,
          },
          disabled: {
            color: BLUEColors["light-blue-grey"],
            background: BLUEColors.white,
            borderColor: BLUEColors.white,
          },
          hover: {
            background: BLUEColors["light-blue-grey"],
          },
        },
      },
    },
  },
  fileAttachment: {
    ...Traditional.fileAttachment,
  },
  modal: {
    ...Traditional.modal,
    borderColor: BLUEColors["navy"],
    backdrop: BLUEColors["navy"],
    backdropOpacity: 0,
    header: {
      ...Traditional.modal.header,
      background: BLUEColors["th"],
      bottomBorderColor: BLUEColors["navy"],
      closeIcon: "fa-times",
      fontColor: BLUEColors["mlb-navy"],
      fontSize: "24px",
      fontWeight: FontWeights.normal,
    },
    body: {
      ...Traditional.modal.body,
      background: BLUEColors["ice-blue"],
      fontColor: BLUEColors["mlb-navy"],
    },
    footer: { ...Traditional.modal.footer, background: BLUEColors["th"], borderTopColor: BLUEColors["navy"] },
  },
};

export default Blue;
