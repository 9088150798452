import clsx from "clsx";
import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import Select, { ValueOpt } from "../../../form/inputs/select/Select";
import { useBreakpoints, useTheme } from "../../../hooks";
import { DatatableThemeType } from "../../../types";
import { Icon } from "../../icon";
import { DesktopTabletView } from "../../view";
import { PageSizeHolder, PaginationContainer } from "./DatatableStyles";

const PageSizes: ValueOpt<PaginationPageSizes>[] = [
  {
    value: 25,
    label: "25 rows",
  },
  {
    value: 50,
    label: "50 rows",
  },
  {
    value: 100,
    label: "100 rows",
  },
];

export type PaginationPageSizes = 25 | 50 | 100;

export type PaginationType = {
  totalItems: number;
  pageNumber: number;
  setPageNumber: (value: number) => void;
  pageSize: PaginationPageSizes;
  setPageSize: (value: PaginationPageSizes) => void;
  paginationTexts?: PaginationTexts;
};

export type PaginationTexts = {
  mobileNext?: React.ReactNode;
  mobilePrevious?: React.ReactNode;
  next?: React.ReactNode;
  previous?: React.ReactNode;
};

type PaginationProps = {
  pagination: PaginationType;
  onValueChanged?: () => void;
  className?: string;
  paginationTexts?: PaginationTexts;
  styles?: DatatableThemeType;
};

const defaultPaginationTexts = {
  next: <Icon iconName="fa-angle-right" />,
  previous: <Icon iconName="fa-angle-left" />,
  mobileNext: "Next",
  mobilePrevious: "Previous",
};

const Pagination: React.FC<PaginationProps> = ({ pagination, onValueChanged, className, styles = {} }) => {
  const { isMobile } = useBreakpoints();
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  const { paginationTexts } = pagination;

  const onPageChange = (selectedItem: { selected: number }) => {
    if (!!pagination?.setPageNumber) {
      pagination.setPageNumber(selectedItem.selected);
    }
    if (!!onValueChanged) {
      onValueChanged();
    }
  };

  const getPageSizeOption = (): ValueOpt<PaginationPageSizes> => {
    if (!!pagination?.pageSize) {
      return PageSizes.find((ps: ValueOpt<PaginationPageSizes>) => ps.value === pagination.pageSize) || PageSizes[0];
    } else {
      return PageSizes[0];
    }
  };

  useEffect(() => {
    if (isMobile) {
      pagination.setPageSize(PageSizes[0].value);
      pagination.setPageNumber(0);
    }
  }, [isMobile]);

  return (
    <PaginationContainer styles={StylesOverride} className={clsx("d-flex", className)}>
      <ReactPaginate
        pageCount={Math.ceil(pagination.totalItems / pagination.pageSize)}
        onPageChange={onPageChange}
        pageRangeDisplayed={isMobile ? 1 : 2}
        marginPagesDisplayed={isMobile ? 1 : 1}
        forcePage={pagination.pageNumber}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        activeClassName="active"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        nextLabel={
          isMobile
            ? !!paginationTexts?.mobileNext
              ? paginationTexts.mobileNext
              : defaultPaginationTexts.mobileNext
            : !!paginationTexts?.next
            ? paginationTexts.next
            : defaultPaginationTexts.next
        }
        previousLabel={
          isMobile
            ? !!paginationTexts?.mobilePrevious
              ? paginationTexts.mobilePrevious
              : defaultPaginationTexts.mobilePrevious
            : !!paginationTexts?.previous
            ? paginationTexts.previous
            : defaultPaginationTexts.previous
        }
      />
      <DesktopTabletView>
        <PageSizeHolder>
          <Select
            id="pageSizeOptions"
            options={PageSizes}
            value={getPageSizeOption()}
            onChange={(value?: ValueOpt<PaginationPageSizes> | ValueOpt<PaginationPageSizes>[]) => {
              const val: ValueOpt<PaginationPageSizes> = value as ValueOpt<PaginationPageSizes>;
              pagination.setPageNumber(0);
              pagination.setPageSize(val.value);
              if (!!onValueChanged) {
                onValueChanged();
              }
            }}
          />
        </PageSizeHolder>
      </DesktopTabletView>
    </PaginationContainer>
  );
};

export default Pagination;
