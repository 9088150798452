import styled from "styled-components";

export type TooltipStyles = {
  background?: string;
  color?: string;
};

type TooltipThemeStyles = {
  styles: TooltipStyles;
};

export const TooltipContainer = styled.div<TooltipThemeStyles>`
  background: ${(props) => props.styles.background};
  color: ${(props) => props.styles.color};
  padding: 1rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.4);
`;
