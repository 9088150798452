export interface DashboardItem {
  dashboardTitle: string;
  label: string;
  value: DashboardType;
}

export type DashboardType = "Admin" | "TD" | "Operator" | "Floater" | "Media" | "Support" | "Camera";

export const DashboardConstants: Record<string, DashboardItem> = {
  ADMIN: {
    label: "ADM",
    value: "Admin",
    dashboardTitle: "Admin",
  },
  TD: {
    label: "TD",
    value: "TD",
    dashboardTitle: "Technical Director",
  },
  OPERATOR: {
    label: "OPR",
    value: "Operator",
    dashboardTitle: "Operator",
  },
  FLOATER: {
    label: "FLT",
    value: "Floater",
    dashboardTitle: "Floater",
  },
  MEDIA: {
    label: "MED",
    value: "Media",
    dashboardTitle: "Replay Media",
  },
  SUPPORT: {
    label: "SUP",
    value: "Support",
    dashboardTitle: "ROC Support",
  },
  CAM: {
    label: "CAM",
    value: "Camera",
    dashboardTitle: "Camera",
  },
};
