import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types/ThemeType";
import { NavigationLinksNavbar, NavigationLinksNavbarHolder, NavigationLinksWrapper } from "./NavigationElementStyles";

type NavigationLinksProps = {
  isProd?: boolean;
  open: boolean;
  styles: NavigationValueThemeType;
};

const NavigationLinks: React.FC<NavigationLinksProps> = ({ isProd, open, children, styles }) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  const collapseClass = open ? "show" : "hide";
  return (
    <NavigationLinksWrapper>
      <NavigationLinksNavbarHolder
        isProd={isProd}
        className={`navbar-collapse ${collapseClass}`}
        id="navbarSupportedContent"
        styles={StylesOverride}
      >
        <NavigationLinksNavbar>{children}</NavigationLinksNavbar>
      </NavigationLinksNavbarHolder>
    </NavigationLinksWrapper>
  );
};

export default NavigationLinks;
