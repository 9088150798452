import styled from "styled-components";
import { StickyFooterThemeType } from "../../../types";

export type StickyFooterStylesProps = {
  justifyContent?: string;
  styles?: StickyFooterThemeType;
  startsOpen?: boolean;
};

export const StickyFooterDesktopHolder = styled.div`
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

export const StickyFooterMobileHolder = styled.div.attrs(() => ({
  className: "fixed-bottom",
}))<StickyFooterStylesProps>`
  &&& {
    box-shadow: 0 -4px 1.25rem 0 rgba(0, 0, 0, 0.2);
    background: ${(props) => props.styles?.background};
    button:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }
`;

export const StickyFooterData = styled.div.attrs(() => ({
  className: "card card-body",
}))<StickyFooterStylesProps>`
  &&& {
    background: ${(props) => props.styles?.background};
  }
`;

export const StickyFooterIconHolder = styled.div.attrs<StickyFooterStylesProps>(() => ({
  className: "d-flex justify-content-center align-items-center",
}))`
  width: 100%;
  height: 38px;
  text-align: center;
`;

export const StickyFooterCollapse = styled.div.attrs(() => ({
  "data-bs-toggle": "collapse",
  href: "#stickFooterButtons",
  "aria-controls": "stickFooterButtons",
}))``;

export const StickyFooterCollapseData = styled.div.attrs(() => ({
  id: "stickFooterButtons",
}))``;
