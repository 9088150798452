import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../hooks";
import { RadioLabelValue, RadioNA, RadioThemeType, RadioValue } from "../../../types";
import { BaseInputProps } from "../../../types/InputTypes";
import { ReadOnlyFormatter } from "../input";
import Label from "../label/Label";
import { LabelHolder, RadioInputStyled, RadioStyled } from "./RadioGroupStyles";

type RadioProps = BaseInputProps & {
  onChange: (value?: RadioValue) => void;
  value?: RadioValue;
  checked: boolean;
  styles?: RadioThemeType;
  optionStyles?: RadioThemeType;
  inputStyles?: RadioThemeType;
};

const Radio: React.FC<RadioProps> = ({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  disabled = false,
  missed = false,
  styles = {},
  optionStyles = {},
  inputStyles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: RadioThemeType = {
    ...Theme.radio,
    ...styles,
  };
  const OptionStylesOverride: RadioThemeType = {
    ...Theme.radio,
    ...optionStyles,
  };
  const InputStylesOverride: RadioThemeType = {
    ...Theme.radio,
    ...inputStyles,
  };

  return (
    <RadioStyled styles={OptionStylesOverride}>
      <RadioInputStyled
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        disabled={disabled}
        styles={InputStylesOverride}
      />
      <LabelHolder>
        <Label htmlFor={id} className="bcr-radio-label" styles={StylesOverride}>
          {label}
        </Label>
      </LabelHolder>
    </RadioStyled>
  );
};

export type RadioGroupProps = {
  id: string;
  name: string;
  options: RadioLabelValue[];
  onChange: (value?: RadioValue) => void;
  value?: RadioValue;
  label?: React.ReactNode;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  missed?: boolean;
  vertical?: boolean;
  showNA?: boolean;
  gutterBottom?: boolean;
  className?: string;
  styles?: RadioThemeType;
  optionStyles?: RadioThemeType;
  inputStyles?: RadioThemeType;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  name,
  label,
  options,
  value,
  onChange,
  required = false,
  readOnly = false,
  disabled = false,
  missed = false,
  vertical = false,
  styles = {},
  optionStyles = {},
  inputStyles = {},
  className = "",
  showNA = false,
  gutterBottom = false,
}) => {
  const toggleNA = (value: boolean) => {
    if (value) {
      onChange(RadioNA);
    } else {
      onChange(undefined);
    }
  };

  const isNA: boolean = showNA && value === RadioNA;

  const getReadOnlyValue = (options: RadioLabelValue[], value: RadioValue | undefined): React.ReactNode => {
    const element: RadioLabelValue | undefined = options.find((opt) => opt.value === value);
    if (!!element) {
      return element.label;
    } else {
      return "";
    }
  };

  return (
    <div className={clsx("bcr-radio-group", className, { "mb-2": gutterBottom })}>
      <Label
        htmlFor={id}
        className="bcr-radio-group-label"
        required={required && !readOnly}
        showNA={showNA && !readOnly}
        isNA={isNA}
        onChange={toggleNA}
      >
        {label}
      </Label>
      {readOnly ? (
        <ReadOnlyFormatter value={getReadOnlyValue(options, value)} id={id} />
      ) : (
        <div className={"bcr-radio-group-options d-flex mt-2" + (vertical ? " flex-column" : "")}>
          {options.map((option: RadioLabelValue) => (
            <Radio
              key={option.value}
              id={id + "-" + option.label}
              name={name}
              label={option.label}
              value={option.value}
              styles={styles}
              optionStyles={optionStyles}
              inputStyles={inputStyles}
              checked={!isNA && option.value === value}
              onChange={onChange}
              disabled={disabled || isNA}
              missed={missed}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RadioGroup;
