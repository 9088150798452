import React from "react";
import ValidationMessage, { ValidationMessageType } from "./ValidationMessage";

type ValidationMessagesProps = {
  /** validation messages to show */
  validationMessages?: ValidationMessageType[];
};

const ValidationMessages: React.FC<ValidationMessagesProps> = ({ validationMessages }) => {
  return (
    <>
      {validationMessages?.length &&
        validationMessages.map((v: ValidationMessageType, index: number) => (
          <ValidationMessage key={index} type={v.type} message={v.message} validationFunction={v.validationFunction} />
        ))}
    </>
  );
};

export default ValidationMessages;
