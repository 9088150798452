import React, { useEffect } from "react";
import { useTheme } from "../../hooks/useTheme";
import { CollapseItemThemeType } from "../../types";
import { Body } from "./AccordionStyles";

export type AccordionBodyProps = {
  /** if the body is open */
  isOpen?: boolean;
  /** Action when the body is expanded */
  onExpansion?: () => void;
  /** overrideable styles */
  styles?: CollapseItemThemeType;
};

/**
 * Accordion Body of content to display/hide
 */
const AccordionBody: React.FC<AccordionBodyProps> = ({ children, isOpen, onExpansion, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: CollapseItemThemeType = { ...Theme.accordion.body, ...styles };

  useEffect(() => {
    if (isOpen && onExpansion) {
      onExpansion();
    }
  }, [isOpen]);

  return <Body styles={StylesOverride}>{children}</Body>;
};

export default AccordionBody;
