import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types/ThemeType";
import { VerticalNavigationContainerStyled } from "./VerticalNavStyles";

type VerticalNavigationContainerProps = {
  loggedIn: boolean;
  styles?: NavigationValueThemeType;
};

const VerticalNavigationContainer: React.FC<VerticalNavigationContainerProps> = ({ children, loggedIn, styles }) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = !!styles
    ? { ...Theme.navigation, ...styles }
    : Theme.navigation.vertical;
  return (
    <VerticalNavigationContainerStyled loggedIn={loggedIn} styles={StylesOverride}>
      {children}
    </VerticalNavigationContainerStyled>
  );
};

export default VerticalNavigationContainer;
