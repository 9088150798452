import React from "react";
import { CalendarEvent, CalendarThemeType } from "../../types";
import CalendarRenderEvent from "./CalendarRenderEvent";
import { CalendarListViewDate, CalendarListViewDayContainer } from "./CalendarStyles";

type CalendarListViewDayProps<T> = {
  event?: CalendarEvent<T>;
  month: number;
  dayOfMonth: number;
  year: number;
  styles: CalendarThemeType;
};

const CalendarListViewDay = <T,>(props: CalendarListViewDayProps<T>) => {
  const { event, month, dayOfMonth, year, styles } = props;
  return (
    <CalendarListViewDayContainer styles={styles}>
      <CalendarListViewDate styles={styles}>
        {month}/{dayOfMonth}
      </CalendarListViewDate>
      <CalendarRenderEvent event={event} dayOfMonth={dayOfMonth} month={month} year={year} viewType="list" />
    </CalendarListViewDayContainer>
  );
};

export default CalendarListViewDay;
