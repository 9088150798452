import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { TableThemeType } from "../../types";
import { TableHeaderStyled } from "./TableStyles";

export type TableHeaderProps = {
  /** overrideable styles */
  styles?: TableThemeType;
};

const TableHeader: React.FC<TableHeaderProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: TableThemeType = { ...Theme.table, ...styles };

  return (
    <TableHeaderStyled styles={StylesOverride} {...rest}>
      {children}
    </TableHeaderStyled>
  );
};

export default TableHeader;
