import React from "react";
import { useTheme } from "../../../../hooks/useTheme";
import { IconThemeType, ModalHeaderThemeType } from "../../../../types";
import Icon from "../../../icon/Icon";
import { ModalHeaderDiv } from "./ModalHeaderStyles";

export type ModalHeaderProps = {
  children: React.ReactNode;
  styles?: ModalHeaderThemeType;
  close?: () => void;
};

const ModalHeader: React.FC<ModalHeaderProps> = ({ children, styles = {}, close }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalHeaderThemeType = { ...Theme.modal.header, ...styles };
  const IconStylesOverride: IconThemeType = { ...Theme.icon };
  if (StylesOverride.iconSize) {
    IconStylesOverride.size = styles.iconSize;
  }
  const closeIcon = StylesOverride.closeIcon ?? "fa-times-circle";
  return (
    <ModalHeaderDiv styles={StylesOverride}>
      {children} {!!close && <Icon iconName={closeIcon} onClick={close} styles={IconStylesOverride} />}
    </ModalHeaderDiv>
  );
};

ModalHeader.displayName = "ModalHeader";

export default ModalHeader;
