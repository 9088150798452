import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { ClipLoader, PulseLoader } from "react-spinners";
import { useTheme } from "../../hooks";
import { ButtonLoadingType, ButtonSizes, ButtonThemeType, ButtonType, ButtonVariant, LinkThemeType } from "../../types";
import { ButtonLinkStyled, ButtonStyled } from "./ButtonStyles";

const getClassName = (buttonType: ButtonVariant): string => {
  switch (buttonType.toLowerCase()) {
    case "default":
      return "btn-light";
    case "success":
      return "btn-success";
    case "link":
      return "btn-link";
    case "danger":
      return "btn-danger";
    case "secondary":
      return "btn-secondary";
    case "primary":
    default:
      return "btn-primary";
  }
};

const getSizeClassName = (size: ButtonSizes): string => {
  switch (size.toLowerCase()) {
    case "sm":
      return "btn-sm";
    case "md":
      return "";
    case "lg":
      return "btn-lg";
    default:
      return "";
  }
};

const getLoadingClass = (loading: boolean): string => {
  return loading ? "btn-loading" : "";
};

export type InButtonOverrideStyles = {
  button?: ButtonThemeType;
  link?: LinkThemeType;
};

export type ButtonOverrideStyles = {
  button: ButtonThemeType;
  link: LinkThemeType;
};

export type ButtonProps = {
  /** Click function for button */
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  /** Type of button */
  type?: ButtonType;
  /** Button Color/Variant */
  variant?: ButtonVariant;
  /** If the button is disabled */
  disabled?: boolean;
  /** if the button is loading */
  loading?: boolean;
  /** Size of the button */
  size?: ButtonSizes;
  /** class name to pass to the button */
  className?: string;
  /** badge count on button */
  badgeCount?: number;
  /** Adorner before the content of button */
  startAdorner?: React.ReactNode;
  /** Adorner after the content of button */
  endAdorner?: React.ReactNode;
  /** type of loading to use when loading */
  loadingType?: ButtonLoadingType;
  /** overrideable styles */
  styles?: InButtonOverrideStyles;
};

/**
 * Button for clicking
 */
const Button: React.FC<ButtonProps> = ({
  children,
  type = "button",
  variant = "default",
  disabled = false,
  loading = false,
  size = "md",
  className = "",
  onClick,
  badgeCount,
  startAdorner,
  endAdorner,
  loadingType = "spinner",
  styles = { button: {}, link: {} },
  ...rest
}) => {
  const { Theme } = useTheme();
  const StylesOverride: ButtonOverrideStyles = {
    button: { ...Theme.button, ...styles.button },
    link: { ...Theme.link, ...styles.link },
  };
  const spinnerColor = variant !== "link" ? StylesOverride.button[variant].color : "";

  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const getLoader = () => {
    switch (loadingType) {
      case "pulse":
        return <PulseLoader size={10} color={spinnerColor} />;
      case "spinner":
      default:
        return <ClipLoader size={15} color={spinnerColor} />;
    }
  };

  // maintain original width of button while loading spinner shows
  useEffect(() => {
    if (!!ref) {
      const buttonWidth = !!ref?.current ? ref.current.offsetWidth : 0;
      setWidth(buttonWidth);
    }
  }, [ref, badgeCount, startAdorner, endAdorner, children]);

  return (
    <>
      {variant === "link" ? (
        <ButtonLinkStyled
          onClick={onClick}
          disabled={disabled}
          className={clsx("bcr-button-link btn btn-link", getSizeClassName(size), className)}
          styles={StylesOverride}
          {...rest}
        >
          <span className="me-1">{startAdorner}</span> {children} <span className="ms-1">{endAdorner}</span>
        </ButtonLinkStyled>
      ) : (
        <ButtonStyled
          type={type}
          className={clsx(
            `bcr-button btn`,
            getClassName(variant),
            getSizeClassName(size),
            getLoadingClass(loading),
            className,
          )}
          isButtonLoading={loading}
          size={size}
          onClick={onClick}
          disabled={disabled || loading}
          styles={StylesOverride}
          {...rest}
        >
          {loading ? (
            <div ref={ref} style={{ width: width + "px" }}>
              {getLoader()}
            </div>
          ) : (
            <>
              <div ref={ref}>
                {!!startAdorner && <span className="me-1">{startAdorner}</span>} {children}
                {!!endAdorner && <span className="ms-1">{endAdorner}</span>}
              </div>
              {badgeCount !== undefined && <span className="badge badge-light">{badgeCount}</span>}
            </>
          )}
        </ButtonStyled>
      )}
    </>
  );
};

export default Button;
