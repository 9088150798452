import clsx from "clsx";
import React, { useEffect } from "react";
import { CollapseProvider, useCollapse } from "./CollapseContext";
import CollapseItem, { CollapseItemProps } from "./CollapseItem";

type CollapseProps = {
  children: React.ReactNode;
  className?: string;
  singleOpen?: boolean;
  setCollapseAll?: (value: () => void) => void;
  setOpenAll?: (value: () => void) => void;
  animation?: boolean;
};

export interface CollapseType extends React.FC<CollapseProps> {
  Item: React.FC<CollapseItemProps>;
}

const CollapseContent: React.FC<CollapseProps> = ({
  children,
  className = "",
  singleOpen = false,
  setCollapseAll,
  setOpenAll,
  animation = true,
}) => {
  const { open, setOpen, setAnimation, setSingleOpen } = useCollapse();

  const openAll = (): void => {
    setOpen(
      Object.keys(open).reduce(
        (acc, key) => ({
          ...acc,
          [key]: true,
        }),
        {},
      ),
    );
  };

  const collapseAll = (): void => {
    setOpen(
      Object.keys(open).reduce(
        (acc, key) => ({
          ...acc,
          [key]: false,
        }),
        {},
      ),
    );
  };

  if (setCollapseAll) {
    setCollapseAll(collapseAll);
  }
  if (setOpenAll) {
    setOpenAll(openAll);
  }

  useEffect(() => {
    setAnimation(animation);
  }, [animation]);

  useEffect(() => {
    setSingleOpen(singleOpen);
  }, [singleOpen]);

  return <div className={clsx("best-collapse", className)}>{children}</div>;
};

const Collapse: CollapseType = ({ ...rest }) => (
  <CollapseProvider>
    <CollapseContent {...rest} />
  </CollapseProvider>
);

Collapse.Item = CollapseItem;

export default Collapse;
