import React from "react";
import { Accordion, Icon } from "../../components";
import { CustomMobileAccordionType } from "../../components/accordion/MobileAccordions";
import Label from "../inputs/label/Label";
import { FileDTO } from "./FileAttachment";
import LabelFormatter from "./LabelFormatter";

type FileAttachmentsMobileProps = CustomMobileAccordionType<FileDTO> & {
  data: FileDTO;
};

const FileAttachmentsMobile = (props: FileAttachmentsMobileProps) => {
  const { data, ...rest } = props;
  return (
    <Accordion {...rest} accordionKey={data.name}>
      <Accordion.Header customActionButton={<Icon iconName="fa-trash" />}>
        <div>
          {data.name}
          <div>
            <Label htmlFor="fileAttachment">Label</Label>
            <LabelFormatter value={data.type} id="fileAttachment" />
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body />
    </Accordion>
  );
};

export default FileAttachmentsMobile;
