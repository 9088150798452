import clsx from "clsx";
import React from "react";
import Icon from "../../../components/icon/Icon";
import { useTheme } from "../../../hooks";
import { Typography } from "../../../layout";
import { InputThemeType, PlaceholderThemeType } from "../../../types";
import { BaseInputProps, InputType, ReadOnlyFormatterProps } from "../../../types/InputTypes";
import RequiredInput from "../../required/RequiredInput";
import { ValidationMessageType } from "../../validation";
import ValidationMessages from "../../validation/ValidationMessages";
import Label from "../label/Label";
import { InputAdorner, InputGroup, InputStyled, LabelContainer } from "./InputStyles";

export type InputProps = BaseInputProps & {
  /** type of the input */
  type?: InputType;
  /** Function called when changed */
  onChange: (value: any) => void;
  /** Value of the input */
  value?: number | string;
  /** If the input failed required checks */
  failedRequired?: boolean;
  /** validation messages */
  validationMessages?: ValidationMessageType[];
  /** end adorner for the input field */
  endAdorner?: React.ReactNode;
  /** start adorner for the input field */
  startAdorner?: React.ReactNode;
  /** step for a number input */
  step?: number;
  /** min for number input */
  min?: number;
  /** max for number input */
  max?: number;
  /** styles to override the specific input */
  styles?: {
    placeholder?: PlaceholderThemeType;
    input?: InputThemeType;
  };
};

export const ReadOnlyFormatter: React.FC<ReadOnlyFormatterProps> = ({ value, id }) => (
  <Typography id={id} variant="div">
    {value}
  </Typography>
);

const InputField: React.FC<InputProps> = ({
  id,
  type = "text",
  onChange,
  value,
  label,
  className,
  clearable = false,
  required = false,
  placeholder = "",
  disabled = false,
  missed = false,
  styles = {},
  failedRequired = false,
  endAdorner,
  startAdorner,
  gutterBottom = false,
  onBlur,
  onFocus,
  ...rest
}) => {
  const { Theme } = useTheme();
  const StylesOverride = {
    input: { ...Theme.input, ...styles.input },
    placeholder: { ...Theme.placeholder, ...styles.placeholder },
  };
  if (!required && className?.includes("is-invalid")) {
    className = className?.replace("is-invalid", "");
  }
  const hasStartAdorner = !!startAdorner;
  const hasEndAdorner: boolean = !!endAdorner || !!clearable;
  return (
    <InputGroup
      failedRequired={failedRequired}
      missed={missed}
      styles={StylesOverride}
      classes={clsx(className, { "mb-2": gutterBottom })}
      disabled={disabled}
    >
      {!!startAdorner && <InputAdorner>{startAdorner}</InputAdorner>}
      <InputStyled
        id={id}
        type={type}
        className={clsx("form-control bcr-text-input", className)}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        placeholder={placeholder}
        clearable={clearable}
        styles={StylesOverride}
        missed={missed}
        failedRequired={failedRequired}
        disabled={disabled}
        autocomplete="off"
        hasEndAdorner={hasEndAdorner}
        hasStartAdorner={hasStartAdorner}
        onBlur={onBlur}
        onFocus={onFocus}
        {...rest}
      />
      {hasEndAdorner && (
        <InputAdorner>
          {!!clearable ? <Icon iconName="fa-times-circle" onClick={() => onChange("")} /> : endAdorner}
        </InputAdorner>
      )}
    </InputGroup>
  );
};

const Input: React.FC<InputProps> = ({
  id,
  type = "text",
  onChange,
  value,
  label,
  readOnly = false,
  clearable = false,
  required = false,
  placeholder = "",
  disabled = false,
  missed = false,
  styles = {},
  readOnlyFormatter = ReadOnlyFormatter,
  validationMessages,
  failedRequired = true,
  showNA = false,
  className = "",
  min,
  max,
  ...rest
}) => {
  const ReadOnlyFormatterWrap = readOnlyFormatter;
  const { Theme } = useTheme();

  const toggleNA = (value: boolean) => {
    if (value) {
      onChange("N/A");
    } else {
      onChange("");
    }
  };

  const localOnChange = (value?: string) => {
    if (type === "number") {
      const numberValue = Number(value);
      const hasMax: boolean = max !== undefined;
      const hasMin: boolean = min !== undefined;
      if (!!value) {
        if (hasMax && hasMin) {
          if (numberValue >= min! && numberValue <= max!) {
            onChange(value);
          }
        } else if (hasMax) {
          if (numberValue <= max!) {
            onChange(value);
          }
        } else if (hasMin) {
          if (numberValue >= min!) {
            onChange(value);
          }
        } else {
          onChange(value);
        }
      } else {
        onChange(value);
      }
    } else {
      onChange(value);
    }
  };

  const isNA: boolean = showNA && value === "N/A";

  return (
    <>
      {!!label && (
        <LabelContainer styles={Theme} hasValidation={!!validationMessages?.length}>
          <Label
            htmlFor={id}
            required={required && !readOnly}
            missed={missed}
            complete={required && !failedRequired}
            showNA={showNA && !readOnly}
            isNA={isNA}
            onChange={toggleNA}
          >
            {label}
          </Label>
          <ValidationMessages validationMessages={validationMessages} />
        </LabelContainer>
      )}
      {readOnly ? (
        //@ts-ignore
        <ReadOnlyFormatterWrap id={id} value={value} />
      ) : (
        <RequiredInput required={required} isNA={isNA}>
          <InputField
            id={id}
            type={type}
            onChange={localOnChange}
            value={!isNA ? value : ""}
            label={label}
            clearable={clearable}
            placeholder={placeholder}
            styles={styles}
            missed={missed}
            disabled={disabled || isNA}
            className={clsx(className, { "is-na": isNA })}
            min={min}
            max={max}
            {...rest}
          />
        </RequiredInput>
      )}
    </>
  );
};

export default Input;
