import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useEffect,
  useState,
} from "react";
import { Popover, PopoverAlign, PopoverPosition } from "react-tiny-popover";

export type TriggerTypes = "hover" | "click";

export const Triggers = {
  HOVER: "hover" as TriggerTypes,
  CLICK: "click" as TriggerTypes,
};

export const Locations = {
  TOP: "top" as PopoverPosition,
  BOTTOM: "bottom" as PopoverPosition,
  LEFT: "left" as PopoverPosition,
  RIGHT: "right" as PopoverPosition,
};

export interface GenericPopoverProps {
  children: React.ReactNode;
  trigger?: TriggerTypes;
  location?: PopoverPosition;
  align?: PopoverAlign;
  delay?: number;
  fadeIn?: boolean;
  disabled?: boolean;
}
export type PopoverRef = HTMLElement;

type GenericPopoverType = ForwardRefExoticComponent<PropsWithoutRef<GenericPopoverProps> & RefAttributes<PopoverRef>>;

const GenericPopover: GenericPopoverType = forwardRef<PopoverRef, GenericPopoverProps>(
  (
    { children, delay = 0, trigger = "hover", location = "top", align = "center", fadeIn = true, disabled = false },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [timeoutRef, setTimeoutRef] = useState<ReturnType<typeof setTimeout> | null>(null);

    const openPopover = () => {
      if (!disabled) {
        const tRef = setTimeout(() => {
          setOpen(true);
          setTimeoutRef(null);
        }, delay);
        setTimeoutRef(tRef);
      }
    };

    const closePopover = () => {
      if (!!timeoutRef) {
        clearTimeout(timeoutRef);
      }
      setTimeout(() => {
        setOpen(false);
      }, delay);
    };

    useEffect(() => {
      if (ref) {
        if (trigger === Triggers.HOVER) {
          //@ts-ignore
          ref.current.onmouseover = () => {
            openPopover();
          };
          //@ts-ignore
          ref.current.onmouseout = () => {
            closePopover();
          };
        }
        if (trigger === Triggers.CLICK) {
          //@ts-ignore
          ref.current.onclick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (open) {
              closePopover();
            } else {
              openPopover();
            }
          };
        }
      }
    }, [ref, trigger, open, timeoutRef]);

    return (
      <Popover
        isOpen={open}
        align={align}
        positions={[location]}
        content={<>{children}</>}
        onClickOutside={() => {
          if (trigger === Triggers.CLICK) {
            setOpen(false);
          }
        }}
        parentElement={document.body}
      >
        <div />
      </Popover>
    );
  },
);

export default GenericPopover;
