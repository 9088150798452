import React, { createContext, useContext, useState } from "react";
import { LoadingType } from "../../types/LoadingTypes";

type LoadingContextType = {
  loading: boolean;
  setLoading: (value: boolean) => void;
  type: LoadingType;
  setType: (type: LoadingType) => void;
  animated: boolean;
  setAnimated: (value: boolean) => void;
  label: string;
  setLabel: (label: string) => void;
};

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<LoadingType>("player");
  const [animated, setAnimated] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("");

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        type,
        setType,
        animated,
        setAnimated,
        label,
        setLabel,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = (): LoadingContextType => {
  const loadingContext: LoadingContextType | undefined = useContext<LoadingContextType | undefined>(LoadingContext);
  if (loadingContext === undefined) {
    throw new Error(`useLoading must be used within a LoadingProvider`);
  }
  return loadingContext;
};

export { LoadingContext, LoadingProvider, useLoading };
