import clsx from "clsx";
import React, { useState } from "react";
import Cards, { Focused } from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FormColumn } from "../../../layout";
import { Input } from "../input";
import { Label } from "../label";

export type CreditCardTypeKeys = "name" | "number" | "exp" | "cvv";

export type CreditCardType = {
  name: string;
  number: string;
  exp: string;
  cvv: string;
};

export type CardPreviewType = {
  showPreview: boolean;
};

type CreditCardInputProps = {
  /** ID of the input */
  id: string;
  /** value of the credit card */
  value?: CreditCardType;
  /** change to the credit card */
  onChange: (value: CreditCardType) => void;
  /** preview type */
  preview: CardPreviewType;
  /** label */
  label?: React.ReactNode;
  /** if the input is required */
  required?: boolean;
  /** className to pass to component */
  className?: string;
  /** if the input is disabled */
  disabled?: boolean;
};

const CreditCard: React.FC<CreditCardInputProps> = ({
  id,
  value = { name: "", number: "", exp: "", cvv: "" },
  onChange,
  preview = { showPreview: true },
  label = "Credit Card",
  required = false,
  className = "",
  disabled = false,
}) => {
  const [focus, setFocus] = useState<Focused | undefined>();
  const changeValue = (key: CreditCardTypeKeys, changeValue: string) => {
    if (key === "exp" && changeValue.length === 2 && value.exp.length === 1) {
      onChange({ ...value, [key]: changeValue + "/" });
    } else {
      onChange({ ...value, [key]: changeValue });
    }
  };

  return (
    <div className={clsx("d-flex", className)}>
      {preview.showPreview && (
        <div style={{ float: "left", marginTop: 18 }}>
          <Cards cvc={value.cvv} expiry={value.exp} name={value.name} focused={focus} number={value.number} />
        </div>
      )}
      <div className={clsx({ "ms-3": preview.showPreview })}>
        {!!label && (
          <Label htmlFor={id} required={required && !disabled}>
            {label}
          </Label>
        )}
        <div id={id} className="row">
          <FormColumn width={1}>
            <Input
              id={`${id}-card-name`}
              value={value.name}
              onChange={(value) => changeValue("name", value)}
              placeholder="Name on card"
              label="Name on card"
              required={required}
              onFocus={() => setFocus("name")}
              onBlur={() => setFocus(undefined)}
              disabled={disabled}
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={1}>
            <Input
              id={`${id}-card-number`}
              value={value.number}
              onChange={(value) => changeValue("number", value)}
              placeholder="Card Number"
              label="Card Number"
              required={required}
              onFocus={() => setFocus("number")}
              onBlur={() => setFocus(undefined)}
              disabled={disabled}
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <Input
              id={`${id}-card-exp`}
              value={value.exp}
              onChange={(value) => changeValue("exp", value)}
              placeholder="MM/YY"
              label="Card Exp"
              onFocus={() => setFocus("expiry")}
              onBlur={() => setFocus(undefined)}
              required={required}
              disabled={disabled}
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <Input
              id={`${id}-card-cvv`}
              value={value.cvv}
              onChange={(value) => changeValue("cvv", value)}
              placeholder="CVV"
              label="CVV"
              required={required}
              onFocus={() => setFocus("cvc")}
              onBlur={() => setFocus(undefined)}
              disabled={disabled}
              gutterBottom
            />
          </FormColumn>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
