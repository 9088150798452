import React from "react";
import { useTheme } from "../../../hooks";
import Button, { ButtonProps, InButtonOverrideStyles } from "../Button";
import { FabContainer, FabDefaultStyles, FabStyles } from "./FabStyles";

export type ButtonStylesOverrides = {
  bottom?: number;
  right?: number;
};

type FabProps = ButtonProps & {
  fabStyles?: ButtonStylesOverrides;
};

const Fab: React.FC<FabProps> = ({ children, fabStyles = {}, variant = "default", ...rest }) => {
  const { Theme } = useTheme();

  const StylesOverrides: FabStyles = { ...FabDefaultStyles, ...fabStyles };
  const fabButtonStyles: InButtonOverrideStyles = {
    button: {
      ...Theme.button[variant],
      padding: "10px",
      "border-radius": "20px",
      "line-height": "1",
    },
  };
  return (
    <FabContainer styles={StylesOverrides}>
      <Button {...rest} variant={variant} styles={fabButtonStyles}>
        {children}
      </Button>
    </FabContainer>
  );
};

export default Fab;
