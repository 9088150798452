import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../hooks";
import { TextareaThemeType } from "../../../types";
import { BaseInputProps } from "../../../types/InputTypes";
import RequiredInput from "../../required/RequiredInput";
import { ReadOnlyFormatter } from "../input";
import Label from "../label/Label";
import { TextareaStyled } from "./TextareaStyles";

export type TextAreaProps = BaseInputProps & {
  value?: string;
  onChange: (value?: string) => void;
  rows?: number;
  maxLength?: number;
  resizable?: boolean;
  styles?: TextareaThemeType;
};

const TextArea: React.FC<TextAreaProps> = ({
  id,
  value = "",
  onChange,
  disabled = false,
  required = false,
  missed = false,
  label,
  className = "",
  placeholder = "",
  maxLength,
  onBlur,
  readOnly = false,
  resizable = false,
  rows = 4,
  gutterBottom = false,
  showNA = false,
  readOnlyFormatter,
  styles = {
    input: {},
    placeholder: {},
    scrollBar: {},
  },
}) => {
  const { Theme } = useTheme();
  const StylesOverride: TextareaThemeType = {
    input: { ...Theme.textarea.input, ...styles.input },
    placeholder: { ...Theme.textarea.placeholder, ...styles.placeholder },
  };
  const ReadOnly = readOnlyFormatter || ReadOnlyFormatter;

  const toggleNA = (value: boolean) => {
    if (value) {
      onChange("N/A");
    } else {
      onChange(undefined);
    }
  };

  const isNA: boolean = showNA && value === "N/A";

  return (
    <>
      <Label
        htmlFor={id}
        required={required && !readOnly}
        missed={missed}
        showNA={showNA && !readOnly}
        isNA={isNA}
        onChange={toggleNA}
      >
        {label}
      </Label>
      {readOnly ? (
        //@ts-ignore
        <ReadOnly id={id} value={value} />
      ) : (
        <RequiredInput required={required && !readOnly} isNA={isNA}>
          <TextareaStyled
            id={id}
            value={isNA ? "" : value}
            className={clsx("form-control bcr-textarea-input", className, { "mb-2": gutterBottom })}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled || isNA}
            required={required}
            missed={missed}
            placeholder={placeholder}
            readOnly={readOnly}
            rows={rows}
            onBlur={onBlur}
            maxLength={maxLength}
            resizeable={resizable}
            styles={StylesOverride}
          />
        </RequiredInput>
      )}
    </>
  );
};

export default TextArea;
