import React from "react";
import { useTheme } from "../../../hooks";
import { CardThemeType } from "../../../types/ThemeType";
import { CardHeaderDivStyled } from "./CardStyles";

export type CardHeaderProps = {
  displayName?: string;
  styles?: CardThemeType;
};

const CardHeader: React.FC<CardHeaderProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: CardThemeType = { ...Theme.card, ...styles };

  return (
    <CardHeaderDivStyled styles={StylesOverride} {...rest}>
      {children}
    </CardHeaderDivStyled>
  );
};

export default CardHeader;
