import clsx from "clsx";
import React from "react";
import { CheckboxThemeType } from "../../../types";
import { BaseInputProps } from "../../../types/InputTypes";
import { areObjectsEqual } from "../../../utils";
import { Label } from "../label";
import { ValueOpt } from "../select";
import Checkbox from "./Checkbox";

type CheckboxGroupProps<T> = BaseInputProps & {
  /** on change value */
  onChange: (values: T[]) => void;
  /** options to display */
  options: ValueOpt<T>[];
  /** selected values */
  values?: T[];
  /** overrideable styles */
  styles?: CheckboxThemeType;
};

const CheckboxGroup = <T,>(props: CheckboxGroupProps<T>) => {
  const {
    id,
    label,
    required,
    onChange,
    values = [],
    options,
    disabled = false,
    readOnly = false,
    gutterBottom = false,
    className = "",
    styles,
  } = props;

  const isChecked = (option: ValueOpt<T>): boolean => {
    return !!values.filter((val: T) => areObjectsEqual(val, option.value)).length;
  };

  const changeValue = (index: number, checked: boolean) => {
    let newValues: T[] = [...values];
    const selectedOption: ValueOpt<T> = options[index];
    if (checked) {
      newValues.push(selectedOption.value);
    } else {
      newValues = newValues.filter((value: T) => !areObjectsEqual(value, selectedOption.value));
    }
    onChange(newValues);
  };

  return (
    <div className={clsx(className, { "mb-2": gutterBottom })}>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      {readOnly ? (
        <div className="d-flex">
          {options
            ?.filter((option) => isChecked(option))
            .map((value: ValueOpt<T>, index: number) => (
              <div key={`read-only-checkbox-${index}`} className="me-2">
                {value.label}
              </div>
            ))}
        </div>
      ) : (
        <div className="d-flex">
          {options.map((option: ValueOpt<T>, index: number) => (
            <Checkbox
              key={`checkbox-${index}`}
              className={clsx(className, "me-2")}
              id={`checkbox-element-${index}-${id}`}
              label={option.label}
              disabled={option.disabled || disabled}
              checked={isChecked(option)}
              readOnly={readOnly}
              styles={styles}
              onChange={(value: boolean) => {
                changeValue(index, value);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckboxGroup;
