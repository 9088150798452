import React, { useEffect, useState } from "react";
import { TabSwitcherThemeType } from "types";
import { Tab } from "types/TabSwitcherTypes";
import { useTheme } from "../../../hooks";
import {
  ComponentContainerStyles,
  IndicatorStyles,
  TabContainerStyles,
  TabStyles,
  TabSwitcherContainerStyles,
} from "./TabSwitcherStyles";

type TabSwitcherProps = {
  /** set the active tab */
  activeTabIndex?: number;
  /** function to determine if tab can be switched to */
  canChange: () => boolean;
  /** Overrideable styles */
  styles?: TabSwitcherThemeType;
  /** array of Tab objects with the following properties: active, dirty, name, component */
  tabs: Tab[];
};

const TabSwitcher: React.FC<TabSwitcherProps> = ({ activeTabIndex = 0, canChange, styles, tabs }) => {
  const { Theme } = useTheme();
  const styleOverrides: TabSwitcherThemeType = {
    container: { ...Theme.tabSwitcher.container, ...styles?.container },
    tab: { ...Theme.tabSwitcher.tab, ...styles?.tab },
    indicator: { ...Theme.tabSwitcher.indicator, ...styles?.indicator },
    componentContainer: { ...Theme.tabSwitcher.componentContainer, ...styles?.componentContainer },
  };

  const [tabsState, setTabsState] = useState<Tab[]>(tabs);
  const activeTab = tabsState.find((t) => t.active);

  const containsNewTabs = (list1: Tab[], list2: Tab[]) => {
    if (list1.length !== list2.length) {
      return true;
    }
    for (let i = 0; i < list1.length; i++) {
      if (list1[i].name !== list2[i].name) {
        return true;
      }
    }
    return false;
  };

  const updateTabs = (tabs: Tab[]) => {
    tabs = tabs ? tabs.slice().map((t) => ({ ...t, active: false, dirty: false })) : [];
    if (tabs.length > 0) {
      if (activeTabIndex) {
        tabs[activeTabIndex].active = true;
      } else {
        tabs[0].active = true;
      }
    }
    return tabs;
  };

  const setActiveTab = (index: number) => {
    const copyTabs = tabsState.slice();
    copyTabs.forEach((c) => (c.active = false));
    copyTabs[index].active = true;
    setTabsState(copyTabs);
  };

  const renderContent = (active: Tab | undefined) => {
    if (active) {
      if (typeof active.component === typeof {}) {
        return active.component;
      } else {
        const RenderComponent = active.component;
        //@ts-ignore
        return <RenderComponent />;
      }
    }
    return <div>No Component Found</div>;
  };

  useEffect(() => {
    tabs = updateTabs(tabs);
  }, []);

  useEffect(() => {
    if (containsNewTabs(tabs, tabsState)) {
      tabs = updateTabs(tabs);
      setTabsState(tabs);
    }
  }, [tabs]);

  useEffect(() => {
    if (activeTabIndex !== null) {
      setActiveTab(activeTabIndex);
    }
  }, [activeTabIndex]);

  return (
    <TabSwitcherContainerStyles styles={styleOverrides}>
      <TabContainerStyles styles={styleOverrides}>
        {tabsState.map((t, index) => (
          <TabStyles
            active={t.active}
            onClick={() => {
              if (canChange()) {
                setActiveTab(index);
              }
            }}
            key={`tab-${index}`}
            styles={styleOverrides}
          >
            {t.name}{" "}
            <IndicatorStyles dirty={t.dirty} styles={styleOverrides}>
              *
            </IndicatorStyles>
          </TabStyles>
        ))}
      </TabContainerStyles>
      <ComponentContainerStyles styles={styleOverrides}>{renderContent(activeTab)}</ComponentContainerStyles>
    </TabSwitcherContainerStyles>
  );
};

export default TabSwitcher;
