import React, { ReactElement, useState } from "react";
import Icon from "../../../components/icon/Icon";
import DesktopTabletView from "../../../components/view/DesktopTabletView";
import MobileView from "../../../components/view/MobileView";
import { useTheme } from "../../../hooks";
import { StickyFooterThemeType } from "../../../types";
import { ButtonProps } from "../index";
import {
  StickyFooterCollapse,
  StickyFooterCollapseData,
  StickyFooterData,
  StickyFooterDesktopHolder,
  StickyFooterIconHolder,
  StickyFooterMobileHolder,
} from "./StickyFooterButtonStyles";

type StickyFooterButtonProps = {
  justifyContent?: "end" | "start";
  startsOpen?: boolean;
  styles?: StickyFooterThemeType;
  children?: ReactElement<ButtonProps> | Array<ReactElement<ButtonProps>>;
};

const StickyFooterButtons: React.FC<StickyFooterButtonProps> = ({
  children,
  justifyContent = "end",
  startsOpen = false,
  styles = {},
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(startsOpen);
  const { Theme } = useTheme();
  const CompStyles: StickyFooterThemeType = { ...Theme.stickyFooter, ...styles };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id="sticky-footer-buttons">
      <MobileView>
        {!!children && (
          <StickyFooterMobileHolder styles={CompStyles}>
            <StickyFooterIconHolder>
              <StickyFooterCollapse onClick={toggleOpen} aria-expanded={startsOpen}>
                {isOpen ? (
                  <Icon iconName="fa-chevron-down" className="fa-lg" styles={{ color: CompStyles.color }} />
                ) : (
                  <Icon iconName="fa-chevron-up" className="fa-lg" styles={{ color: CompStyles.color }} />
                )}
              </StickyFooterCollapse>
            </StickyFooterIconHolder>
            <StickyFooterCollapseData className={`collapse ${startsOpen ? "show" : ""}`}>
              <StickyFooterData className="card card-body" styles={CompStyles}>
                {React.Children.map(children, (child: React.ReactElement<ButtonProps>) => {
                  return !!child
                    ? React.cloneElement(child, {
                        className: (child?.props?.className || "") + " w-100",
                      })
                    : null;
                })}
              </StickyFooterData>
            </StickyFooterCollapseData>
          </StickyFooterMobileHolder>
        )}
      </MobileView>
      <DesktopTabletView>
        <StickyFooterDesktopHolder className={`d-flex justify-content-${justifyContent}`}>
          {children}
        </StickyFooterDesktopHolder>
      </DesktopTabletView>
    </div>
  );
};

export default StickyFooterButtons;
