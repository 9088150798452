import React, { useRef } from "react";
import { useTheme } from "../../../hooks";
import { BuildType, NavigationValueThemeType } from "../../../types";
import Tooltip from "../../popover/tooltip/Tooltip";
import { NavigationLogoHolder, NavigationLogoText, NavigationNavLogo } from "./NavigationElementStyles";

type NavigationLeftLogoProps = {
  logo?: any;
  logoText?: string;
  builds: BuildType[];
  styles: NavigationValueThemeType;
};

const NavigationLeftLogo: React.FC<NavigationLeftLogoProps> = ({
  logo,
  logoText = "",
  builds = [],
  children,
  styles,
}) => {
  const ref = useRef(null);
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation.horizontal, ...styles };

  return (
    <>
      <div id="TooltipTarget" ref={ref}>
        {!!logo ? (
          <NavigationNavLogo src={logo} styles={StylesOverride} />
        ) : !!logoText?.length ? (
          <NavigationLogoHolder styles={StylesOverride}>
            <NavigationLogoText styles={StylesOverride}>{logoText}</NavigationLogoText>
          </NavigationLogoHolder>
        ) : null}
        {children}
      </div>
      {!!builds.length && (
        <Tooltip location="bottom" delay={1000} align="start" ref={ref}>
          <div className="p-1">
            <div>Versions</div>
            <div>
              {builds.map((b: BuildType) => (
                <div key={b.name}>
                  {b.name}: {b.build}
                </div>
              ))}
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default NavigationLeftLogo;
