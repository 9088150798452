import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { CollapseItemThemeType } from "../../types";
import AccordionIcon from "./AccordionIcon";
import { ChildrenWrapper, Header } from "./AccordionStyles";

export type AccordionHeaderProps = {
  /** if the accordion header is open */
  isOpen?: boolean;
  /** if the icon on the left or right */
  iconLeft?: boolean;
  /** custom accordion button */
  customActionButton?: React.ReactNode;
  /** overrideable styles */
  styles?: CollapseItemThemeType;
};

/**
 * Accordion Header to always display
 */
const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  children,
  isOpen = false,
  iconLeft = false,
  customActionButton,
  styles = {},
}) => {
  const hasCustomActionButton = !!customActionButton;
  const { Theme } = useTheme();
  const StylesOverride: CollapseItemThemeType = { ...Theme.accordion.header, ...styles };

  return (
    <Header styles={StylesOverride}>
      {iconLeft && <AccordionIcon isOpen={isOpen} />}
      <ChildrenWrapper styles={StylesOverride}>{children}</ChildrenWrapper>
      {hasCustomActionButton && customActionButton}
      {!iconLeft && <AccordionIcon isOpen={isOpen} />}
    </Header>
  );
};

export default AccordionHeader;
