import styled from "styled-components";
import { SubSectionThemeType } from "../../../types";

type SubSectionStylesType = {
  styles: SubSectionThemeType;
};

export const SubSectionHeader = styled.div.attrs(() => ({
  className: "bcr-subsection-header",
}))<SubSectionStylesType>`
  background: ${(props) => props.styles.background};
  border: ${(props) => props.styles.border};
  border-bottom: ${(props) => props.styles.borderBottom};
  color: ${(props) => props.styles.color};
  padding: ${(props) => props.styles.padding};
  font-size: 0.875rem;
  font-weight: 300;
`;

export const SubSectionBody = styled.div.attrs(() => ({
  className: "bcr-subsection-body",
}))`
  padding: 0.5rem;
`;
