import React from "react";
import ReactDOM from "react-dom";
import { useTheme } from "../../../hooks";
import { ModalThemeType } from "../../../types";
import ModalBody, { ModalBodyProps } from "../genericModal/body/ModalBody";
import ModalFooter, { ModalFooterProps } from "../genericModal/footer/ModalFooter";
import GenericModal from "../genericModal/GenericModal";
import ModalHeader, { ModalHeaderProps } from "../genericModal/header/ModalHeader";
import { Backdrop, ModalDialog, ModalSizes } from "./ModalStyles";

type ModalProps = {
  show: boolean;
  children: React.ReactElement[];
  portal?: Element;
  size?: ModalSizes;
  styles?: ModalThemeType;
};

export interface ModalType extends React.FC<ModalProps> {
  Header: React.FC<ModalHeaderProps>;
  Body: React.FC<ModalBodyProps>;
  Footer: React.FC<ModalFooterProps>;
}

const Modal: ModalType = ({ show, children, portal = document.body, size = "md", styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.modal, ...styles };

  return show
    ? ReactDOM.createPortal(
        <Backdrop styles={StylesOverride}>
          <ModalDialog size={size}>
            <GenericModal styles={StylesOverride}>{children}</GenericModal>
          </ModalDialog>
        </Backdrop>,
        portal,
      )
    : null;
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
