import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { AlertThemeType, AlertType } from "../../types";
import Alert from "./Alert";
import { Message, MessageWrapper } from "./AlertStyles";

type AlertHolderProps = {
  alerts: AlertType[];
  onRemove: (id: number | string) => void;
  fullWidth?: boolean;
  className?: string;
  styles?: AlertThemeType;
};

const AlertHolder: React.FC<AlertHolderProps> = ({
  alerts,
  onRemove,
  fullWidth = false,
  className = "",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: AlertThemeType = { ...Theme.alert, ...styles };

  return (
    <div className={clsx("best-alert-holder", className)}>
      {!!alerts?.length && (
        <MessageWrapper>
          <Message style={{ width: fullWidth ? "95%" : "" }}>
            {alerts.map((alert: AlertType, index: number) => (
              <Alert key={`alert-${index}`} type={alert.type} id={alert.id} onRemove={onRemove} styles={StylesOverride}>
                {alert.text}
              </Alert>
            ))}
          </Message>
        </MessageWrapper>
      )}
    </div>
  );
};

export default AlertHolder;
