import React from "react";
import { useTheme } from "../../../hooks";
import { CardThemeType } from "../../../types";
import { CarBodyStyled } from "./CardStyles";

export type CardBodyProps = {
  displayName?: string;
  styles?: CardThemeType;
};

const CardBody: React.FC<CardBodyProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: CardThemeType = { ...Theme.card, ...styles };

  return (
    <CarBodyStyled styles={StylesOverride} {...rest}>
      {children}
    </CarBodyStyled>
  );
};

export default CardBody;
