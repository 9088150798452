import React from "react";
import { ClipLoader } from "react-spinners";
import { CalendarEvent, CalendarThemeType } from "../../types";
import CalendarDay from "./CalendarDay";
import { CalendarContainer, CalendarDayOfWeekContainer } from "./CalendarStyles";
import { getDay, getEvent } from "./CalendarUtil";

type CalendarGridViewProps<T> = {
  events: CalendarEvent<T>[];
  loading?: boolean;
  daysToRender: number[];
  startDayOfWeek: number;
  daysInMonth: number;
  year: number;
  month: number;
  styles: CalendarThemeType;
};

const CalendarGridView = <T,>(props: CalendarGridViewProps<T>) => {
  const { loading = false, events, daysToRender, startDayOfWeek, daysInMonth, year, month, styles } = props;
  return (
    <CalendarContainer styles={styles}>
      {loading ? (
        <div className="align-self-center ms-auto me-auto">
          <ClipLoader />
        </div>
      ) : (
        <>
          {daysToRender.map((i, index) => {
            if (index < 7) {
              return <CalendarDayOfWeekContainer styles={styles}>{getDay(index)}</CalendarDayOfWeekContainer>;
            } else {
              const calculatedDay: number = index - 6 - startDayOfWeek;
              const dayOfMonth: number | undefined =
                calculatedDay > 0 && calculatedDay <= daysInMonth ? calculatedDay : undefined;
              const event: CalendarEvent<T> | undefined = getEvent(events, dayOfMonth);
              return <CalendarDay dayOfMonth={dayOfMonth} year={year} month={month} event={event} styles={styles} />;
            }
          })}
        </>
      )}
    </CalendarContainer>
  );
};

export default CalendarGridView;
