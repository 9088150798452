import styled from "styled-components";
import { DatatableThemeType } from "../../../types";

type DatatableStylesType = {
  styles: DatatableThemeType;
};

//Expand Button
export const ExpansionButtonHolder = styled.div`
  min-width: 30px;
  &:hover {
    cursor: pointer;
  }
`;

//Subrow Button
export const SubRowButtonHolder = styled.div.attrs(() => ({}))`
  min-width: 31px;
  &:hover {
    cursor: pointer;
  }
`;

export const ActionsHeader = styled.div.attrs(() => ({}))`
  min-width: 31px;
`;

// More actions button
export const MoreActionsButtonStyles = styled.div`
  min-width: 31px;
  &:hover {
    cursor: pointer;
  }
`;

// Styled action for the more actions popover
export const MoreActionsPopoverAction = styled.div<DatatableStylesType>`
  display: flex;
  color: ${(props) => props.styles.moreActions.popoverAction.color};
  font-size: ${(props) => props.styles.moreActions.popoverAction.fontSize};
  &:hover {
    background-color: ${(props) => props.styles.moreActions.popoverAction.hoverBackgroundColor};
    cursor: pointer;
  }
  padding: ${(props) => props.styles.moreActions.popoverAction.padding};
`;

//Header Cell
type HeaderCellProps = {
  styles: DatatableThemeType;
  elemWidth: number;
  elemHeight: number;
  sortable: boolean;
  background?: string;
};

type HeaderTextProps = {
  addRow: boolean;
};

export const HeaderCellDiv = styled.div<HeaderCellProps>`
  &&& {
    height: ${(props) => props.elemHeight}px;
    width: ${(props) => props.elemWidth}px;
    font-weight: ${(props) => props.styles.header.fontWeight};
    font-size: 0.75rem;
    color: ${(props) => props.styles.header.color};
    background: ${(props) => (!!props.background?.length ? props.background : props.styles.header.background)};
    ${(props) => props.sortable && "cursor: pointer;"}
  }
`;

export const HeaderText = styled.div<HeaderTextProps>`
  width: 100%;
  display: flex;
  align-items: end;
  ${(props) => props.addRow && "display: flex"};
  ${(props) => props.addRow && "justify-content: space-between"};
`;

export const HeaderAddRowText = styled.div<DatatableStylesType>`
  color: ${(props) => props.styles.moreActions.addRowText.color};
  cursor: pointer;
`;

//Header
type HeaderStylesProps = {
  height: number;
  minWidth: number;
  styles: DatatableThemeType;
};

export const HeaderDiv = styled.div.attrs(() => ({
  className: "d-flex align-items-end px-2 bcr-table-header",
}))<HeaderStylesProps>`
  height: ${(props) => props.height}px;
  width: 100%;
  min-width: ${(props) => props.minWidth}px;
  border-bottom: 1px solid ${(props) => props.styles.header.borderBottomColor};
  background: ${(props) => props.styles.header.background};
`;

export const HeaderCheckboxHolder = styled.div.attrs(() => ({
  className: "bcr-table-header-cell me-3",
}))`
  height: 100%;
`;

//Row
type RowStylesProps = {
  height?: number;
  width?: number;
  expanded?: boolean;
  sorted?: boolean;
  selected?: boolean;
  disabled?: boolean;
  isClickable?: boolean;
  styles: DatatableThemeType;
};

export const RowDiv = styled.div.attrs(() => ({
  className: "d-flex align-items-center px-2 bcr-table-row",
}))<RowStylesProps>`
  height: ${(props) => props.height}px;
  background: ${(props) =>
    props.disabled
      ? props.styles.row.disabledBackground
      : props.selected
      ? props.styles.row.selectedBackground
      : props.styles.row.background};
  border: ${(props) => (props.styles.row.fullBorder ? props.styles.row.fullBorder : null)};
  border-bottom: ${(props) => (props.styles.row.fullBorder ? null : "1px solid " + props.styles.row.separatorColor)};
  ${(props) => props.expanded && "box-shadow: 0px 20px 15px -15px silver"};
  position: relative;
  z-index: 1112;
  &:hover {
    ${(props) =>
      props.isClickable
        ? props.onClick && !props.disabled && "cursor: pointer"
        : undefined === props.isClickable
        ? ""
        : "cursor: not-allowed"};
    ${(props) => props.onClick && !props.disabled && `background: ${props.styles.row.selectedBackground}`};
  }
`;

export const BodyCell = styled.div<RowStylesProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  ${(props) => (!!props.sorted ? `background: ${props.styles.column.sortedBackground}` : "")};
`;

export const RowExpansionHolder = styled.div<RowStylesProps>`
  border-bottom: 1px solid ${(props) => props.styles.row.separatorColor};
  background: ${(props) => props.styles.row.expandedBackground};
  position: relative;
  z-index: 1111;
`;

export const RowCheckboxHolder = styled.div.attrs(() => ({
  className: "me-3 bcr-table-body-cell",
}))`
  min-width: 1.0625rem;
  height: 50%;
`;

//Datatable
type DatatableStylesProps = {
  height?: number;
  styles: DatatableThemeType;
};

export const TableStyled = styled.div.attrs(() => ({
  className: "bcr-table",
}))<DatatableStylesProps>`
  *:focus {
    outline: none;
  }

  position: relative;
  border: ${(props) => props.styles.datatable.border};
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 1rem;
  }
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: ${(props) => props.styles.scrollBar.background};
  }
  scrollbar-background: ${(props) => props.styles.scrollBar.background};

  &::-webkit-scrollbar-thumb {
    border: 3px solid ${(props) => props.styles.scrollBar.element.borderColor};
    border-radius: 100px;
    background-color: ${(props) => props.styles.scrollBar.element.color};
    background-clip: content-box;
  }
  scrollbar-color: ${(props) => props.styles.scrollBar.element.borderColor};
`;

export const BodyStyled = styled.div.attrs(() => ({
  className: "bcr-table-body",
}))<DatatableStylesProps>`
  overflow: visible;
  height: ${(props) => props.height}px;

  .ReactVirtualized__Grid {
    &::-webkit-scrollbar {
      width: 1rem;
    }
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
      background: ${(props) => props.styles.scrollBar.background};
    }
    scrollbar-background: ${(props) => props.styles.scrollBar.background};

    &::-webkit-scrollbar-thumb {
      border: 3px solid ${(props) => props.styles.scrollBar.element.borderColor};
      border-radius: 100px;
      background-color: ${(props) => props.styles.scrollBar.element.color};
      background-clip: content-box;
    }
    scrollbar-color: ${(props) => props.styles.scrollBar.element.borderColor};
  }
`;

export const PageSizeHolder = styled.div.attrs(() => ({
  className: "ms-2",
}))`
  width: 7rem;
`;

export const PaginationContainer = styled.div<DatatableStylesProps>`
  &&& {
    .page-link {
      background: ${(props) => props.styles.pagination.background} !important;
      color: ${(props) => props.styles.pagination.color} !important;
    }
    .page-item.active .page-link {
      background: ${(props) => props.styles.pagination.active.background} !important;
      color: ${(props) => props.styles.pagination.active.color} !important;
    }
    .page-item.disabled .page-link {
      background: ${(props) => props.styles.pagination.disabled.background} !important;
      color: ${(props) => props.styles.pagination.disabled.color} !important;
    }
  }
`;

type LoadingProps = {
  height: number;
  width: number;
  styles: DatatableThemeType;
};

export const TableLoadingDiv = styled.div<LoadingProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(230, 230, 230, 0.5);
  border-left: ${(props) => (props.styles.row.fullBorder ? null : "1px solid " + props.styles.row.separatorColor)};
  border-bottom: ${(props) => (props.styles.row.fullBorder ? null : "1px solid " + props.styles.row.separatorColor)};
  border-right: ${(props) => (props.styles.row.fullBorder ? null : "1px solid " + props.styles.row.separatorColor)};
`;
