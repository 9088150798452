import React from "react";
import { useTheme } from "../../../../hooks/useTheme";
import { ModalFooterDiv, ModalFooterStyles } from "./ModalFooterStyles";

export type ModalFooterProps = {
  children: React.ReactNode;
  justifyContent?: string;
  styles?: ModalFooterStyles;
};

const ModalFooter: React.FC<ModalFooterProps> = ({ children, justifyContent = "end", styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.modal.footer, ...styles };
  return (
    <ModalFooterDiv styles={StylesOverride} justifyContent={justifyContent}>
      {children}
    </ModalFooterDiv>
  );
};

ModalFooter.displayName = "ModalFooter";

export default ModalFooter;
