import React from "react";
import { CalendarEvent, CalendarThemeType } from "../../types";
import CalendarRenderEvent from "./CalendarRenderEvent";
import { CalendarDayContainer, CalendarDayContent, CalendarDayOfMonth } from "./CalendarStyles";

type CalendarDayProps<T> = {
  dayOfMonth?: number;
  year: number;
  month: number;
  event?: CalendarEvent<T>;
  styles: CalendarThemeType;
};

const CalendarDay = <T,>(props: CalendarDayProps<T>) => {
  const { dayOfMonth, year, month, event, styles } = props;
  return (
    <CalendarDayContainer styles={styles}>
      <div className="d-flex flex-row w-100 h-100">
        <CalendarDayContent>
          <CalendarRenderEvent event={event} dayOfMonth={dayOfMonth} month={month} year={year} viewType="grid" />
        </CalendarDayContent>
        <CalendarDayOfMonth styles={styles}>{dayOfMonth}</CalendarDayOfMonth>
      </div>
    </CalendarDayContainer>
  );
};

export default CalendarDay;
