import { useMediaQuery } from "react-responsive";

type useBreakpointsType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopTablet: boolean;
  isTabletOrMobile: boolean;
  isPortrait: boolean;
  isRetina: boolean;
};

export const useBreakpoints = (): useBreakpointsType => {
  const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
  const isTablet: boolean = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop: boolean = useMediaQuery({ minWidth: 992 });
  const isDesktopTablet: boolean = useMediaQuery({ minWidth: 768 });
  const isTabletOrMobile: boolean = useMediaQuery({ maxWidth: 991 });
  const isPortrait: boolean = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina: boolean = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return { isMobile, isTablet, isDesktop, isDesktopTablet, isTabletOrMobile, isPortrait, isRetina };
};
