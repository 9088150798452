import React from "react";
import { EnviromentType } from "../../../types/EnviromentType";
import MobileView from "../../view/MobileView";
import EnvIndicator from "../env/EnvIndicator";
import { NavbarEnvHolder } from "./NavigationElementStyles";

type NavBarProps = {
  displayEnvIndicator: boolean;
  vertical: boolean;
  env: EnviromentType;
};

//@ts-ignore
const getComponent = (children, displayName?: string) => {
  //@ts-ignore
  return children.filter((comp) => {
    if (displayName) {
      return comp.props && comp.props.displayName === displayName;
    } else {
      return !comp.props || !comp.props.displayName;
    }
  });
};

const NavBar: React.FC<NavBarProps> = ({ children, displayEnvIndicator, vertical, env }) => {
  //todo: test
  const getComponent = (displayName: string) => {
    return React.Children.toArray(children).filter((comp) => {
      //@ts-ignore
      return displayName === comp?.props?.displayName;
    });
  };

  return (
    <div>
      {displayEnvIndicator ? (
        <MobileView>
          <NavbarEnvHolder>
            <EnvIndicator env={env} />
          </NavbarEnvHolder>
        </MobileView>
      ) : null}
      {getComponent("nav-bar-signout")}
      {vertical ? getComponent("navigation-vertical") : getComponent("navigation-horizontal")}
    </div>
  );
};

export default NavBar;
