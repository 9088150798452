import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import { useTheme } from "../../../hooks";
import GenericPopover, { GenericPopoverProps, PopoverRef } from "../generic/GenericPopover";
import { TooltipContainer, TooltipStyles } from "./TooltipStyles";

export interface TooltipProps extends GenericPopoverProps {
  styles?: TooltipStyles;
}

type TooltipType = ForwardRefExoticComponent<PropsWithoutRef<TooltipProps> & RefAttributes<PopoverRef>>;

const Tooltip: TooltipType = forwardRef<PopoverRef, TooltipProps>(({ children, styles = {}, ...rest }, ref) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.tooltip, ...styles };
  return (
    <GenericPopover ref={ref} {...rest}>
      <TooltipContainer styles={StylesOverride}>{children}</TooltipContainer>
    </GenericPopover>
  );
});

export default Tooltip;
