import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavigationValueThemeType } from "../../../types";
import DropdownItem from "../elements/DropdownItem";

type NavigationVerticalStyleProps = {
  styles: NavigationValueThemeType;
  loggedIn?: boolean;
};

//Navigation Vertical
export const NavBarHidden = styled.nav.attrs(() => ({
  className: `navbar fixed-top navbar-expand-xl navbar-light bg-faded align-items-start justify-content-start`,
  id: "best-navbar",
}))`
  &&& {
    padding: 0;
  }
`;

export const NavBarStyled = styled.nav.attrs(() => ({
  className: `navbar fixed-top navbar-expand-lg navbar-light bg-faded align-items-start justify-content-start`,
  id: "best-navbar-styled",
}))<NavigationVerticalStyleProps>`
  &&& {
    width: 12rem;
    height: 100vh;
    background-color: ${(props) => props.styles.background};
    z-index: 1050;
    margin-top: 5.2rem;
    padding: 0 0 5px 0;
    box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.1);
    border-top: 1px solid ${(props) => props.styles.borderColor};
    border-bottom: 1px solid ${(props) => props.styles.borderColor};
  }
`;

//Horizontal Bar For Vertical Nav
export const HorizontalNavHolder = styled.div`
  &&& {
    z-index: 1051;
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    height: 3.875rem;
    line-height: 3.875rem;

    @media (min-width: 768px) {
      top: 22px;
    }
    @media (max-width: 767px) {
      top: 0px;
    }
  }
`;

export const HorizontalBarNavContainer = styled.div`
  &&& {
    background: ${(props) => props.theme.navigation.vertical.topBar.background};
    width: 100vw;
    height: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const HorizontalBarLogoHolder = styled.div`
  &&& {
    z-index: 1052;
    float: left;
    background: ${(props) => props.theme.navigation.vertical.topBar.background};
  }
`;

export const HorizontalBarLogoLink = styled(Link)`
  &&& {
    padding: 0;
  }
`;

export const HorizontalBarTitle = styled.div<NavigationVerticalStyleProps>`
  &&& {
    @media (min-width: 768px) {
      font-size: 1.55rem;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-top: 5px;
    }
  }
  color: ${(props) => props.styles.topBar.color};
  font-weight: bold;
`;

export const HorizontalBarLogoPaddingStyle = styled.div`
  &&& {
    @media (min-width: 768px) {
      padding-right: 15px;
    }
    @media (max-width: 767px) {
      padding-right: 5px;
    }
  }
`;

export const HorizontalBarStyledChildren = styled.div`
  flex: 2;
  &&& {
    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
`;

export const NavIconHolder = styled.div.attrs(() => ({
  className: "d-flex align-items-center justify-content-center",
}))`
  @media (min-width: 768px) {
    height: 32px;
    width: 30px;
  }
  @media (max-width: 767px) {
    height: 37px;
    width: 68px;
  }
`;

//Navigation From Config

export const NavbarSignoutLink = styled(Link)`
  display: block;
  z-index: 1053 !important;
  padding: 0.25rem 1.5rem;
  &&& {
    text-decoration: none;
  }
`;

export const NavbarSignoutItem = styled(DropdownItem)<NavigationVerticalStyleProps>`
  z-index: 1053 !important;
  color: ${(props) => props.styles.usernameDropdown.color} !important;
  background: ${(props) => props.styles.usernameDropdown.background} !important;
  &:hover {
    color: ${(props) => props.styles.usernameDropdown.hover.color} !important;
    background: ${(props) => props.styles.usernameDropdown.hover.background} !important;
  }
`;

//Vertical Navigation Container
export const VerticalNavigationContainerStyled = styled.div.attrs(() => ({
  className: "container-fluid",
}))<NavigationVerticalStyleProps>`
  width: auto;
  @media (max-width: 767px) {
    margin-top: ${(props) => (props.loggedIn ? "0" : "10px")};
  }
  @media (min-width: 768px) {
    margin-top: ${(props) => (props.loggedIn ? "5.25rem" : "10px")};
    margin-left: ${(props) => (props.loggedIn ? "12rem" : "0")};
  }

  margin-bottom: 1.25rem;
`;
