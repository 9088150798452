import { CountryCode, PhoneNumber } from "libphonenumber-js/types";
import React, { useEffect, useState } from "react";
import { Typography } from "../../../layout";
import { constructPhoneNumber } from "../../../utils";

type PhoneNumberDisplayProps = {
  phoneNumber?: string;
  countryCode?: CountryCode;
  className?: string;
};

const PhoneNumberDisplay: React.FC<PhoneNumberDisplayProps> = React.memo(
  ({ phoneNumber = "", countryCode = "US", className = "" }) => {
    const [phoneNumberValue, setPhoneNumberValue] = useState<PhoneNumber | null>(null);

    useEffect(() => {
      setPhoneNumberValue(constructPhoneNumber(phoneNumber, countryCode));
    }, [phoneNumber, countryCode]);

    return (
      <Typography variant="span" className={className}>
        {!!phoneNumberValue ? phoneNumberValue.formatNational() : ""}
      </Typography>
    );
  },
);

export default PhoneNumberDisplay;
