import React from "react";
import player from "../../assets/player1.png";
import { LoadingType } from "../../types/LoadingTypes";
import { LoaderImage } from "./LoadingStyles";

type LoaderProps = {
  type: LoadingType;
};

const Loader: React.FC<LoaderProps> = ({ type = "mlb" }) => {
  const getLoader = () => {
    switch (type) {
      case "player":
        return <LoaderImage src={player} width="70px" />;
      case "mlb":
      default:
        return <LoaderImage src="https://www.mlbstatic.com/team-logos/league-on-light/1.svg" width="120px" />;
    }
  };

  return getLoader();
};

export default Loader;
