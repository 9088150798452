import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { StepperThemeType } from "../../types";
import Step, { StepProps, StepShapeTypes } from "./Step";
import StepSpacer from "./StepSpacer";

type StepperProps = {
  /** Children of Stepper should be all steps */
  children: React.ReactElement[];
  /** current active step index */
  activeStep: number;
  /** if the steps are sequential */
  sequential?: boolean;
  /** if the stepper is vertical */
  vertical?: boolean;
  /** if the steps are numbered */
  numbered?: boolean;
  /** shape of the steps */
  shape?: StepShapeTypes;
  /** overrideable styles */
  styles?: StepperThemeType;
};

export interface StepperType extends React.FC<StepperProps> {
  Step: React.FC<StepProps>;
}

const Stepper: StepperType = ({
  children,
  activeStep,
  sequential = false,
  vertical = false,
  numbered = false,
  shape = "round",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: StepperThemeType = { ...Theme.stepper, ...styles };

  return (
    <div
      className={clsx("d-flex", {
        "flex-column": vertical,
        "w-100 align-items-center justify-space-between": !vertical,
      })}
    >
      {React.Children.map(children, (child: React.ReactElement, index: number) => {
        if (!child || !child.type || !React.isValidElement(child)) {
          return null;
        } else if (child.type === Step) {
          const stepNumber: number = index + 1;
          return (
            <>
              {!vertical && <StepSpacer />}
              {React.cloneElement(child, {
                //@ts-ignore
                active: activeStep === stepNumber,
                complete: sequential && stepNumber <= activeStep,
                index: stepNumber,
                styles: StylesOverride,
                shape: shape,
                content: !!numbered ? stepNumber.toString() : undefined,
              })}
              {index + 1 === children.length && !vertical && <StepSpacer />}
            </>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

Stepper.Step = Step;

export default Stepper;
