import clsx from "clsx";
import React from "react";
import { useBreakpoints, useTheme } from "../../../hooks";
import Typography from "../../../layout/typography/Typography";
import { CategoryResponses, NavigationValueThemeType } from "../../../types";
import { Icon } from "../../index";
import { DropdownNavLink } from "./NavigationElementStyles";

type NavigationDropdownLinkProps = {
  toggleMobileNav: (value: boolean) => void;
  to?: string;
  icon?: string;
  iconCategory?: CategoryResponses;
  name: string;
  navisactive?: boolean;
  displayName?: boolean;
  styles: NavigationValueThemeType;
  onClick?: () => void;
};

const NavigationDropdownLink: React.FC<NavigationDropdownLinkProps> = ({
  toggleMobileNav,
  to = "",
  name,
  icon,
  iconCategory,
  navisactive = false,
  displayName = true,
  onClick,
  styles,
}) => {
  const { Theme } = useTheme();
  const { isMobile } = useBreakpoints();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  return (
    <li className="best-nav-dropdown-link">
      <DropdownNavLink
        to={to}
        navisactive={navisactive}
        id={name}
        onClick={() => {
          if (toggleMobileNav) {
            toggleMobileNav(false);
          }
          if (onClick) {
            onClick();
          }
        }}
        styles={StylesOverride}
      >
        {!!icon && (
          <Icon
            iconName={icon}
            iconCategory={iconCategory}
            className={clsx({ "me-1": displayName })}
            styles={{
              color: navisactive ? StylesOverride.active.color : StylesOverride.sublinks.color,
              hoverColor: StylesOverride.hover.color,
            }}
          />
        )}{" "}
        {!!displayName && (
          <Typography
            elementStyles={{
              ...styles,
              size: styles.fontSize,
              color: navisactive
                ? StylesOverride.active.color
                : isMobile
                ? StylesOverride.mobile.linkColor
                : StylesOverride.sublinks.color,
            }}
            variant="div"
          >
            {name}
          </Typography>
        )}
      </DropdownNavLink>
    </li>
  );
};

export default NavigationDropdownLink;
