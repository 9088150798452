import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { calculateColumnWidth } from "./TableUtils";

/**
 * Calculates the column widths of the cells in rows.
 * @param {string} selector - the id or data-uuid of the row element or the element
 * @param {number} numColumns - number of columns to calculate width for
 * @param {number} usedSpace - total size in pixels of defined widths
 * @param {number} minColumnWidth - the min column width of each column
 * @param {boolean} subtractScrollbar - whether or not to subtract the scrollbar width when finding column width
 */
export const useCellResize = (
  selector: string,
  numColumns: number,
  usedSpace: number,
  minColumnWidth = 0,
  subtractScrollbar = false,
) => {
  const [pixelWidth, setPixelWidth] = useState(0);
  const resizeObserver = useRef(
    new ResizeObserver((_entries) => {
      resize();
    }),
  );

  const getElement = (selector: string): HTMLElement | null => {
    return document.getElementById(selector) || document.querySelector(selector);
  };

  const resize = useCallback(() => {
    const el: HTMLElement | null = getElement(selector);
    const [val]: number[] = calculateColumnWidth(el, numColumns, usedSpace, subtractScrollbar);
    const width: number = Math.max(val, minColumnWidth);
    if (pixelWidth !== width) {
      setPixelWidth(width);
    }
  }, [selector, pixelWidth, numColumns, usedSpace, minColumnWidth, subtractScrollbar]);

  useEffect(() => {
    const el: HTMLElement | null = getElement(selector);
    if (el) {
      resizeObserver.current.observe(el);
    }
    return () => {
      const el: HTMLElement | null = getElement(selector);
      if (el) {
        resizeObserver.current.unobserve(el);
      }
    };
  }, [selector]);

  useLayoutEffect(() => {
    if (!pixelWidth) {
      resize();
    }
  }, [pixelWidth, resize]);

  return pixelWidth;
};
