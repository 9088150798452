import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../hooks";
import { CardThemeType } from "../../../types";
import CardBody, { CardBodyProps } from "./CardBody";
import CardFooter, { CardFooterProps } from "./CardFooter";
import CardHeader, { CardHeaderProps } from "./CardHeader";
import { CardStyled } from "./CardStyles";

type CardProps = {
  children: React.ReactElement[];
  className?: string;
  styles?: CardThemeType;
};

export interface CardType extends React.FC<CardProps> {
  Header: React.FC<CardHeaderProps>;
  Body: React.FC<CardBodyProps>;
  Footer: React.FC<CardFooterProps>;
}
const Card: CardType = ({ children, className = "", styles }) => {
  const { Theme } = useTheme();
  const StylesOverride: CardThemeType = { ...Theme.card, ...styles };

  return (
    <CardStyled className={clsx("card", className)} styles={StylesOverride}>
      {React.Children.map(children, (child: React.ReactElement) => {
        if (!child || !child.type || !React.isValidElement(child)) {
          return null;
        } else if (child.type === CardHeader || child.type === CardBody || child.type === CardFooter) {
          return child;
        } else {
          return null;
        }
      })}
    </CardStyled>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
