import React from "react";
import { DataTableDataType } from "../components";
import { DatatableThemeType } from "../types";
import { FormatterType } from "./FormatterTypes";

export type DatatableRow = {
  key: string;
  name: string;
  width?: number;
  minWidth?: number;
  formatter?: any;
  cellClass?: string;
  sortable?: boolean;
  headerBackgroundColor?: string;
};

export type DataTableColumn<T extends DataTableDataType> = {
  name: React.ReactNode;
  key: string;
  width?: number;
  minWidth?: number;
  sortable?: boolean;
  cellClass?: string;
  headerBackground?: string;
  readonlyFormatter?: React.FC<FormatterType<T>>;
  editFormatter?: React.FC<FormatterType<T>>;
  onClick?: (index: number) => void;
  icon?: string;
  visible?: boolean;
};

export type ActionType<T extends DataTableDataType> = {
  name: React.ReactNode;
  onClick: (data: T) => void;
};

export type SortDirection = "ASC" | "DESC" | "NO DIRECTION";

export const ASC: SortDirection = "ASC" as SortDirection;
export const DESC: SortDirection = "DESC" as SortDirection;
export const NO_DIRECTION: SortDirection = "NONE" as SortDirection;

export type ExpansionType<T extends DataTableDataType> = {
  value: T;
  styles?: DatatableThemeType;
};

export type RowSelection<T extends DataTableDataType> = {
  showCheckbox: boolean;
  onRowsSelected: (rows: T[]) => void;
  onRowsDeselected: (rows: T[]) => void;
  selectBy: {
    isSelectedKey: string;
  };
  canSelect: () => boolean;
};

export type RowExpansion<T extends DataTableDataType> = {
  expandable: boolean;
  expandAllCallback: (expanded: object) => void;
  expandCallback: (index: number, value: T, expanded: boolean) => void;
  expandedKey: string;
};

export type RowClick<T extends DataTableDataType> = {
  onClick: (index: number, value: T) => void;
  isClickable?: (index: number, value: T) => boolean;
};
