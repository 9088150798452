import React from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Traditional from "./themes/Traditional";
import { ThemeType } from "./types";

type BCRProps = {
  theme?: ThemeType;
};

type BCRStyleProps = {
  styles: ThemeType;
};

const GlobalStyles = createGlobalStyle<BCRStyleProps>`
  html, body {
    font-family: ${(props) => props.styles.fontFamily};
    background: ${(props) => props.styles.background};
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BCR: React.FC<BCRProps> = ({ children, theme = Traditional }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles styles={theme} />
    <Container>{children}</Container>
  </ThemeProvider>
);

export default BCR;
