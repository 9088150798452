import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { ListRowProps } from "react-virtualized/dist/es/List";
//@ts-ignore
import clone from "rfdc/default";
import { useBreakpoints, useTheme, useWindowDimensions } from "../../../hooks";
import {
  ActionType,
  DataTableColumn,
  DatatableThemeType,
  ExpansionType,
  RowClick,
  RowExpansion,
  RowSelection,
  SortDirection,
} from "../../../types";
import { uniqueStr } from "../../../utils";
import MobileAccordions, { CustomMobileAccordionType } from "../../accordion/MobileAccordions";
import { Hint } from "../../hint";
import { BodyStyled, TableStyled } from "./DatatableStyles";
import Header from "./Header";
import Pagination, { PaginationType } from "./Pagination";
import Row from "./Row";
import TableLoading from "./TableLoading";
import { useCellResize } from "./useCellResize";

const sum = (accumulator: number, currentValue: number) => accumulator + currentValue;

export type DataTableAccordionBreakpoint = "mobile" | "tablet" | "desktop";

export type DataTableDataType = {
  id?: string | number;
  subRows?: any[];
  expanded?: boolean;
};

type RowRendererProps = ListRowProps & {
  index: number;
  key: string;
  style: object;
  parent: any;
};

type DataTableHeightType = {
  /** height of the header row */
  headerHeight?: number;
  /** height of each row */
  rowHeight?: number;
  /** max table height used without autogrow */
  maxTableHeight?: number;
  /** how much to offset autogrow */
  maxHeightOffset?: number;
  /** if the table autogrows, required maxHeightOffset */
  autogrow?: boolean;
};

type DataTableProps<T extends DataTableDataType> = {
  /** data to pass to table */
  data: T[];
  /** columns to display on the table */
  columns: DataTableColumn<T>[];
  /** actions to have for the table */
  actions?: ActionType<T>[];
  /** expansion element */
  expansion?: React.FC<ExpansionType<T>>;
  /** object to control row expansion */
  rowExpansion?: RowExpansion<T>;
  /** configuration for the table heights */
  tableHeights?: DataTableHeightType;
  /** column that is currently sorted */
  sortColumn?: string;
  /** direction a table is sorted */
  sortDirection?: SortDirection;
  /** function called when sort is changed */
  sortFunction?: (key: string, direction: SortDirection) => void;
  /** function to style individual rows */
  rowStylesGetter?: (index: number, value: T) => any;
  /** function to style individual subrows */
  subRowStylesGetter?: (index: number, value: T, subRowIndex: number, subValue: any) => any;
  /** min column width for any column in the table */
  minColumnWidth?: number;
  /** Accordion to display per row on a mobile view */
  accordion?: React.FC<CustomMobileAccordionType<T>>;
  /** Accordion display breakpoint */
  accordionBreakpoint?: DataTableAccordionBreakpoint;
  /** if the table is virtualized */
  virtualized?: boolean;
  /** if the table expansion button should be displayed */
  displayExpansionButton?: boolean;
  /** scroll to a row index in the table */
  scrollToIndex?: number;
  /** object to control row selection */
  rowSelection?: RowSelection<T>;
  /** key to determine in data object is row is disabled */
  rowDisabledKey?: string;
  /** if the user can select all */
  selectAll?: boolean;
  /** function called when a row is clicked */
  rowClick?: RowClick<T>;
  /** function to override the status text of the table */
  statusTextOverride?: (selectedCount: number, totalCount: number) => string;
  /** can the user add a row to the table */
  addRow?: boolean;
  /** function called when addRow is clicked */
  addRowFunction?: () => void;
  /** object to display and control pagination */
  pagination?: PaginationType;
  /** if the table is loading */
  loading?: boolean;
  /** className to pass to the table */
  className?: string;
  /** overrideable styles */
  styles?: DatatableThemeType;
};

const DataTable = <T extends DataTableDataType>(props: DataTableProps<T>) => {
  const [tableId] = useState<string>(`bcr-table-${uniqueStr()}`);
  const [rowPrefix] = useState<string>(`bcr-row-${uniqueStr()}`);

  const {
    data = [],
    actions = [],
    columns = [],
    expansion,
    tableHeights = {
      headerHeight: 25,
      maxTableHeight: 0,
      rowHeight: 40,
      autogrow: false,
      maxHeightOffset: 400,
    },
    sortColumn,
    sortDirection,
    sortFunction = () => {},
    rowStylesGetter = () => {},
    minColumnWidth = 100,
    accordion,
    accordionBreakpoint = "mobile",
    virtualized = true,
    displayExpansionButton = true,
    scrollToIndex,
    subRowStylesGetter,
    rowSelection = {
      showCheckbox: false,
      onRowsSelected: () => {},
      onRowsDeselected: () => {},
      selectBy: {
        isSelectedKey: "selected",
      },
      canSelect: () => false,
    },
    rowDisabledKey = "",
    selectAll = true,
    rowExpansion = {
      expandCallback: () => {},
      expandAllCallback: () => {},
      expandable: false,
      expandedKey: "expansion",
    },
    rowClick,
    styles = {},
    className = "",
    statusTextOverride = null,
    addRow = false,
    addRowFunction = () => {},
    pagination,
    loading = false,
  } = props;
  const { isMobile, isTabletOrMobile, isDesktop } = useBreakpoints();
  const rowHeight: number = tableHeights.rowHeight !== undefined ? tableHeights.rowHeight : 40;
  const headerHeight: number = tableHeights.headerHeight !== undefined ? tableHeights.headerHeight : 25;
  const maxHeightOffset: number = tableHeights.maxHeightOffset !== undefined ? tableHeights.maxHeightOffset : 400;
  const maxTableHeight: number = tableHeights.maxTableHeight !== undefined ? tableHeights.maxTableHeight : 0;
  const autogrow: boolean = tableHeights.autogrow || false;
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  const [lastIndex, setLastIndex] = useState<number | undefined>();
  const [BodyHeight, setBodyHeight] = useState<number>(0);
  const [expandedRows, setExpandedRows] = useState<object>({});
  const [tableSubRows, setTableSubRows] = useState<boolean>(false);
  const [anyAreExpanded, setAnyAreExpanded] = useState<boolean>(false);
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
  const [localScrollToIndex, setLocalScrollToIndex] = useState<undefined | number>(scrollToIndex);
  const { height } = useWindowDimensions();
  const { showCheckbox, onRowsSelected, onRowsDeselected, selectBy, canSelect } = rowSelection;
  const { expandCallback, expandAllCallback, expandable, expandedKey = "" } = rowExpansion;

  const list = useRef<List>();
  const cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: tableHeights.rowHeight || 40,
  });

  // We need to deep copy the data here to force react to intelligently perform diffs
  // With a shallow copy, the sub-components would always assume the data changed and re-render
  // Even when memoization was attempted.
  // With a deep copy, we can do meaningful comparisons and leverage memoization to reduce re-renders.
  const copiedData = clone(data);

  const selectedCount: number = !!rowSelection.selectBy
    ? data.filter((d) => {
        const key = rowSelection.selectBy.isSelectedKey;
        return !!key && !!d[key];
      }).length
    : 0;

  const finalColumns: DataTableColumn<T>[] = columns.filter((c) => c.visible === undefined || c.visible);

  const columnSizes: number[] = [];
  const tableHasSubRows = !!data.filter((d) => !!d.subRows).length;
  let addtlColumnWidths = 16;
  if (showCheckbox) {
    addtlColumnWidths += 31;
  }
  if (!!expansion) {
    addtlColumnWidths += 30;
  }
  if (!!actions?.length) {
    addtlColumnWidths += 31;
  }
  if (tableHasSubRows) {
    addtlColumnWidths += 31;
  }
  const fixedColumns: DataTableColumn<T>[] = finalColumns.filter((c) => c.width);
  const fixedWidths: number = fixedColumns.map((c) => c.width || 0).reduce(sum, 0) + addtlColumnWidths;
  finalColumns.forEach((col, index) => {
    columnSizes[index] =
      col.width || useCellResize(tableId, columns.length - fixedColumns.length, fixedWidths, col.minWidth);
  });

  const TableWidth = columnSizes.reduce(
    (a: number = minColumnWidth, b: number = minColumnWidth) => a + b,
    addtlColumnWidths,
  );

  const recompute = (index?: number) => {
    setTimeout(() => {
      if (typeof index != "undefined" && index > -1) {
        cellMeasurerCache.clear(index, 0);
      }
      if (!!list?.current?.recomputeRowHeights) {
        list!.current!.recomputeRowHeights(index);
      }
    }, 10);
  };

  const tableExpanded = (index: number, newState: boolean, rowValue: T) => {
    const value = clone(expandedRows);
    value[index] = { expanded: newState, value: rowValue };
    setExpandedRows(value);
    setLastIndex(index);
  };

  const calculateBodyHeight = (
    expandedRows: object,
    rowHeight: number,
    headerHeight: number,
    maxHeightOffset: number,
    maxTableHeight: number,
    autogrow: boolean,
  ) => {
    const tableHeight = data.length * (isMobile ? 200 : rowHeight) + headerHeight;
    if (autogrow) {
      const shouldAutoGrow = data.length <= 6 || isMobile;
      setTimeout(() => {
        let growth = 0;
        if (shouldAutoGrow) {
          const expandedValues = Object.values(expandedRows).filter((v) => !!v);
          if (expandedValues.length > 0) {
            for (const [key, value] of Object.entries(expandedRows)) {
              if (!!value) {
                const selector = `${rowPrefix}-${key}`;
                const el: HTMLElement | null = document.getElementById(selector) || document.querySelector(selector);
                growth += el?.scrollHeight || 0;
              }
            }
          }
        }
        const calculated = tableHeight - headerHeight + growth;
        if (maxHeightOffset !== 0) {
          if (height! - maxHeightOffset > 0) {
            setBodyHeight(Math.min(calculated, height! - maxHeightOffset));
          } else {
            setBodyHeight(Math.min(calculated, height!));
          }
        } else if (maxTableHeight !== 0) {
          setBodyHeight(Math.min(calculated, maxTableHeight));
        } else {
          setBodyHeight(calculated);
        }
      }, 0);
    } else {
      const calculated = tableHeight - headerHeight;
      if (maxHeightOffset !== 0) {
        if (height! - maxHeightOffset > 0) {
          setBodyHeight(Math.min(calculated, height! - maxHeightOffset));
        } else {
          setBodyHeight(Math.min(calculated, height!));
        }
      } else if (maxTableHeight !== 0) {
        setBodyHeight(maxTableHeight);
      } else {
        setBodyHeight(calculated);
      }
    }
  };

  const expandAll = (value: boolean) => {
    const newExpanded = {};
    for (let i = 0; i < data.length; i++) {
      newExpanded[i] = { expanded: value, value: data[i] };
    }
    expandAllCallback(newExpanded);
    setExpandedRows(newExpanded);
  };

  const onRowSelectionChange = (row: T, selected: boolean) => {
    const selectedRow: T = { ...row };
    selectedRow[selectBy.isSelectedKey] = selected;
    selected ? onRowsSelected([selectedRow]) : onRowsDeselected([selectedRow]);
  };

  const selectAllRows = (selected: boolean) => {
    const newData: T[] = [];
    data.forEach((obj) => {
      const row = { ...obj };
      if (row[selectBy.isSelectedKey] !== selected) {
        row[selectBy.isSelectedKey] = selected;
        newData.push(row);
      }
    });
    selected ? onRowsSelected([...newData]) : onRowsDeselected([...newData]);
    setAllRowsSelected(selected);
  };

  const checkForSelectAll = () => {
    if (!!selectBy?.isSelectedKey) {
      const notSelectedCount: number = data.filter((row) => !row[selectBy.isSelectedKey]).length;
      setAllRowsSelected(notSelectedCount === 0);
    }
  };

  useEffect(() => {
    recompute();
    calculateBodyHeight(expandedRows, rowHeight, headerHeight, maxHeightOffset, maxTableHeight, autogrow);
    let containsSubRows = false;
    data.forEach((d) => {
      if (!!d.subRows) {
        containsSubRows = true;
      }
    });
    if (containsSubRows !== tableSubRows) {
      setTableSubRows(containsSubRows);
    }
  }, [data]);

  useEffect(() => {
    calculateBodyHeight(expandedRows, rowHeight, headerHeight, maxHeightOffset, maxTableHeight, autogrow);
  }, [expandedRows, lastIndex, rowHeight, headerHeight, height, maxHeightOffset, maxTableHeight, autogrow]);

  useEffect(() => {
    checkForSelectAll();
  }, [data]);

  useEffect(() => {
    const dataValuesExpanded = Object.values(data).map((value) => value[expandedKey] || value.expanded);
    const values = Object.values(expandedRows).map((value: T) => value[expandedKey] || value.expanded);
    setAnyAreExpanded(values.includes(true) || dataValuesExpanded.includes(true));
  }, [expandedRows]);

  useEffect(() => {
    setExpandedRows({});
  }, [sortColumn, sortDirection]);

  const RowRenderer: React.FC<RowRendererProps> = ({ index, key, style, parent }) => {
    const value: T = copiedData[index];
    const expanded = expandedRows[index]
      ? expandedRows[index].expanded
      : !!value[expandedKey]
      ? value[expandedKey]
      : false;
    const expandedClone = clone(expanded);
    const hasSubRows = !!value.subRows;
    const rowStyle = rowStylesGetter(index, value) || {};

    if (value[selectBy.isSelectedKey] === undefined) {
      value[selectBy.isSelectedKey] = false;
    }

    if (value[rowDisabledKey] === undefined) {
      value[rowDisabledKey] = false;
    }

    return (
      <CellMeasurer
        key={rowPrefix + index + key + JSON.stringify(rowStyle)}
        cache={cellMeasurerCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <Row
          actions={actions}
          index={index}
          rowPrefix={rowPrefix}
          virtualizedStyles={style}
          rowStyle={rowStyle}
          rowHeight={rowHeight}
          expanded={expandedClone}
          columns={finalColumns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          value={value}
          hasSubRows={hasSubRows}
          columnSizes={columnSizes}
          expansion={expansion}
          displayExpansionButton={displayExpansionButton}
          recompute={recompute}
          expandable={expandable}
          subRowStylesGetter={subRowStylesGetter}
          showCheckbox={showCheckbox}
          tableExpanded={tableExpanded}
          isRowSelected={value[selectBy.isSelectedKey]}
          onRowSelectionChange={onRowSelectionChange}
          isRowDisabled={value[rowDisabledKey]}
          canSelect={canSelect}
          expandCallback={expandCallback}
          rowClick={rowClick}
        />
      </CellMeasurer>
    );
  };

  const StatusTextRender = () => {
    const count: number = !!pagination ? pagination.totalItems : data.length;
    if (!!statusTextOverride) {
      return statusTextOverride(selectedCount, count);
    } else {
      return (
        <>
          {!!rowSelection?.showCheckbox && selectedCount > 0
            ? `${selectedCount} of ${data.length} selected`
            : `${count} items`}
        </>
      );
    }
  };

  const paginationChanged = () => {
    if (!!list?.current?.scrollToRow) {
      list.current.scrollToRow(0);
    }
    setLocalScrollToIndex(0);
  };

  useEffect(() => {
    setLocalScrollToIndex(scrollToIndex);
  }, [scrollToIndex]);

  const BCRTable = (
    <div className={clsx(className)}>
      <Hint styles={{ ...Theme.hint, fontStyle: "italic" }}>{StatusTextRender()}</Hint>
      <TableStyled id={tableId} styles={StylesOverride}>
        <Header
          headerHeight={headerHeight}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          sortFunction={sortFunction}
          columns={finalColumns}
          expansion={!!expansion}
          subrows={tableSubRows}
          expandAll={expandAll}
          anyAreExpanded={anyAreExpanded}
          columnSizes={columnSizes}
          minWidth={TableWidth}
          displayExpansionButton={displayExpansionButton}
          expandable={expandable}
          showCheckbox={showCheckbox}
          selectAll={selectAll}
          selectAllRows={selectAllRows}
          allRowsSelected={allRowsSelected}
          actions={actions}
          addRow={addRow}
          addRowFunction={addRowFunction}
        />
        <BodyStyled height={BodyHeight} className="bcr-table-body" styles={StylesOverride}>
          {!!data?.length && (
            <AutoSizer>
              {({ height, width }) => {
                const tableWidth = Math.max(width, TableWidth);
                return (
                  <>
                    {loading ? (
                      <TableLoading width={width} height={height} styles={StylesOverride} />
                    ) : (
                      <List
                        //@ts-ignore
                        ref={list}
                        width={tableWidth}
                        height={height}
                        rowCount={data.length}
                        rowHeight={cellMeasurerCache.rowHeight}
                        rowRenderer={RowRenderer}
                        scrollToIndex={localScrollToIndex || lastIndex}
                      />
                    )}
                  </>
                );
              }}
            </AutoSizer>
          )}
        </BodyStyled>
      </TableStyled>
    </div>
  );

  const accordionBreakpointValue: boolean =
    accordionBreakpoint === "mobile" ? isMobile : accordionBreakpoint === "tablet" ? isTabletOrMobile : isDesktop;

  if (accordion) {
    return (
      <>
        {accordionBreakpointValue ? (
          <MobileAccordions
            data={data}
            holderHeight={BodyHeight}
            accordion={accordion}
            scrollToIndex={localScrollToIndex || lastIndex}
            virtualized={virtualized}
            pagination={pagination}
          />
        ) : (
          <>
            {BCRTable}
            {!!pagination && (
              <Pagination
                pagination={pagination}
                onValueChanged={paginationChanged}
                styles={StylesOverride}
                className="mt-2"
              />
            )}
          </>
        )}
      </>
    );
  } else {
    return BCRTable;
  }
};

export default DataTable;
