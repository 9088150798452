import { Theme as AutoSuggestTheme } from "react-autosuggest";
import styled from "styled-components";
import { IconAdorner } from "../../../components/icon";
import { InputThemeType, PlaceholderThemeType } from "../../../types";

type AutoCompleteStylesProps = {
  styles: {
    input: InputThemeType;
    placeholder: PlaceholderThemeType;
  };
  disabled: boolean;
  missed: boolean;
};

export const getAutocompleteTheme = (styles: InputThemeType): AutoSuggestTheme => {
  return {
    container: {
      position: "relative",
      width: "100%",
      flex: "1 0 auto",
    },
    input: {
      width: "100%",
      height: "36px",
      color: styles.color,
      borderRadius: "4px",
      border: `solid 1px ${styles["border-color"]}`,
      backgroundColor: "#ffffff",
      paddingLeft: "10px",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    inputFocused: {
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.2)",
      border: "solid 1px #2b77eb",
      backgroundColor: "#fbf8cb",
      color: "#000000",
      fontStyle: "normal",
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    suggestionsContainer: {
      display: "none",
    },
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      width: "100%",
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.2)",
      border: "solid 1px #2b77eb",
      backgroundColor: "#ffffff",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      maxHeight: "201px",
      overflowY: "auto",
      zIndex: 15,
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "10px 20px",
    },
    suggestionHighlighted: {
      backgroundColor: "#ddd",
    },
  };
};

export const SearchWrapper = styled.div<AutoCompleteStylesProps>`
  &&& {
    display: flex;
    align-items: center;

    .quick-search-input {
      border-color: ${(props) =>
        props.disabled
          ? props.styles.input.disabled!["border-color"]
          : props.missed
          ? props.styles.input.missed!["border-color"]
          : props.styles.input!["border-color"]} !important;
      background: ${(props) =>
        props.disabled
          ? props.styles.input.disabled!.background
          : props.missed
          ? props.styles.input.missed!.background
          : props.styles.input.background}} !important;
      color: ${(props) =>
        props.disabled
          ? props.styles.input.disabled!.color
          : props.missed
          ? props.styles.input.missed!.color
          : props.styles.input.color} !important;
    }

    .quick-search-input::placeholder {
      color: ${(props) => props.styles.placeholder["color"]} !important;
      font-weight: ${(props) => props.styles.placeholder["font-weight"]};
      opacity: 1;
      font-style: ${(props) => props.styles.placeholder["font-style"]};
    }
    
    &.is-invalid {
      .quick-search-input {
        border-color: ${(props) => props.styles.input.required!["border-color"]} !important;
        background: ${(props) => props.styles.input.required!.background} !important;
        color: ${(props) => props.styles.input.required!.color} !important;
      }
    }
    }
  }
`;

export const AutoCompleteTheme: AutoSuggestTheme = {};

export const SelectButton = styled(IconAdorner)`
  transform: translateX(-27px);
`;
