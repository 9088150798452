import React, { useEffect } from "react";
import validate from "validate.js";
import IconAdorner from "../../../components/icon/IconAdorner";
import ValidationConstants from "../../../constants/ValidationConstants";
import { useTheme } from "../../../hooks";
import Input, { InputProps } from "../input/Input";

type EmailProps = InputProps & {
  value?: string;
  invalidMessage?: string;
  setValidEmail?: (value: boolean) => void;
};

const Email: React.FC<EmailProps> = ({
  id,
  value = "",
  setValidEmail,
  invalidMessage = "Invalid Email",
  disabled = false,
  ...rest
}) => {
  const { Theme } = useTheme();
  const disabledColor: string | undefined = Theme.icon.disabledColor;
  const constraints = {
    email: {
      email: true,
    },
  };

  const isValidEmail = (value: string): boolean => !validate({ email: value }, constraints);

  useEffect(() => {
    const validEmail = isValidEmail(value);
    if (!!setValidEmail) {
      setValidEmail(validEmail);
    }
  }, [value]);

  return (
    <Input
      id={id}
      value={value}
      validationMessages={[
        {
          type: ValidationConstants.TYPES.ERROR,
          message: invalidMessage,
          validationFunction: () => {
            return value !== "" && validate({ email: value }, constraints);
          },
        },
      ]}
      endAdorner={
        <IconAdorner
          iconName="fa-check-circle"
          styles={{ color: !value.length ? disabledColor : isValidEmail(value) ? "green" : "red" }}
          disabled={disabled}
        />
      }
      {...rest}
    />
  );
};

export default Email;
