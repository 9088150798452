import styled from "styled-components";
import { CalendarThemeType } from "../../types";

type StyleProps = {
  styles: CalendarThemeType;
};

export const ActionsHolder = styled.div.attrs(() => ({
  className: "bcr-calendar-actions-container",
}))`
  display: flex;
  gap: 2.5rem 0;
  margin-bottom: 0.5rem;
`;

//Calendar Grid View

export const CalendarContainer = styled.div.attrs(() => ({
  className: "bcr-calendar-container",
}))<StyleProps>`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.styles.body.borderColor};
  min-height: 31.25rem;
`;

export const CalendarElement = styled.div`
  flex: 1 0 calc(100% / 7);
`;

export const CalendarDayOfWeekContainer = styled(CalendarElement).attrs(() => ({
  className: "bcr-calendar-day-of-week-container",
}))<StyleProps>`
  background: ${(props) => props.styles.header.background};
  color: ${(props) => props.styles.header.color};
  font-family: ${(props) => props.styles.header.fontFamily};
  font-weight: ${(props) => props.styles.header.fontWeight};
  font-size: ${(props) => props.styles.header.size};
  padding: 0.25rem;
  text-align: center;
`;

export const CalendarDayContainer = styled(CalendarElement).attrs(() => ({
  className: "bcr-calendar-day-container",
}))<StyleProps>`
  min-height: 6.25rem;
  border: 1px solid ${(props) => props.styles.body.borderColor};
  background: ${(props) => props.styles.body.background};
  color: ${(props) => props.styles.body.color};
  flex-direction: row;
  position: relative;
`;

export const CalendarDayOfMonth = styled.div.attrs(() => ({
  className: "bcr-calendar-day-container",
}))<StyleProps>`
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0.25rem;
  font-family: ${(props) => props.styles.body.fontFamily};
  font-size: ${(props) => props.styles.body.size};
`;

export const CalendarDayContent = styled.div.attrs(() => ({
  className: "bcr-calendar-day-content-container",
}))`
  width: 100%;
  height: 100%;
`;

//Calendar List View

export const CalendarListViewContainer = styled.div.attrs(() => ({
  className: "bcr-calendar-list-view",
}))<StyleProps>`
  border: 1px solid ${(props) => props.styles.body.borderColor};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CalendarListViewDayContainer = styled.div.attrs(() => ({
  className: "bcr-calendar-list-view-day-container",
}))<StyleProps>`
  height: 100%;
  width: 100%;
  border: 1px solid ${(props) => props.styles.body.borderColor};
  position: relative;
`;

export const CalendarListViewDate = styled.div.attrs(() => ({
  className: "bcr-calendar-list-view-date",
}))<StyleProps>`
  margin-left: 1rem;
  float: right;
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 1rem;
  font-family: ${(props) => props.styles.body.fontFamily};
  font-size: ${(props) => props.styles.body.size};
  color: ${(props) => props.styles.body.color};
`;
