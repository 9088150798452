import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../../../hooks/useTheme";
import { DatatableThemeType, FormatterStyleProps, FormatterType } from "../../../../types";
import { isObject } from "../../../../utils";
import Tooltip from "../../../popover/tooltip/Tooltip";
import { DataTableDataType } from "../DataTable";
import { formatterPropsCompare } from "../Row";

const TextHolder = styled.div<FormatterStyleProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  padding-right: 0.5rem;
  color: ${(props) => props.styles.row.color};
`;

const TooltipContainer = styled.div`
  width: 500px;
`;

const TextFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value, className = "", styles = {} } = props;
  const { Theme } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };

  const isElementOverflowed = (el: HTMLDivElement): boolean => el.clientWidth < el.scrollWidth;

  const getValue = (): React.ReactNode => (!!value && isObject(value) ? JSON.stringify(value) : value);

  useEffect(() => {
    if (!!ref?.current) {
      setIsOverflowed(isElementOverflowed(ref.current));
    }
  }, [ref]);

  return (
    <div className={clsx("d-flex w-100", className)}>
      {isOverflowed && (
        <Tooltip ref={ref} trigger="hover" align="center" location="bottom" delay={500}>
          <TooltipContainer>{getValue()}</TooltipContainer>
        </Tooltip>
      )}
      <TextHolder styles={StylesOverride} ref={ref}>
        {getValue()}
      </TextHolder>
    </div>
  );
};

const MemoTextFormatter = React.memo(TextFormatter, formatterPropsCompare);

export default MemoTextFormatter;
