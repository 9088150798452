import prettyBytes from "pretty-bytes";

export const convertToPrettyBytes = (value: number): string => prettyBytes(value);

const getFilename = (response: any) => {
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return "";
};

export const downloadFile = (response: any, type: string) => {
  const blob = new Blob([response.data], { type: type });
  const filename = getFilename(response);
  //@ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //@ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 1000);
};

export const getExtensionFromMimeType = (mimeType: string): string => {
  switch (mimeType) {
    case "text/plain":
      return ".txt";
    case "application/pdf":
      return ".pdf";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return ".docx";
    case "application/msword":
      return ".doc";
    case "text/csv":
      return ".csv";
    case "application/vnd.ms-excel":
      return ".xls";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return ".xlsx";
    case "text/html":
      return ".htm,.html";
    case "text/css":
      return ".css";
    case "text/javascript":
      return ".js";
    case "image/jpeg":
      return ".jpg,.jpeg";
    case "image/png":
      return ".png";
    case "image/svg+xml":
      return ".svg";
    case "video/mp4":
      return ".mp4";
    case "video/mpeg":
      return ".mpeg";
    case "video/x-msvideo":
      return ".avi";
    case "application/json":
      return ".json";
    case "application/zip":
      return ".zip";
    case "":
      return "";
    default:
      return mimeType;
  }
};
