import axios from "axios";
import { getPatch, PATCH_HEADERS } from "../../modules/common/Patch";
import {
  ReplayReviewDTO,
  ReviewDTO,
  ReviewSummaryDTO,
  EmailDTO,
  TopNavDTO,
  ReviewSelectorDTO,
  ReviewInfoDTO,
  ReviewStaticOptions,
  ReviewDynamicOptions,
  ReviewRefreshDto,
} from "../../types/ReviewDetailsTypes";

const SERVICES_URL = window.MLBBest.envVariables.SERVICES_URL;
const API_URL = `${SERVICES_URL}/review`;

export const getAllReviewSelectoDTOsByGame = (gamePk: number): Promise<ReviewSelectorDTO[]> =>
  axios.get(`${API_URL}/selector/${gamePk}`).then(response => response.data);

export const getReviewGamesByDate = (date: string): Promise<ReplayReviewDTO[]> =>
  axios.get(`${API_URL}/games/${date}`).then(response => response.data);

export const deleteReview = (reviewId: number): Promise<ReviewDTO> =>
  axios.delete(`${API_URL}/${reviewId}`).then(response => response.data);

export const getReviewByYearAndSeason = (year: number, season: number): Promise<ReviewRefreshDto> =>
  axios.get(`${API_URL}/${year}/${season}`).then(response => response.data);

export const deleteTimingNote = (reviewId: number, timingNotesId: number): Promise<ReviewDTO> =>
  axios.delete(`${API_URL}/${reviewId}/${timingNotesId}`).then(response => response.data);

export const getGamesSummaryForReviews = (date: string): Promise<ReviewSummaryDTO> =>
  axios.get(`${API_URL}/games/summary/${date}`).then(response => response.data);

export const updatePregameIssue = (gamePk: string, pregameIssue: boolean): Promise<void> =>
  axios.post(`${API_URL}/games/summary/${gamePk}/${pregameIssue}`).then(response => response.data);

export const getReplayReviewEmailTemplate = (reviewId: number): Promise<EmailDTO> =>
  axios.get(`${SERVICES_URL}/email/content/replayReview/${reviewId}`).then(response => response.data);

export const getVideoLinkEmailTemplate = (reviewId: number): Promise<EmailDTO> =>
  axios.get(`${SERVICES_URL}/email/content/videoLink/${reviewId}`).then(response => response.data);

export const getReviewTopNavs = (date: string): Promise<TopNavDTO[]> =>
  axios.get(`${API_URL}/topnav/${date}`).then(response => response.data);

export const getReviewInfo = (reviewId: number): Promise<ReviewInfoDTO> =>
  axios.get(`${API_URL}/reviewInfo/${reviewId}`).then(response => response.data);

export const patchReviewInfo = (reviewId: number, path: string, value: any): Promise<ReviewInfoDTO> =>
  axios
    .patch(`${API_URL}/reviewInfo/${reviewId}`, getPatch(true, path, value), PATCH_HEADERS)
    .then(response => response.data);

export const getReviewStaticOptions = (): Promise<ReviewStaticOptions> =>
  axios.get(`${API_URL}/staticOptions`).then(response => response.data);

export const getReviewDynamicOptions = (reviewId: number, gamePk: number): Promise<ReviewDynamicOptions> =>
  axios.get(`${API_URL}/dynamicOptions/${reviewId}/${gamePk}`).then(response => response.data);
