import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useBreakpoints, useTheme } from "../../../hooks";
import {
  AlertConstants,
  BuildType,
  NavigationLinkType,
  NavigationLinkWithSubLinks,
  NavigationLogoType,
  NavigationStatusType,
  NavigationValueThemeType,
} from "../../../types";
import Alert from "../../alert/Alert";
import { DesktopView } from "../../index";
import NavHamburger from "../elements/NavHamburger";
import NavigationLeftLogo from "../elements/NavigationLeftLogo";
import QuickLink from "../elements/QuickLink";
import {
  CenterLogo,
  EndComponentHolder,
  NavBarStyled,
  NavigationSpacer,
  NavLogoHolderSignedOut,
  RightLogo,
  StatusHolder,
} from "./HorizontalStyles";

type HorizontalNavigationProps = {
  displayName?: string;
  startLogo?: NavigationLogoType;
  centerLogo?: NavigationLogoType;
  endLogo?: NavigationLogoType;
  endComponent?: React.ReactElement;
  loggedIn: boolean;
  mobileNavOpen: boolean;
  navigationLinks?: NavigationLinkWithSubLinks[];
  toggleMobileNav: (value: boolean) => void;
  builds?: BuildType[];
  tabletSupport?: boolean;
  displayEnvIndicator?: boolean;
  status?: NavigationStatusType;
  styles: NavigationValueThemeType;
};

const HorizontalNavigation: React.FC<HorizontalNavigationProps> = ({
  children,
  loggedIn,
  startLogo,
  centerLogo,
  endLogo,
  endComponent,
  navigationLinks,
  mobileNavOpen,
  toggleMobileNav,
  builds = [],
  tabletSupport = false,
  displayEnvIndicator = false,
  status,
  styles,
}) => {
  const [quickLinks, setQuickLinks] = useState<NavigationLinkType[]>([]);
  const { isMobile } = useBreakpoints();
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation.horizontal, ...styles };

  useEffect(() => {
    if (!!navigationLinks) {
      setQuickLinks(
        navigationLinks
          .map((link: NavigationLinkWithSubLinks) => {
            const links: NavigationLinkWithSubLinks[] = [link];
            return !!link.subLinks ? links.concat(link.subLinks.filter((sub) => sub.quickLink)) : links;
          })
          .reduce((a: NavigationLinkWithSubLinks[], b: NavigationLinkWithSubLinks[]) => a.concat(b), [])
          .filter((link: NavigationLinkWithSubLinks) => !!link.quickLink),
      );
    } else {
      setQuickLinks([]);
    }
  }, [navigationLinks]);

  const visibleQuickLinks: NavigationLinkType[] = quickLinks.filter((ql) => !!ql.visible);

  return (
    <>
      <NavigationSpacer
        styles={StylesOverride}
        displayEnvIndicator={displayEnvIndicator}
        loggedIn={loggedIn}
        hasStatus={!!status}
      />
      <NavBarStyled
        className={`navbar fixed-top navbar-expand-${
          tabletSupport ? "lg" : "md"
        } navbar-light bg-faded justify-content-start`}
        loggedIn={loggedIn}
        displayEnvIndicator={displayEnvIndicator}
        styles={StylesOverride}
      >
        {!!startLogo && (
          <DesktopView>
            {startLogo.visible && (
              <Link
                className={clsx("navbar-brand", "me-0", { "h-auto": !isMobile, "h-100": isMobile })}
                to={startLogo.link ?? ""}
              >
                <NavigationLeftLogo
                  logo={startLogo.logo}
                  logoText={startLogo.text}
                  builds={builds}
                  styles={StylesOverride}
                />
              </Link>
            )}
          </DesktopView>
        )}
        {loggedIn ? (
          <>
            <NavHamburger open={mobileNavOpen} toggle={toggleMobileNav} />
            {visibleQuickLinks.length && isMobile && !mobileNavOpen ? (
              <div className="d-flex" id="quick-links-holder">
                {visibleQuickLinks.slice(0, 4).map((ql, index) => (
                  <QuickLink
                    key={`${ql?.icon || ql?.to}-${index}`}
                    icon={ql.icon}
                    iconCategory={ql.iconCategory}
                    to={ql.to}
                    styles={StylesOverride}
                  />
                ))}
              </div>
            ) : null}
            {!!centerLogo && isMobile ? (
              <>
                {centerLogo.visible && (
                  <Link
                    className={clsx("navbar-brand", "me-0", { "h-auto": !isMobile, "h-100": isMobile })}
                    to={centerLogo.link ?? ""}
                    style={{ paddingRight: endComponent ? "" : "3.5rem", flex: "2", textAlign: "center" }}
                  >
                    <CenterLogo src={centerLogo.logo} styles={StylesOverride} alt="Center Logo" />
                  </Link>
                )}
              </>
            ) : null}
            {!!endLogo && isMobile ? (
              <>
                {endLogo.visible && (
                  <Link
                    className={clsx("navbar-brand", "me-1", "ms-auto", { "h-auto": !isMobile, "h-100": isMobile })}
                    to={endLogo.link ?? ""}
                    style={{ textAlign: "center" }}
                  >
                    <RightLogo src={endLogo.logo} styles={StylesOverride} alt="Right Logo" />
                  </Link>
                )}
              </>
            ) : null}
            {!!endComponent && <EndComponentHolder>{endComponent}</EndComponentHolder>}
            {children}
          </>
        ) : (
          <NavLogoHolderSignedOut />
        )}
        {!!centerLogo && !isMobile ? (
          <>
            {centerLogo.visible && (
              <Link
                className={clsx("navbar-brand", "me-0", { "h-auto": !isMobile, "h-100": isMobile })}
                to={centerLogo.link ?? ""}
                style={{ textAlign: "center" }}
              >
                <CenterLogo src={centerLogo.logo} styles={StylesOverride} alt="Center Logo" />
              </Link>
            )}
          </>
        ) : null}
        {!!endLogo && !isMobile ? (
          <>
            {endLogo.visible && (
              <Link
                className={clsx("navbar-brand", "ms-auto", "me-0", { "h-auto": !isMobile, "h-100": isMobile })}
                to={endLogo.link ?? ""}
                style={{ textAlign: "center" }}
              >
                <CenterLogo src={endLogo.logo} styles={StylesOverride} alt="End Logo" />
              </Link>
            )}
          </>
        ) : null}
      </NavBarStyled>
      {!!status && (
        <StatusHolder>
          <Alert
            id="navigation-status"
            type={status.type || AlertConstants.TYPES.DANGER}
            styles={{ ...StylesOverride.statuses, padding: "0", margin: "0", fontSize: ".75rem", borderRadius: 0 }}
          >
            <div className="text-center w-100">{status.text}</div>
          </Alert>
        </StatusHolder>
      )}
    </>
  );
};

HorizontalNavigation.defaultProps = {
  displayName: "navigation-horizontal",
};

export default HorizontalNavigation;
