import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types";
import Icon from "../../icon/Icon";
import { NavbarUsernameButton, UsernameDropdown } from "./NavigationElementStyles";

type NavbarUsernameDropdownProps = {
  username?: string;
  vertical: boolean;
  styles: NavigationValueThemeType;
};

const NavbarUsernameDropdown: React.FC<NavbarUsernameDropdownProps> = ({
  username = "",
  children,
  vertical,
  styles,
}) => {
  const { Theme } = useTheme();
  const defaultStyles: NavigationValueThemeType = vertical
    ? { ...Theme.navigation.vertical }
    : { ...Theme.navigation.horizontal };
  const StylesOverride: NavigationValueThemeType = { ...defaultStyles, ...styles };
  return (
    <span>
      <NavbarUsernameButton styles={StylesOverride}>
        {username} &nbsp;{" "}
        <Icon styles={{ color: StylesOverride.signout.color }} iconName="fa-angle-down" className="fa-sm" />
      </NavbarUsernameButton>
      <UsernameDropdown styles={StylesOverride} className="dropdown-menu" aria-labelledby="username-dropdown-link">
        {children}
      </UsernameDropdown>
    </span>
  );
};

export default NavbarUsernameDropdown;
