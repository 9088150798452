import styled from "styled-components";

export const DatePickerInputGroup = styled.div.attrs(() => ({
  className: "input-group",
}))`
  &&& {
    > div:nth-child(2) {
      position: relative;
      flex: 1 1 auto;
    }
  }
`;

export const DatePickerStylesWrapper = styled.div`
  &&& {
    .react-datepicker {
      border-style: solid;
      border-color: ${(props): string => props.theme.datepicker.calendar.borderColor};
    }
    .react-datepicker__input-container {
      display: block;
      min-width: 9.375rem;
    }
    .react-datepicker-wrapper {
      display: block;
    }
    .react-datepicker__day--outside-month {
      color: ${(props): string => props.theme.datepicker.calendar.body.day.colorOutsideMonth} !important;
    }
    .react-datepicker-popper {
      z-index: 1100;
    }
    .react-datepicker__month-container {
      background-color: ${(props): string => props.theme.datepicker.calendar.body.background};
      border-radius: ${(props): string => props.theme.datepicker.calendar.borderRadius ?? "0.3px"};
    }
    .react-datepicker__header {
      background-color: ${(props): string => props.theme.datepicker.calendar.header.background};
      border-bottom: 1px solid ${(props): string => props.theme.datepicker.calendar.header.borderColor};
      border-top-left-radius: ${(props): string => props.theme.datepicker.calendar.borderRadius ?? "0.3px"};
      border-top-right-radius: ${(props): string => props.theme.datepicker.calendar.borderRadius ?? "0.3px"};
      font-family: ${(props): string => props.theme.datepicker.calendar.header.fontFamily ?? undefined};
    }
    .react-datepicker__day {
      color: ${(props): string => props.theme.datepicker.calendar.body.day.color};
      font-size: ${(props): string => props.theme.datepicker.calendar.body.fontSize ?? undefined};
      font-weight: ${(props): string => props.theme.datepicker.calendar.body.fontWeight ?? undefined};
    }
    .react-datepicker__day--disabled {
      background-color: ${(props): string => props.theme.datepicker.calendar.body.day.disabled.background} !important;
      color: ${(props): string => props.theme.datepicker.calendar.body.day.disabled.color} !important;
    }
    .react-datepicker__day--selected {
      background-color: ${(props): string => props.theme.datepicker.calendar.body.day.active.background} !important;
      color: ${(props): string => props.theme.datepicker.calendar.body.day.active.color} !important;
    }
    .react-datepicker__day:hover {
      background-color: ${(props): string => props.theme.datepicker.calendar.body.day.hover.background};
    }
    .react-datepicker__month-select, .react-datepicker__year-select {
      background-color: ${(props): string => props.theme.datepicker.calendar.header.select.background};
      color: ${(props): string => props.theme.datepicker.calendar.header.select.color};
      border: 1px solid ${(props): string => props.theme.datepicker.calendar.body.borderColor};
      border-radius: 4px;
    }
    .react-datepicker__day-name {
      color: ${(props): string => props.theme.datepicker.calendar.header.dayOfWeek.color};
      font-size: ${(props): string => props.theme.datepicker.calendar.header.dayOfWeek.fontSize ?? undefined};
      font-weight: ${(props): string => props.theme.datepicker.calendar.header.dayOfWeek.fontWeight ?? undefined};
    }
    .react-datepicker__navigation-icon::before {
      border-color: ${(props): string => props.theme.datepicker.calendar.header.color};
    }
    .react-datepicker__time {
      background-color: ${(props): string => props.theme.datepicker.calendar.body.background};
      color: ${(props): string => props.theme.datepicker.calendar.body.color};
    }
    .react-datepicker-time__header {
      color: ${(props): string => props.theme.datepicker.calendar.header.color};
      background-color: ${(props): string => props.theme.datepicker.calendar.header.background};
    }
    .react-datepicker__current-month {
      color: ${(props): string => props.theme.datepicker.calendar.header.color};
      font-size: ${(props): string => props.theme.datepicker.calendar.header.fontSize ?? undefined};
      font-weight: ${(props): string => props.theme.datepicker.calendar.header.fontWeight ?? undefined};
    }
    .react-datepicker__triangle::before, .react-datepicker__triangle::after {
      border-bottom-color: ${(props): string => props.theme.datepicker.calendar.header.borderColor};
      visibility: ${(props): string => props.theme.datepicker.calendar.header.triangle?.visibility ?? undefined};
    }
    .react-datepicker__time-container {
      border-left: 1px solid ${(props): string => props.theme.datepicker.calendar.body.borderColor};
    }
    }
  }
`;

export const Spacer = styled.div.attrs(() => ({
  className: "mx-2",
}))`
  font-weight: bold;
  font-size: 1.3rem;
`;

export const SelectsHolder = styled.div`
  display: flex;
`;
