import clsx from "clsx";
import React, { useState } from "react";
import { Icon } from "../../../components";
import { BaseInputProps } from "../../../types/InputTypes";

type InputEditToggleProps = {
  hasEditControls?: boolean;
  startReadOnly?: boolean;
  editIcon?: string;
  readOnlyIcon?: string;
  input: React.ReactElement<BaseInputProps>;
};

const InputEditToggle: React.FC<InputEditToggleProps> = ({
  hasEditControls = true,
  startReadOnly = true,
  input,
  readOnlyIcon = "fa-check",
  editIcon = "fa-pencil-alt",
}) => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(startReadOnly);
  const gutterBottom = input.props.gutterBottom;
  return hasEditControls ? (
    <div className={clsx("d-flex align-items-center", { "mb-2": gutterBottom })}>
      <Icon
        className="me-2"
        iconName={isReadOnly ? editIcon : readOnlyIcon}
        onClick={() => setIsReadOnly(!isReadOnly)}
      />
      <div>
        {React.cloneElement(input, {
          ...input.props,
          gutterBottom: false,
          readOnly: isReadOnly,
        })}
      </div>
    </div>
  ) : (
    <>{input}</>
  );
};

export default InputEditToggle;
