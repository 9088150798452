import React from "react";
import { FormatterType } from "../../../../types";
import Icon from "../../../icon/Icon";
import { DataTableDataType } from "../DataTable";
import { formatterPropsCompare } from "../Row";

const IconFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { rowIndex, icon = "", className = "", onClick } = props;
  return <Icon iconName={icon} className={className} onClick={!!onClick ? () => onClick(rowIndex!) : undefined} />;
};

const MemoIconFormatter = React.memo(IconFormatter, formatterPropsCompare);

export default MemoIconFormatter;
