import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AlertConstants, AlertType, AlertTypes } from "../../types";
import { hasPast, randomString } from "../../utils";

type AlertContextType = {
  alerts: AlertType[];
  addAlert: (alert: AlertType) => void;
  removeAlert: (id: number | string) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const getAlertId = () => {
  return "_" + randomString();
};

const AlertProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  const addAlert = useCallback(
    (alert: AlertType) => {
      if (!alert.id) {
        alert.id = getAlertId();
      }
      if (alert.selfClosing === undefined) {
        alert.selfClosing = true;
      }
      if (alert.selfClosing) {
        const closeTime: Date = new Date();
        closeTime.setTime(closeTime.getTime() + getTime(alert.type));
        alert.closeTime = closeTime;
      }
      setAlerts([...alerts, alert]);
    },
    [alerts],
  );

  const getTime = (type: AlertTypes): number => {
    const typeStr = !!type ? type.toLowerCase() : "";
    switch (typeStr) {
      case AlertConstants.TYPES.SUCCESS:
        return 5000;
      case AlertConstants.TYPES.INFO:
        return 7000;
      case AlertConstants.TYPES.WARNING:
        return 10000;
      case AlertConstants.TYPES.DANGER:
      default:
        //a day in advance
        return 1000 * 60 * 60 * 24;
    }
  };

  const removeAlert = useCallback(
    (id: string | number) => {
      const newAlerts: AlertType[] = [...alerts];
      const index = alerts.findIndex((alert) => alert.id === id);
      newAlerts.splice(index, 1);
      setAlerts(newAlerts);
    },
    [alerts],
  );

  const checkForAlertsToRemove = useCallback(
    (alerts: AlertType[]) => {
      const newAlerts = alerts.filter((alert: AlertType) => {
        if (!!alert.closeTime) {
          return hasPast(alert.closeTime);
        } else {
          return false;
        }
      });
      if (newAlerts.length !== alerts.length) {
        setAlerts(newAlerts);
      }
    },
    [alerts],
  );

  useEffect(() => {
    const intv: NodeJS.Timeout = setInterval(() => {
      checkForAlertsToRemove(alerts);
    }, 1000);

    return () => {
      if (!!intv) {
        clearInterval(intv);
      }
    };
  }, [alerts]);

  return (
    <AlertContext.Provider
      value={{
        alerts,
        addAlert,
        removeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

const useAlerts = (): AlertContextType => {
  const alertContext: AlertContextType | undefined = useContext<AlertContextType | undefined>(AlertContext);
  if (alertContext === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider`);
  }
  return alertContext;
};

export { AlertContext, AlertProvider, useAlerts };
