import React, { useRef } from "react";
import { ActionType, DatatableThemeType, ModalBodyThemeType } from "types";
import { useTheme } from "../../../hooks";
import Icon from "../../icon/Icon";
import Popover from "../../popover/popover/Popover";
import { DataTableDataType } from "./DataTable";
import { MoreActionsButtonStyles, MoreActionsPopoverAction } from "./DatatableStyles";

type MoreActionsButtonProps<T extends DataTableDataType> = {
  data: T;
  actions: ActionType<T>[];
  disabled?: boolean;
  styles?: DatatableThemeType;
};

const MoreActionsButton = <T extends DataTableDataType>(props: MoreActionsButtonProps<T>) => {
  const { data, actions, disabled = false, styles = { datatable: {} } } = props;
  const { Theme } = useTheme();
  const datatableStylesOverride: DatatableThemeType = {
    ...Theme.datatable,
    ...styles?.datatable,
  };
  const modalBodyStylesOverride: ModalBodyThemeType = {
    ...Theme.modal.body,
  };
  // do not want padding here since we're adding a hover effect and padding on the body items
  modalBodyStylesOverride.padding = "0";

  const iconButtonRef = useRef(null);

  return (
    <MoreActionsButtonStyles>
      <div>
        <Popover ref={iconButtonRef} trigger="click" location="right" disabled={disabled}>
          <Popover.Body styles={modalBodyStylesOverride}>
            {actions.map((a: ActionType<T>, index: number) => (
              <MoreActionsPopoverAction
                onClick={() => a.onClick(data)}
                styles={datatableStylesOverride}
                key={`table-action-${index}`}
              >
                {a.name}
              </MoreActionsPopoverAction>
            ))}
          </Popover.Body>
        </Popover>
        <div ref={iconButtonRef} style={{ width: "100%" }}>
          <Icon
            iconName="fa-ellipsis-v"
            styles={
              disabled
                ? {
                    color: datatableStylesOverride.moreActions.icon.disabledColor,
                    hoverColor: datatableStylesOverride.moreActions.icon.disabledColor,
                  }
                : {
                    color: datatableStylesOverride.moreActions.icon.color,
                    hoverColor: datatableStylesOverride.moreActions.icon.hoverColor,
                  }
            }
          />
        </div>
      </div>
    </MoreActionsButtonStyles>
  );
};

export default MoreActionsButton;
