import clsx from "clsx";
import React, { useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { AccordionThemeType } from "../../types";
import Collapse from "../collapse/Collapse";
import AccordionBody, { AccordionBodyProps } from "./AccordionBody";
import AccordionHeader, { AccordionHeaderProps } from "./AccordionHeader";

export type AccordionProps = {
  /** Children to display */
  children: React.ReactElement[];
  /** Custom className */
  className?: string;
  /** Unique key for the accordion */
  accordionKey: string;
  /** Should the accordion start open */
  startOpen?: boolean;
  /** action when the accordion is clicked and its state */
  onClick?: (value: boolean) => void;
  /** if the accordion should have animation */
  animation?: boolean;
  /** overrideable styles */
  styles?: AccordionThemeType;
};

export interface AccordionType extends React.FC<AccordionProps> {
  Header: React.FC<AccordionHeaderProps>;
  Body: React.FC<AccordionBodyProps>;
}

/**
 * Accordion to display/hide data
 */
const Accordion: AccordionType = ({
  children,
  className = "",
  accordionKey,
  startOpen = false,
  onClick,
  animation = true,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: AccordionThemeType = { ...Theme.accordion, ...styles };

  const [isOpen, setIsOpen] = useState(startOpen);

  const toggle = (open: boolean) => {
    setIsOpen(open);
    if (!!onClick) {
      onClick(open);
    }
  };

  return (
    <Collapse singleOpen={false} animation={animation} className={clsx("best-accordion", className)}>
      <Collapse.Item
        header={
          <div>
            {React.Children.map(children, (child: React.ReactElement) => {
              if (!child || !child.type || !React.isValidElement(child)) {
                return null;
              } else if (child.type === AccordionHeader) {
                return React.cloneElement(child, {
                  //@ts-ignore
                  isOpen: isOpen,
                  animation: animation,
                  styles: StylesOverride.header,
                });
              } else {
                return null;
              }
            })}
          </div>
        }
        onToggle={toggle}
        collapseKey={accordionKey}
        startsOpen={startOpen}
      >
        {React.Children.map(children, (child: React.ReactElement) => {
          if (!child || !child.type || !React.isValidElement(child)) {
            return null;
          } else if (child.type === AccordionBody) {
            return React.cloneElement(child, {
              //@ts-ignore
              isOpen: isOpen,
              animation: animation,
              styles: StylesOverride.body,
            });
          } else {
            return null;
          }
        })}
      </Collapse.Item>
    </Collapse>
  );
};

Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;

export default Accordion;
export { AccordionHeader, AccordionBody };
