import { ValueOpt } from "../form";
import { convertToValueOpt } from "../utils/SelectUtil";

export const defaultMonthsValueOptions: MonthsValueOptions = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const getNumberValueOption = (start: number, end: number): ValueOpt<number>[] => {
  const days: number[] = [];
  for (let i = start; i <= end; i++) {
    days.push(i);
  }
  return convertToValueOpt(days);
};

export const getMonthsValueOptions = (months: string[]): ValueOpt<string>[] => {
  const valueOpts: ValueOpt<string>[] = [];
  months.forEach((month, index) => {
    valueOpts.push({
      value: month,
      label: month,
    });
  });
  return valueOpts;
};

export const getYearValueOptions = (start: number, end: number): ValueOpt<number>[] => getNumberValueOption(start, end);

export type MonthsValueOptions = Array<string>;
