import styled from "styled-components";
import { HeroImageThemeType } from "types";

type HeroImagesStylesType = {
  image: string;
  styles: HeroImageThemeType;
};

export const HeroImageStyles = styled.div<HeroImagesStylesType>`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.image});
  border-bottom: ${(props) => props.styles.borderBottom};
  background-size: ${(props) => props.styles.backgroundSize};
  background-repeat: ${(props) => props.styles.backgroundRepeat};
  background-position-x: ${(props) => props.styles.backgroundPositionX};
  background-position-y: ${(props) => props.styles.backgroundPositionY};
`;
