import styled from "styled-components";
import { WysiwygThemeType } from "types";

type WysiwygStylesType = {
  styles: WysiwygThemeType;
};

export const WysiwygEditorStyles = styled.div<WysiwygStylesType>`
  &&& {
    .rdw-editor-toolbar {
      background: ${(props) => props.styles.editorToolbarBackground};
      border-top-right-radius: ${(props) => props.styles.editorToolbarBorderRadius};
      border-top-left-radius: ${(props) => props.styles.editorToolbarBorderRadius};
      border: none;
      margin-bottom: 0px;
      font-size: ${(props) => props.styles.fontSize};
    }
    .public-DraftEditorPlaceholder-root {
      padding-top: ${(props) => props.styles.editorPaddingTop};
      padding-left: ${(props) => props.styles.editorPaddingLeft};
      margin: 1rem 0;
    }
    .DraftEditor-editorContainer {
      border: ${(props) => props.styles.border};
      min-height: ${(props) => props.styles.editorMinHeight};
      padding-top: ${(props) => props.styles.editorPaddingTop};
      padding-left: ${(props) => props.styles.editorPaddingLeft};
    }
    .rdw-history-wrapper {
      background-color: ${(props) => props.styles.historyWrapperColor};
    }
    .rdw-text-align-wrapper {
      background-color: ${(props) => props.styles.textAlignWrapperColor};
      margin-right: ${(props) => props.styles.textAlignMarginRight};
    }
    .rdw-text-align-wrapper {
      border: none;
      height: ${(props) => props.styles.textAlignWrapperHeight};
    }
    .rdw-option-wrapper {
      background: ${(props) => props.styles.optionWrapperBackground};
      border: none;
      height: ${(props) => props.styles.optionWrapperHeight};
    }
    .rdw-list-wrapper {
      background-color: ${(props) => props.styles.listWrapperColor};
    }
    .rdw-option-active {
      background-color: ${(props) => props.styles.activeOptionColor};
    }
    .rdw-editor-main {
      background: ${(props) => props.styles.background};
    }
  }
`;

export const WysiwygFooterStyles = styled.div<WysiwygStylesType>`
  width: 100%;
  height: ${(props) => props.styles.footerHeight};
  background: ${(props) => props.styles.footerBackground};
  border-bottom-right-radius: ${(props) => props.styles.footerBorderRadius};
  border-bottom-left-radius: ${(props) => props.styles.footerBorderRadius};
`;
