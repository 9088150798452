import styled from "styled-components";

const TableTitle = styled.div.attrs(() => ({ className: "my-3", id: "table-title" }))`
  padding-bottom: 0.25rem;
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  width: 100%;
  border-bottom: 1px solid #d2d2d2;
`;

export default TableTitle;
