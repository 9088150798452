import styled from "styled-components";
import { NavigationValueThemeType } from "../../../types";
import Link from "../../links/Link";
import DropdownItem from "../elements/DropdownItem";

//HorizontalNavigationContainer
type HorizontalStyleType = {
  loggedIn?: boolean;
  displayEnvIndicator?: boolean;
  hasStatus?: boolean;
  styles?: NavigationValueThemeType;
};

//Horizontal Navigation From Config

export const NavBarHolder = styled.div.attrs({
  id: "navbarSupportedContent",
})<HorizontalStyleType>`
  @media (max-width: 767px) {
    height: 100vh;
    overflow: scroll;
    margin-top: -2px;
  }
  @media (min-width: 768px) {
    height: ${(props) => props.styles!.size};
  }
`;

export const Navbar = styled.ul.attrs({
  className: "navbar-nav me-auto",
})<HorizontalStyleType>`
  @media (max-width: 767px) {
    height: 100%;
    background: ${(props) => props.styles!.mobile.background};
  }
  @media (min-width: 768px) {
    height: ${(props) => props.styles!.size};
    background: ${(props) => props.styles!.background};
  }
  margin-left: 0;
`;

export const NavbarSignoutLink = styled(Link)<HorizontalStyleType>`
  display: block;
  z-index: 1051 !important;
  padding: 0.25rem 1.5rem;
  color: ${(props) => props.styles?.color} !important;
  background: ${(props) => props.styles?.background} !important;
  &&& {
    text-decoration: none;
  }
  &:hover {
    color: ${(props) => props.styles?.hover.color} !important;
    background: ${(props) => props.styles?.hover.background} !important;
  }
`;

export const NavbarSignoutItem = styled(DropdownItem)<HorizontalStyleType>`
  z-index: 1051 !important;
  color: ${(props) => props.styles?.color} !important;
  background: ${(props) => props.styles?.background} !important;
  &:hover {
    color: ${(props) => props.styles?.hover.color} !important;
    background: ${(props) => props.styles?.hover.background} !important;
  }
`;

export const NavigationSpacer = styled.div<HorizontalStyleType>`
  width: 100%;
  @media (min-width: 768px) {
    height: calc(
      ${(props) => props.styles!.size} +
        ${(props) => (props.loggedIn ? (props.hasStatus ? "32px" : "22px") : props.hasStatus ? "10px" : "0px")}
    );
  }
  @media (max-width: 768px) {
    height: calc(
      ${(props) => props.styles!.mobile.size} +
        ${(props) =>
          props.displayEnvIndicator ? (props.hasStatus ? "28px" : "18px") : props.hasStatus ? "10px" : "0px"}
    );
  }
`;

//Horizontal Navigation
export const NavBarStyled = styled.nav.attrs(() => ({
  id: "best-navbar",
}))<HorizontalStyleType>`
  &&& {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    background: ${(props) => props.styles!.background};
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1051;
    @media (min-width: 768px) {
      height: ${(props) => props.styles!.size};
      background-size: ${(props) => `calc(1.75 * ${props.styles!.size}) ${props.styles!.size}`};
      margin-top: ${(props) => (props.loggedIn ? `1.375rem` : 0)};
    }
    @media (max-width: 768px) {
      height: ${(props) => props.styles!.mobile.size};
      background-size: ${(props) => `calc(1.75 * ${props.styles!.mobile.size}) ${props.styles!.mobile.size}`};
      padding: 0;
      margin-top: ${(props) => (props.displayEnvIndicator ? "1.125rem" : "0")};
    }
  }
`;

export const NavLogoHolderSignedOut = styled.div`
  height: 0.625rem;
`;

export const RightLogo = styled.img<HorizontalStyleType>`
  height: ${(props) => props.styles!.rightLogo.height};
  width: ${(props) => props.styles!.rightLogo.width};
  margin: ${(props) => props.styles!.rightLogo.margin};
  margin-left: auto;
`;

export const CenterLogo = styled.img<HorizontalStyleType>`
  align-self: center;
  height: ${(props) => props.styles!.centerLogo.height};
  width: ${(props) => props.styles!.centerLogo.width};
  margin: ${(props) => props.styles!.centerLogo.margin};
`;

export const StatusHolder = styled.div.attrs(() => ({
  className: "fixed-top",
}))`
  @media (min-width: 768px) {
    margin-top: 62px;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;

export const MobilePermissionsHolder = styled.div<HorizontalStyleType>`
  background: ${(props) => props.styles!.mobile.permissionsBackground};
  color: ${(props) => props.styles!.mobile.permissionsColor};
  padding: 0.5rem 0.625rem 0.5rem 2.25rem;
`;

export const EndComponentHolder = styled.div`
  width: 3.5rem;
  text-align: center;
`;
