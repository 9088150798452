import React from "react";
import Checkbox from "../../../form/inputs/checkbox/Checkbox";
import { useTheme } from "../../../hooks";
import {
  ActionType,
  ASC,
  DataTableColumn,
  DatatableThemeType,
  DESC,
  NO_DIRECTION,
  SortDirection,
} from "../../../types";
import { DataTableDataType } from "./DataTable";
import {
  ActionsHeader,
  ExpansionButtonHolder,
  HeaderCheckboxHolder,
  HeaderDiv,
  SubRowButtonHolder,
} from "./DatatableStyles";
import HeaderCell from "./HeaderCell";
import SortIcon from "./SortIcon";
import SubrowButton from "./SubrowButton";

export type HeaderProps<T extends DataTableDataType> = {
  columns: DataTableColumn<T>[];
  headerHeight?: number;
  sortColumn?: string;
  sortDirection?: SortDirection;
  sortFunction?: (key: string, direction: SortDirection) => void;
  expansion?: boolean;
  subrows?: boolean;
  expandAll?: (expand: boolean) => void;
  anyAreExpanded?: boolean;
  columnSizes?: number[];
  minWidth?: number;
  displayExpansionButton?: boolean;
  expandable?: boolean;
  showCheckbox?: boolean;
  selectAllRows?: (value: boolean) => void;
  allRowsSelected?: boolean;
  selectAll?: boolean;
  actions?: ActionType<T>[];
  styles?: DatatableThemeType;
  addRow?: boolean;
  addRowFunction?: () => void;
};

const Header = <T extends DataTableDataType>(props: HeaderProps<T>) => {
  const {
    columns,
    headerHeight = 35,
    sortColumn,
    sortDirection,
    sortFunction = () => {},
    expansion = false,
    subrows = false,
    expandAll = () => {},
    anyAreExpanded = false,
    columnSizes = [],
    minWidth = 50,
    displayExpansionButton = true,
    expandable = true,
    showCheckbox = false,
    selectAllRows = () => {},
    allRowsSelected = false,
    selectAll = true,
    actions = [],
    styles = {},
    addRow = false,
    addRowFunction = () => {},
  } = props;
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };

  const handleSort = (canSort: boolean, key: string) => {
    if (canSort) {
      let direction;
      if (sortColumn !== key) {
        direction = ASC;
      } else if (sortDirection === ASC) {
        direction = DESC;
      } else {
        direction = NO_DIRECTION;
      }
      const newKey = direction === NO_DIRECTION ? "" : key;
      sortFunction(newKey, direction);
    }
  };

  return (
    <HeaderDiv height={headerHeight} minWidth={minWidth} styles={StylesOverride}>
      {showCheckbox && (
        <HeaderCheckboxHolder>
          {selectAll && (
            <Checkbox
              id="select-all-checkbox"
              checked={allRowsSelected}
              onChange={() => {
                selectAllRows(!allRowsSelected);
              }}
            />
          )}
        </HeaderCheckboxHolder>
      )}
      {expansion && displayExpansionButton && (
        <ExpansionButtonHolder>
          <SubrowButton expanded={anyAreExpanded} onClick={expandAll} />
        </ExpansionButtonHolder>
      )}
      {subrows && expandable && (
        <SubRowButtonHolder>
          <SubrowButton expanded={anyAreExpanded} onClick={expandAll} />
        </SubRowButtonHolder>
      )}
      {!!actions?.length && <ActionsHeader />}
      {columns.map((col: DataTableColumn<T>, index: number) => {
        const { name, key, sortable = false, cellClass = "", width } = col;
        const columnId = `${Math.floor(Math.random() * 10000)}-${key}`;
        const headerWidth = !!columnSizes[index] ? columnSizes[index] : width;
        // Only pass down addRowProps for the last column
        const addRowProps = index === columns.length - 1 ? { addRow, addRowFunction } : {};
        return (
          <HeaderCell
            columnId={columnId}
            key={columnId}
            width={headerWidth}
            headerHeight={headerHeight}
            sortable={sortable}
            onClick={() => handleSort(sortable, key)}
            sorted={sortColumn === key}
            backgroundColor={col.headerBackground}
            name={name}
            sortDirection={sortDirection}
            cellClass={cellClass}
            {...addRowProps}
          />
        );
      })}
    </HeaderDiv>
  );
};

export { SubrowButton, SortIcon, ExpansionButtonHolder, HeaderCell };

export default Header;
