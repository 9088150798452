import React from "react";
import { NavigationConfigType } from "../../types";
import { DesktopTabletView, MobileView } from "../view";
import HorizontalNavigationFromConfig from "./horizontal/HorizontalNavigationFromConfig";
import VerticalNavigationFromConfig from "./vertical/VerticalNavigationFromConfig";

type NavigationFromConfigProps = {
  config: NavigationConfigType;
  vertical?: boolean;
};
const NavigationFromConfig: React.FC<NavigationFromConfigProps> = ({ config, vertical = false }) => (
  <>
    <MobileView>
      <HorizontalNavigationFromConfig config={config} />
    </MobileView>
    <DesktopTabletView>
      {vertical ? <VerticalNavigationFromConfig config={config} /> : <HorizontalNavigationFromConfig config={config} />}
    </DesktopTabletView>
  </>
);

export default NavigationFromConfig;
