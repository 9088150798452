import React from "react";
import Loading from "./Loading";
import { useLoading } from "./LoadingContext";

const LoadingFromProvider: React.FC = () => {
  const { loading, type, animated, label } = useLoading();
  return loading ? <Loading type={type} animated={animated} label={label} /> : null;
};

export default LoadingFromProvider;
