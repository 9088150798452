import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { AccordionThemeType } from "../../types";
import Icon from "../icon/Icon";

type AccordionIconProps = {
  /** if the icon is open */
  isOpen: boolean;
  /** overrideable styles */
  styles?: AccordionThemeType;
};

const AccordionIcon: React.FC<AccordionIconProps> = ({ isOpen, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: AccordionThemeType = { ...Theme.accordion, ...styles };
  return (
    <div className="d-flex align-items-center">
      <Icon
        styles={{ size: "1.5rem", padding: "0 1.25rem", margin: "0.375rem 0", color: StylesOverride.actionColor }}
        iconName={isOpen ? "fa-angle-up" : "fa-angle-down"}
      />
    </div>
  );
};

export default AccordionIcon;
