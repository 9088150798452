import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBreakpoints, useTheme } from "../../../hooks";
import Typography from "../../../layout/typography/Typography";
import { NavigationConfigType, NavigationLinkType, NavigationValueThemeType } from "../../../types";
import DesktopTabletView from "../../view/DesktopTabletView";
import MobileView from "../../view/MobileView";
import NavBar from "../elements/NavBar";
import NavbarDropdown from "../elements/NavbarDropdown";
import NavbarSignout from "../elements/NavbarSignout";
import NavbarUsernameDropdown from "../elements/NavbarUsernameDropdown";
import NavigationDropdownLink from "../elements/NavigationDropdownLink";
import NavigationLink from "../elements/NavigationLink";
import HorizontalNavigation from "./HorizontalNavigation";
import {
  MobilePermissionsHolder,
  Navbar,
  NavBarHolder,
  NavbarSignoutItem,
  NavbarSignoutLink,
} from "./HorizontalStyles";

type HorizontalNavigationFromConfigProps = {
  config: NavigationConfigType;
  styles?: NavigationValueThemeType;
};

const HorizontalNavigationFromConfig: React.FC<HorizontalNavigationFromConfigProps> = ({
  config: {
    displayEnvIndicator,
    loggedIn,
    username,
    usernameDropdownLinks,
    navigationLinks,
    startLogo,
    centerLogo,
    endLogo,
    endComponent,
    builds,
    tabletSupport,
    env,
    status,
    permissions = "",
    displayPermissionsIndicator = false,
  },
  styles,
}) => {
  const location = useLocation();
  const navLinkRef: React.Ref<HTMLDivElement> = useRef(null);
  const { isMobile } = useBreakpoints();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState<string[]>([]);

  const toggleMobileNav = (value: boolean) => {
    if (isMobile) {
      setMobileNavOpen(!mobileNavOpen);
    } else {
      setMobileNavOpen(false);
    }
  };

  const checkForActive = (...to: string[]): boolean => !!to && to.includes(location?.pathname);

  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = !!styles
    ? { ...Theme.navigation.horizontal, ...styles }
    : Theme.navigation.horizontal;

  useEffect(() => {
    if (navLinkRef?.current) {
      navLinkRef?.current?.addEventListener("scroll", (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    }
  }, [navLinkRef]);

  return (
    <NavBar displayEnvIndicator={displayEnvIndicator} env={env} vertical={false}>
      <NavbarSignout
        loggedIn={loggedIn}
        env={env}
        displayEnvIndicator={displayEnvIndicator}
        styles={StylesOverride}
        horizontal={true}
      >
        <NavbarUsernameDropdown username={username} vertical={false} styles={StylesOverride}>
          <div>
            {usernameDropdownLinks &&
              usernameDropdownLinks.map(({ name, to, onClick, visible = true }) =>
                visible && to && to.length ? (
                  <NavbarSignoutLink to={to} onClick={onClick} key={`signout-link-${name}`}>
                    {name}
                  </NavbarSignoutLink>
                ) : (
                  <NavbarSignoutItem onClick={onClick} key={`signout-item-${name}`} styles={StylesOverride}>
                    {name}
                  </NavbarSignoutItem>
                ),
              )}
          </div>
        </NavbarUsernameDropdown>
      </NavbarSignout>
      <HorizontalNavigation
        startLogo={startLogo}
        centerLogo={centerLogo}
        endLogo={endLogo}
        endComponent={endComponent}
        builds={builds}
        loggedIn={loggedIn}
        tabletSupport={tabletSupport}
        displayEnvIndicator={displayEnvIndicator}
        mobileNavOpen={mobileNavOpen}
        toggleMobileNav={setMobileNavOpen}
        navigationLinks={navigationLinks}
        status={status}
        styles={StylesOverride}
      >
        <NavBarHolder
          displayEnvIndicator={displayEnvIndicator}
          className={`collapse navbar-collapse ${mobileNavOpen ? "show" : "hide"}`}
          id="navbarSupportedContent"
          ref={navLinkRef}
          styles={StylesOverride}
          style={{ flexGrow: !!navigationLinks && navigationLinks.length > 0 ? 1 : 0 }}
        >
          <DesktopTabletView>
            <Navbar styles={StylesOverride}>
              {!!navigationLinks &&
                navigationLinks.map(
                  ({ name, to, icon, iconCategory, onClick, visible = true, subLinks = [], displayName = true }) => {
                    if (visible) {
                      const visibleSubLinks: NavigationLinkType[] = !!subLinks
                        ? subLinks.filter((subLink: NavigationLinkType) => !!subLink.visible)
                        : [];
                      const subTos: string[] = visibleSubLinks.filter((sl) => !!sl.to).map((sl) => sl.to!);
                      return visibleSubLinks.length ? (
                        <NavbarDropdown
                          title={name}
                          navisactive={checkForActive(...subTos)}
                          key={`nav-link-${name}`}
                          dropdownId={`nav-link-${name}`}
                          vertical={false}
                          icon={icon}
                          iconCategory={iconCategory}
                          styles={StylesOverride}
                          displayName={displayName}
                          openDropdowns={openDropdowns}
                          setOpenDropdowns={setOpenDropdowns}
                        >
                          {visibleSubLinks.map((sublink) => (
                            <NavigationDropdownLink
                              key={`dropdown-link-${sublink.name}`}
                              to={sublink.to}
                              name={sublink.name}
                              icon={sublink.icon}
                              iconCategory={sublink.iconCategory}
                              navisactive={checkForActive(sublink.to || "")}
                              toggleMobileNav={toggleMobileNav}
                              displayName={sublink.displayName}
                              styles={StylesOverride}
                            />
                          ))}
                        </NavbarDropdown>
                      ) : (
                        <NavigationLink
                          key={`nav-link-${name}`}
                          to={to}
                          icon={icon}
                          iconCategory={iconCategory}
                          name={name}
                          onClick={onClick}
                          vertical={false}
                          displayName={displayName}
                          navisactive={checkForActive(to || "")}
                          toggleMobileNav={toggleMobileNav}
                          styles={StylesOverride}
                        />
                      );
                    } else {
                      return null;
                    }
                  },
                )}
            </Navbar>
          </DesktopTabletView>
          <MobileView>
            <Navbar styles={StylesOverride}>
              {permissions && displayPermissionsIndicator ? (
                <MobilePermissionsHolder styles={StylesOverride}>
                  <Typography
                    elementStyles={{
                      ...StylesOverride,
                      size: StylesOverride.fontSize,
                      color: StylesOverride!.mobile.permissionsColor,
                    }}
                    variant="div"
                  >
                    {permissions}
                  </Typography>
                </MobilePermissionsHolder>
              ) : null}
              {navigationLinks &&
                navigationLinks.map(
                  ({ name, to, onClick, active, visible = true, subLinks }) =>
                    visible &&
                    (subLinks && subLinks.length ? (
                      <NavbarDropdown
                        title={name}
                        navisactive={active}
                        key={`mobile-sub-nav-link-${name}`}
                        dropdownId={`mobile-sub-nav-link-${name}`}
                        vertical={false}
                        styles={StylesOverride}
                        openDropdowns={openDropdowns}
                        setOpenDropdowns={setOpenDropdowns}
                      >
                        {subLinks.map((sublink) => (
                          <NavigationDropdownLink
                            key={`mobile-sub-nav-dropdown-link-${sublink.name}`}
                            to={sublink.to}
                            name={sublink.name}
                            navisactive={checkForActive(sublink.to || "")}
                            toggleMobileNav={toggleMobileNav}
                            styles={StylesOverride}
                          />
                        ))}
                      </NavbarDropdown>
                    ) : (
                      <NavigationLink
                        key={`mobile-nav-link-${name}`}
                        to={to}
                        name={name}
                        vertical={false}
                        onClick={onClick}
                        navisactive={checkForActive(to || "")}
                        toggleMobileNav={toggleMobileNav}
                        styles={StylesOverride}
                      />
                    )),
                )}
              <NavbarDropdown
                dropdownId="mobile-nav-link-username"
                title={username}
                vertical={false}
                styles={StylesOverride}
                openDropdowns={openDropdowns}
                setOpenDropdowns={setOpenDropdowns}
              >
                {usernameDropdownLinks &&
                  usernameDropdownLinks.map(
                    ({ name, to, onClick, visible = true }) =>
                      visible && (
                        <NavigationDropdownLink
                          key={`dropdown-nav-link-${name}`}
                          to={to}
                          name={name}
                          navisactive={checkForActive(to || "")}
                          toggleMobileNav={toggleMobileNav}
                          onClick={onClick}
                          styles={StylesOverride}
                        />
                      ),
                  )}
              </NavbarDropdown>
            </Navbar>
          </MobileView>
        </NavBarHolder>
      </HorizontalNavigation>
    </NavBar>
  );
};

export default HorizontalNavigationFromConfig;
