import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../form/buttons";
import { useCountdown } from "../../utils";
import { Modal } from "../modal";
import { InactivityMonitorModalProps } from "./InactivityMonitor";

const FooterButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

type CountdownProps = {
  value: number;
};

const Countdown: React.FC<CountdownProps> = ({ value }) => {
  const countdown: string = useCountdown(value);
  return <span>{countdown}</span>;
};

type InactivityWarningProps = {
  duration: number;
  onClose: () => void;
  logout: () => void;
  modalProps?: InactivityMonitorModalProps;
};

const InactivityWarning: React.FC<InactivityWarningProps> = ({ duration, onClose, logout, modalProps }) => {
  const onConfirm = () => {
    logout();
    onClose();
  };

  useEffect(() => {
    const interval = setTimeout(onConfirm, duration);
    return () => !!interval && clearInterval(interval);
  }, []);

  return (
    <Modal show={true}>
      <Modal.Header>{!!modalProps?.header ? !!modalProps?.header : "Do you want to logout?"}</Modal.Header>
      <Modal.Body>
        {!!modalProps?.body ? !!modalProps?.body : "You have been inactive and will be logged out in"}{" "}
        <Countdown value={duration} />
      </Modal.Body>
      <Modal.Footer>
        <FooterButtonsContainer>
          <Button onClick={onConfirm}>{!!modalProps?.confirm ? !!modalProps?.confirm : "Log Out"}</Button>
          <Button onClick={onClose} variant="primary">
            {!!modalProps?.cancel ? !!modalProps?.cancel : "Stay Logged In"}
          </Button>
        </FooterButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default InactivityWarning;
