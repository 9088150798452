import React from "react";
import { ClipLoader } from "react-spinners";
import { CalendarEvent, CalendarThemeType } from "../../types";
import CalendarListViewDay from "./CalendarListViewDay";
import { CalendarListViewContainer } from "./CalendarStyles";
import { getEvent } from "./CalendarUtil";

type CalendarListViewProps<T> = {
  events: CalendarEvent<T>[];
  loading?: boolean;
  daysToRender: number[];
  daysInMonth: number;
  year: number;
  month: number;
  styles: CalendarThemeType;
};

const CalendarListView = <T,>(props: CalendarListViewProps<T>) => {
  const { events, loading = false, daysToRender, daysInMonth, year, month, styles } = props;
  return (
    <CalendarListViewContainer styles={styles}>
      {loading ? (
        <div className="align-self-center ms-auto me-auto">
          <ClipLoader />
        </div>
      ) : (
        <>
          {daysToRender.map((day, index) => {
            const dayOfMonth: number | undefined = index > 0 && index <= daysInMonth ? index : undefined;
            if (dayOfMonth) {
              const event: CalendarEvent<T> | undefined = getEvent(events, dayOfMonth);
              return (
                <CalendarListViewDay month={month} dayOfMonth={dayOfMonth} year={year} event={event} styles={styles} />
              );
            } else {
              return null;
            }
          })}
        </>
      )}
    </CalendarListViewContainer>
  );
};

export default CalendarListView;
