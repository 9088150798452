import React from "react";
import { useTheme } from "../../../hooks";
import { DatatableThemeType } from "../../../types";
import Icon from "../../icon/Icon";
import { ExpansionButtonHolder } from "./DatatableStyles";

export type ExpandedButtonProps = {
  expanded?: boolean;
  disabled?: boolean;
  onClick: () => void;
  styles?: DatatableThemeType;
};

const ExpandedButton: React.FC<ExpandedButtonProps> = ({
  expanded = false,
  disabled = false,
  onClick,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  return (
    <ExpansionButtonHolder onClick={!disabled ? onClick : () => {}}>
      {disabled ? (
        <Icon
          iconName="fa-minus-circle"
          styles={{
            color: StylesOverride.expanded.icon.disabledColor,
            hoverColor: StylesOverride.expanded.icon.disabledColor,
          }}
        />
      ) : expanded ? (
        <Icon
          iconName="fa-minus-circle"
          styles={{ color: StylesOverride.expanded.icon.color, hoverColor: StylesOverride.expanded.icon.hoverColor }}
        />
      ) : (
        <Icon
          iconName="fa-plus-circle"
          styles={{ color: StylesOverride.expanded.icon.color, hoverColor: StylesOverride.expanded.icon.hoverColor }}
        />
      )}
    </ExpansionButtonHolder>
  );
};

export default ExpandedButton;
