import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types/ThemeType";
import Icon from "../../icon/Icon";
import { MobileNavBigLinkStyled } from "./NavigationElementStyles";

type MobileNavBigLinkProps = {
  toggleMobileNav: (value: boolean) => void;
  title: string;
  icon: string;
  to: string;
  navisactive?: boolean;
  hasTopBorder?: boolean;
  styles: NavigationValueThemeType;
};

const MobileNavBigLink: React.FC<MobileNavBigLinkProps> = ({
  toggleMobileNav,
  title,
  icon,
  to,
  navisactive = false,
  hasTopBorder = false,
  styles,
}) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };

  return (
    <MobileNavBigLinkStyled
      style={{ borderWidth: hasTopBorder ? "1px 1px 1px 1px" : "0 1px 1px 1px" }}
      to={to}
      navisactive={navisactive}
      onClick={() => {
        if (toggleMobileNav) {
          toggleMobileNav(false);
        }
      }}
      styles={StylesOverride}
    >
      <div>
        <Icon styles={{ color: StylesOverride.color }} iconName={icon} />
        <span style={{ marginLeft: "10px" }}>{title}</span>
      </div>
    </MobileNavBigLinkStyled>
  );
};

export default MobileNavBigLink;
