import React, { useEffect, useState } from "react";
import { DateInputThemeType } from "../../../types";
import { BaseInputProps } from "../../../types/InputTypes";
import DateInput from "../dates/DateInput";

type YearInputProps = BaseInputProps & {
  value?: number | "N/A";
  onChange: (value?: number | "N/A") => void;
  minYear?: number;
  maxYear?: number;
  styles?: DateInputThemeType;
};

const YearInput: React.FC<YearInputProps> = ({ value, onChange, minYear, maxYear, ...rest }) => {
  const [tempDate, setTempDate] = useState<Date | undefined | "N/A">(undefined);
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);

  const changeDate = (value?: Date | "N/A") => {
    if (value === "N/A" || value === undefined) {
      onChange(value);
    } else {
      onChange((value as Date).getFullYear());
    }
  };

  useEffect(() => {
    if (!!value) {
      setTempDate(new Date(`1/1/${value}`));
    } else {
      setTempDate(undefined);
    }
  }, [value]);

  useEffect(() => {
    if (!!minYear) {
      setMinDate(new Date(`1/1/${minYear}`));
    } else {
      setMinDate(undefined);
    }
  }, [minYear]);

  useEffect(() => {
    if (!!maxYear) {
      setMaxDate(new Date(`12/31/${maxYear}`));
    } else {
      setMaxDate(undefined);
    }
  }, [maxYear]);

  return <DateInput value={tempDate} onChange={changeDate} yearOnly minDate={minDate} maxDate={maxDate} {...rest} />;
};

export default YearInput;
