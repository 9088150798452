import React from "react";
import Typography from "../../../layout/typography/Typography";
import { NavigationValueThemeType } from "../../../types";
import Icon from "../../icon/Icon";
import DesktopTabletView from "../../view/DesktopTabletView";
import MobileView from "../../view/MobileView";
import { NoSublinkMobileLink, NoSublinkNavLink } from "./NavigationElementStyles";

type IconNavigationNoSubLinksType = {
  toggleMobileNav?: (value: boolean) => void;
  to?: string;
  icon?: string;
  name: string;
  navisactive: boolean;
  styles: NavigationValueThemeType;
};

const IconNavigationLinkNoSubLinks: React.FC<IconNavigationNoSubLinksType> = ({
  toggleMobileNav,
  to = "",
  name,
  navisactive,
  icon = "",
  styles,
}) => {
  return (
    <>
      <DesktopTabletView>
        <NoSublinkNavLink
          onClick={() => {
            if (!!toggleMobileNav) {
              toggleMobileNav(false);
            }
          }}
          to={to}
          styles={styles}
          navisactive={navisactive}
        >
          {icon && <Icon iconName={icon} styles={{ color: navisactive ? styles.active.color : styles.color }} />}
          {name && (
            <span style={{ marginLeft: icon ? "10px" : "" }}>
              <Typography
                elementStyles={{
                  ...styles,
                  size: styles.fontSize,
                  color: navisactive ? styles.active.color : styles.color,
                }}
                variant="div"
              >
                {name}
              </Typography>
            </span>
          )}
        </NoSublinkNavLink>
      </DesktopTabletView>

      <MobileView>
        <NoSublinkMobileLink
          to={to}
          onClick={() => {
            if (toggleMobileNav) {
              toggleMobileNav(false);
            }
          }}
          styles={styles}
        >
          <Icon iconName={icon} styles={{ color: styles.color, hoverColor: styles?.hover?.color }} className="me-3" />
          <Typography elementStyles={{ ...styles, size: styles.fontSize }} variant="div">
            {name}
          </Typography>
        </NoSublinkMobileLink>
      </MobileView>
    </>
  );
};

export default IconNavigationLinkNoSubLinks;
