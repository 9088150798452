import React from "react";
import Catcher from "./Catcher";

type ErrorCatcherProps = {
  /** Name of your application to display */
  appName: string;
  /** if the catcher is active */
  active?: boolean;
  /** Force the catcher to be in error state */
  hasError?: boolean;
};

/**
 * Error catcher for all react errors under the tree of this component
 * Will display clean screen versus react error screen
 */
const ErrorCatcher: React.FC<ErrorCatcherProps> = ({ children, active = true, appName, hasError = false }) => (
  <>
    {active ? (
      <Catcher appName={appName} hasError={hasError}>
        {children}
      </Catcher>
    ) : (
      children
    )}
  </>
);

export default ErrorCatcher;
