import { isNumber, toLower } from "./string/StringUtils";

export type direction = 1 | -1 | 0;

export const sort = (list: any[] = [], field = "id", direction: direction = 1): any[] => {
  return !!list
    ? list.sort((a: any, b: any) => (a[field] < b[field] ? -direction : a[field] > b[field] ? direction : 0))
    : [];
};

export const compare = (
  val1: number | string,
  val2: number | string,
  direction: direction = 1,
  secondarySort: (val1: number | string, val2: number | string) => direction = (
    val1: number | string,
    val2: number | string,
  ) => 0,
) => {
  if (isNumber(val1) && isNumber(val2)) {
    return val1 < val2 ? -direction : val1 > val2 ? direction : secondarySort(val1, val2);
  }
  if (isNumber(val1)) {
    return direction;
  } else if (isNumber(val2)) {
    return -direction;
  } else {
    return val1 < val2 ? -direction : val1 > val2 ? direction : secondarySort(val1, val2);
  }
};

export const sortWithFunction = (
  list: any[] = [],
  field = "id",
  direction: direction = 1,
  manipulationFunc: (a: any) => any = (a: any) => a,
  secondarySort: (a: number | string, b: number | string) => direction = () => 0,
): any[] =>
  list
    .map((a) => a)
    .sort((a, b) => {
      const val1 = manipulationFunc(a[field]);
      const val2 = manipulationFunc(b[field]);
      return compare(val1, val2, direction, secondarySort);
    });

export const sortLabel = (a: { label: string }, b: { label: string }) => {
  const label1 = toLower(a.label);
  const label2 = toLower(b.label);
  return compare(label1, label2);
};
