import React from "react";
import styled from "styled-components";

const FooterStyled = styled.footer`
  z-index: 1029;
`;

const Footer: React.FC = ({ children }) => {
  return <FooterStyled className="footer mt-auto">{children}</FooterStyled>;
};

export default Footer;
