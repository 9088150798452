import React from "react";
import { isString } from "../../utils";
import { Label } from "../inputs";

type LabelFormatterProps = {
  id: string;
  value: string | string[];
};

const LabelFormatter: React.FC<LabelFormatterProps> = ({ id, value }) => {
  let valuesToRender = null;
  if (!!value) {
    if (isString(value)) {
      valuesToRender = value;
    } else if (Array.isArray(value)) {
      valuesToRender = value.map((label: string, index: number) => (
        <Label htmlFor={label} key={`label-formatter-of-${index}`}>
          {label}
        </Label>
      ));
    }
  }
  return <div id={id}>{valuesToRender}</div>;
};

export default LabelFormatter;
