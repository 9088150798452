import React from "react";
import { useTheme } from "../../../hooks";
import ModalBody, { ModalBodyProps } from "./body/ModalBody";
import ModalFooter, { ModalFooterProps } from "./footer/ModalFooter";
import { GenericModalDiv, ModalStyles } from "./GenericModalStyles";
import ModalHeader, { ModalHeaderProps } from "./header/ModalHeader";

type GenericModalProps = {
  children: React.ReactElement[] | React.ReactElement;
  styles?: ModalStyles;
};

export interface GenericModalType extends React.FC<GenericModalProps> {
  Header: React.FC<ModalHeaderProps>;
  Body: React.FC<ModalBodyProps>;
  Footer: React.FC<ModalFooterProps>;
}

const GenericModal: GenericModalType = ({ children, styles }) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.modal, ...styles };
  return (
    <GenericModalDiv styles={StylesOverride}>
      {React.Children.map(children, (child: React.ReactElement) => {
        if (!child || !child.type || !React.isValidElement(child)) {
          return null;
        } else if (child.type === ModalHeader || child.type === ModalBody || child.type === ModalFooter) {
          return child;
        } else if (
          //@ts-ignore
          child.type?.displayName === ModalHeader.displayName ||
          //@ts-ignore
          child.type?.displayName === ModalBody.displayName ||
          //@ts-ignore
          child.type?.displayName === ModalFooter.displayName
        ) {
          return child;
        } else {
          return null;
        }
      })}
    </GenericModalDiv>
  );
};

GenericModal.Header = ModalHeader;
GenericModal.Body = ModalBody;
GenericModal.Footer = ModalFooter;

export default GenericModal;
