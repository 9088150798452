import clsx from "clsx";
import React, { useState } from "react";
import Autosuggest, {
  InputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
  Theme as AutoSuggestTheme,
} from "react-autosuggest";
import { useTheme } from "../../../hooks";
import { InputThemeType, PlaceholderThemeType } from "../../../types";
import { BaseInputProps, ReadOnlyFormatterProps } from "../../../types/InputTypes";
import { RequiredInput } from "../../required";
import { ReadOnlyFormatter } from "../input";
import Label from "../label/Label";
import { getAutocompleteTheme, SearchWrapper, SelectButton } from "./AutoCompleteStyles";
export type AutoCompleteProps<Suggestion> = BaseInputProps & {
  /** values to display for options */
  options: Suggestion[];
  /** value of autocomplete */
  value?: Suggestion;
  /** on change of the value */
  onChange: (value?: Suggestion) => void;
  /** How to display the suggestion */
  renderSuggestion: (suggestion?: Suggestion) => string;
  /** how to filter the options: */
  filterOptions?: (options: Suggestion[], value?: string) => any[];
  /** if new suggestions are requested */
  onSuggestionsFetchRequested?: (value: string) => void;
  /** overrideable styles */
  styles?: InputThemeType;
  /** if the input should clear upon selection */
  clearOnSelect?: boolean;
};

export const LocalReadOnlyFormatter: React.FC<ReadOnlyFormatterProps> = ({ value, id }) => (
  <ReadOnlyFormatter id={id} value={value} />
);

const AutoComplete = <Suggestion,>(props: AutoCompleteProps<Suggestion>) => {
  const {
    id,
    options,
    value,
    onChange,
    renderSuggestion,
    onSuggestionsFetchRequested,
    filterOptions,
    label,
    placeholder = "type here...",
    missed = false,
    disabled = false,
    required = false,
    readOnly = false,
    readOnlyFormatter = LocalReadOnlyFormatter,
    clearable = false,
    gutterBottom = false,
    className = "",
    styles = {},
    clearOnSelect = false,
  } = props;
  const { Theme } = useTheme();
  const StylesOverride: InputThemeType = { ...Theme.autocomplete, ...styles };
  const PlaceholderStyles: PlaceholderThemeType = Theme.placeholder;
  const suggestionValue = renderSuggestion(value) || "";
  const [localValue, setLocalValue] = useState<string>(suggestionValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // Update the current value on change
  const onChangeFn = (event: React.FormEvent<HTMLElement>, { newValue }: any) => {
    setLocalValue(newValue);
  };
  const clear = () => {
    setLocalValue("");
    onChange();
  };
  //call the onChange
  const onSuggestionSelected = (
    event: React.FormEvent<HTMLElement>,
    { suggestion }: SuggestionSelectedEventData<Suggestion>,
  ) => {
    onChange(suggestion);

    if (!!clearOnSelect) {
      clear();
    }
  };
  //Update suggestions
  const onSuggestionsFetchRequestLocal = ({ value }: SuggestionsFetchRequestedParams) => {
    if (!!onSuggestionsFetchRequested) {
      onSuggestionsFetchRequested(value);
    }
  };
  const handleClearClick = () => {
    setLocalValue("");
    onChange();
  };
  const shouldRenderSuggestions = (value: string, _: any) => {
    return !!value || value.trim().length > 0 || isOpen;
  };
  //Autosuggest will pass through all these props to the input.
  const inputProps: InputProps<Suggestion> = {
    placeholder: placeholder,
    className: "quick-search-input",
    value: localValue,
    onChange: onChangeFn,
    disabled: disabled,
  };
  const FinalTheme: AutoSuggestTheme = getAutocompleteTheme(StylesOverride);
  const ReadOnlyFormatterWrap = readOnlyFormatter;
  const validateFunc = (): boolean => {
    return !!localValue?.length;
  };
  return (
    <>
      {!!label && (
        <Label htmlFor={id} required={required && !readOnly} missed={missed}>
          {label}
        </Label>
      )}
      <div className={clsx({ "mb-2": gutterBottom }, className)}>
        {readOnly ? (
          <ReadOnlyFormatterWrap value={localValue} id={id} />
        ) : (
          <RequiredInput validateFunc={validateFunc} required={required}>
            <SearchWrapper
              id={id}
              styles={{
                input: StylesOverride,
                placeholder: PlaceholderStyles,
              }}
              missed={missed}
              disabled={disabled}
            >
              <Autosuggest
                theme={FinalTheme}
                suggestions={!!filterOptions ? filterOptions(options, localValue) : options}
                onSuggestionsFetchRequested={onSuggestionsFetchRequestLocal}
                getSuggestionValue={renderSuggestion}
                renderSuggestion={renderSuggestion}
                shouldRenderSuggestions={shouldRenderSuggestions}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsClearRequested={() => {}}
                inputProps={inputProps}
              />
              {clearable ? (
                <>
                  {localValue?.length || 0 >= 1 ? (
                    <SelectButton iconName="fa-times-circle" onClick={handleClearClick} disabled={disabled} />
                  ) : null}
                </>
              ) : (
                <SelectButton iconName="fa-angle-down" onClick={() => setIsOpen(!isOpen)} disabled={disabled} />
              )}
            </SearchWrapper>
          </RequiredInput>
        )}
      </div>
    </>
  );
};
export default AutoComplete;
