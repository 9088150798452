export const removeNodeById = (id: string): void => {
  const node = document.getElementById(id);
  if (node) {
    if (node.remove) {
      node.remove();
    } else {
      if (node.parentNode) {
        node.parentNode.removeChild(node);
      }
    }
  }
};

export const getTopCoord = (y: number, offsetY: number, targetHeight: number, childHeight: number): number => {
  // calculate y
  const yCoord = y - childHeight - offsetY;
  return Math.round(yCoord < 0 ? y + targetHeight + offsetY : yCoord);
};

export const getBottomCoord = (y: number, offsetY: number, targetHeight: number, childHeight: number): number => {
  // calculate y
  const yCoord =
    y + targetHeight + childHeight + offsetY > window.innerHeight
      ? y - childHeight - offsetY
      : y + targetHeight + offsetY;
  return Math.round(yCoord);
};

export const getRightCoord = (x: number, offsetX: number, targetWidth: number, childWidth: number): number => {
  // calculate x
  const xCoord =
    x + targetWidth + childWidth + offsetX > window.innerWidth ? x - childWidth - offsetX : x + targetWidth + offsetX;
  return Math.round(xCoord);
};

export const getLeftCoord = (x: number, offsetX: number, targetWidth: number, childWidth: number): number => {
  const xCoord = x - childWidth - offsetX;
  return Math.round(xCoord < 0 ? x + targetWidth + offsetX : xCoord);
};

export const isTopFlipped = (y: number, offsetY: number, childHeight: number): boolean => y - childHeight - offsetY < 0;

export const isBottomFlipped = (y: number, offsetY: number, targetHeight: number, childHeight: number): boolean =>
  y + targetHeight + childHeight + offsetY > window.innerHeight;

export const isRightFlipped = (x: number, offsetX: number, targetWidth: number, childWidth: number): boolean =>
  x + targetWidth + childWidth + offsetX > window.innerWidth;

export const isLeftFlipped = (x: number, offsetX: number, childWidth: number): boolean => x - childWidth - offsetX < 0;
