import clsx from "clsx";
import React from "react";
import { useBreakpoints, useTheme } from "../../../hooks";
import Typography from "../../../layout/typography/Typography";
import { CategoryResponses, NavigationValueThemeType } from "../../../types";
import { Icon } from "../../index";
import { NavLink, NavLinkContainer } from "./NavigationElementStyles";

type NavigationLinkProps = {
  toggleMobileNav: (value: boolean) => void;
  to?: string;
  navisactive?: boolean;
  name: string;
  icon?: string;
  displayName?: boolean;
  iconCategory?: CategoryResponses;
  vertical: boolean;
  styles: NavigationValueThemeType;
  onClick?: () => void;
};

const NavigationLink: React.FC<NavigationLinkProps> = ({
  toggleMobileNav,
  to = "",
  navisactive = false,
  name,
  vertical,
  icon,
  displayName = true,
  iconCategory,
  styles,
  onClick,
}) => {
  const { Theme } = useTheme();
  const { isMobile } = useBreakpoints();
  const defaultStyles: NavigationValueThemeType = vertical
    ? { ...Theme.navigation.vertical }
    : { ...Theme.navigation.horizontal };
  const StylesOverride: NavigationValueThemeType = { ...defaultStyles, ...styles };
  return (
    <NavLinkContainer>
      <NavLink
        to={to}
        navisactivestr={navisactive.toString()}
        onClick={() => {
          if (!!onClick) {
            onClick();
          }
          if (toggleMobileNav) {
            toggleMobileNav(false);
          }
        }}
        styles={StylesOverride}
      >
        {!!icon && (
          <Icon
            iconName={icon}
            iconCategory={iconCategory}
            className={clsx({ "me-1": displayName })}
            styles={{ color: StylesOverride.color, hoverColor: StylesOverride.hover.color }}
          />
        )}
        {displayName && (
          <Typography
            elementStyles={{
              ...styles,
              size: styles.fontSize,
              color: navisactive
                ? StylesOverride.active.color
                : isMobile
                ? StylesOverride.mobile.linkColor
                : StylesOverride.sublinks.color,
            }}
            variant="div"
          >
            {name}
          </Typography>
        )}
      </NavLink>
    </NavLinkContainer>
  );
};

export default NavigationLink;
