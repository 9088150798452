import React from "react";
import { CalendarEvent, CalendarFormatter, CalendarViewType } from "../../types";

type CalendarRenderEventProps<T> = {
  event?: CalendarEvent<T>;
  dayOfMonth?: number;
  year: number;
  month: number;
  viewType: CalendarViewType;
};

const CalendarRenderEvent = <T,>(props: CalendarRenderEventProps<T>) => {
  const { event, dayOfMonth, year, month, viewType } = props;
  if (!!event) {
    const Formatter: React.FC<CalendarFormatter<T>> = event.formatter;
    return <Formatter data={event.data} dayOfMonth={dayOfMonth} year={year} month={month} viewType={viewType} />;
  } else {
    return <div className="h-100 w-100">&nbsp;</div>;
  }
};

export default CalendarRenderEvent;
