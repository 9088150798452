import styled from "styled-components";

export type FabStyleProps = {
  styles: FabStyles;
};

export type FabStyles = {
  bottom: number;
  right: number;
};

export const FabDefaultStyles: FabStyles = {
  bottom: 20,
  right: 20,
};

export const FabContainer = styled.div<FabStyleProps>`
  position: absolute;
  bottom: ${(props) => props.styles.bottom}px;
  right: ${(props) => props.styles.right}px;
`;
