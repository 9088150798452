import React, { useRef } from "react";
import { useTheme } from "../../../hooks";
import { BuildType, NavigationValueThemeType } from "../../../types";
import Icon from "../../icon/Icon";
import MobileView from "../../view/MobileView";
import NavHamburger from "../elements/NavHamburger";
import NavigationLeftLogo from "../elements/NavigationLeftLogo";
import {
  HorizontalBarLogoHolder,
  HorizontalBarLogoLink,
  HorizontalBarLogoPaddingStyle,
  HorizontalBarNavContainer,
  HorizontalBarStyledChildren,
  HorizontalBarTitle,
  HorizontalNavHolder,
  NavIconHolder,
} from "./VerticalNavStyles";

type NavLogoProps = {
  logo?: any;
  builds: BuildType[];
  styles: NavigationValueThemeType;
};

const HorizontalNavLogo: React.FC<NavLogoProps> = ({ logo, builds = [], styles }) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  return (
    <div>
      {!!logo ? (
        <NavigationLeftLogo logo={logo} builds={builds} styles={StylesOverride} />
      ) : (
        <NavIconHolder>
          <Icon iconName="fa-baseball-ball" styles={{ color: Theme["mlb-blue"], hoverColor: Theme["mlb-blue"] }} />
        </NavIconHolder>
      )}
    </div>
  );
};

type HorizontalBarForVerticalNavProps = {
  title?: string;
  loggedIn?: boolean;
  mobileNavOpen: boolean;
  toggleMobileNav: (value: boolean) => void;
  logo?: any;
  builds?: BuildType[];
  styles: NavigationValueThemeType;
};

const HorizontalBarForVerticalNav: React.FC<HorizontalBarForVerticalNavProps> = ({
  children,
  title = "Welcome",
  loggedIn = false,
  mobileNavOpen,
  toggleMobileNav,
  logo,
  builds = [],
  styles,
}) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  const tooltipTarget = useRef(null);

  return (
    <>
      {loggedIn && (
        <HorizontalNavHolder>
          <HorizontalBarLogoHolder>
            <HorizontalBarLogoLink to="/">
              <HorizontalBarLogoPaddingStyle>
                <div ref={tooltipTarget}>
                  <HorizontalNavLogo logo={logo} builds={builds} styles={StylesOverride} />
                </div>
              </HorizontalBarLogoPaddingStyle>
            </HorizontalBarLogoLink>
          </HorizontalBarLogoHolder>

          <HorizontalBarNavContainer>
            <div className="d-flex align-self-center">
              <HorizontalBarTitle styles={StylesOverride}>{title}</HorizontalBarTitle>
              <HorizontalBarStyledChildren>{children}</HorizontalBarStyledChildren>
              <MobileView>
                {loggedIn ? <NavHamburger open={mobileNavOpen} toggle={toggleMobileNav} /> : null}
              </MobileView>
            </div>
          </HorizontalBarNavContainer>
        </HorizontalNavHolder>
      )}
    </>
  );
};

export default HorizontalBarForVerticalNav;
