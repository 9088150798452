import React from "react";
import { FormatterType } from "../../../../types";
import { DataTableDataType } from "../DataTable";

/*
 * Get the formatter and pass it props
 * We need to do this because you can either pass an element JSX (JSX)
 * Or a type as a formatter
 */
export function getFormatter<T extends DataTableDataType>(
  formatter: React.FC<FormatterType<T>>,
  props: FormatterType<T>,
): any {
  const Formatter: React.FC<FormatterType<T>> = formatter;
  if (React.isValidElement(Formatter)) {
    // formatter was passed as an element, e.g.
    // formatter: <Formatter />
    // Clone it and pass props
    return React.cloneElement(Formatter, props);
  } else if (isFunction(Formatter)) {
    // formatter was passed as a type, e.g.
    // formatter: Formatter
    // So pass it props here
    return <Formatter {...props} />;
  } else {
    return null;
  }
}

export const isFunction = (functionToCheck?: any): boolean => {
  const getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === "[object Function]";
};
