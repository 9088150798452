import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { StepperThemeType } from "../../types";
import { Base, Circle, Diamond, DiamondContent, StepContainer } from "./StepperStyles";

export type StepShapeTypes = "round" | "diamond" | "base";

export type StepProps = {
  /** index of the step */
  index?: number;
  /** if the step is active or not */
  active?: boolean;
  /** if the step is complete */
  complete?: boolean;
  /** shape of the step */
  shape?: StepShapeTypes;
  /** onClick function passing back the index */
  onClick?: (value: number) => void;
  /** label for the step */
  label?: string;
  /** content of the step */
  content?: string;
  /** overrideable styles */
  styles?: StepperThemeType;
};

const Step: React.FC<StepProps> = ({
  active = false,
  complete = false,
  index = -1,
  shape = "round",
  onClick,
  label,
  content,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: StepperThemeType = { ...Theme.stepper, ...styles };

  const clickedStep = () => {
    if (!!onClick) {
      onClick(index);
    }
  };

  const getStep = (shape: StepShapeTypes) => {
    switch (shape) {
      case "base":
        return (
          <Base styles={StylesOverride} active={active} complete={complete}>
            <DiamondContent styles={StylesOverride}>{content}</DiamondContent>
          </Base>
        );
      case "diamond":
        return (
          <Diamond styles={StylesOverride} active={active} complete={complete}>
            <DiamondContent styles={StylesOverride}>{content}</DiamondContent>
          </Diamond>
        );
      case "round":
      default:
        return (
          <Circle styles={StylesOverride} active={active} complete={complete}>
            {content}
          </Circle>
        );
    }
  };

  return (
    <StepContainer className="d-flex flex-column align-items-center" onClick={clickedStep}>
      {getStep(shape)}
      <span className="mt-1">{label}</span>
    </StepContainer>
  );
};

export default Step;
