import { OktaAuth } from "@okta/okta-auth-js";
import queryString from "query-string";
import { useEffect, useState } from "react";

type LoginFuncType = (state?: string) => void;

export const handleLogin = (authClient: OktaAuth, onLogin: LoginFuncType) => {
  authClient.tokenManager.get("accessToken").then((tokens) => {
    const windowHash = window.location.hash;
    if (tokens) {
      onLogin();
    } else if (windowHash) {
      //@ts-ignore
      authClient.token.parseFromUrl().then((token) => {
        //@ts-ignore
        token.forEach((t) => {
          if (t.idToken) {
            authClient.tokenManager.add("idToken", t);
          }
          if (t.accessToken) {
            authClient.tokenManager.add("accessToken", t);
          }
        });
        const param: any = queryString.parse(windowHash);
        const state = decodeURIComponent(param.state);
        onLogin(state);
      });
    } else {
      authClient.token.getWithRedirect({
        responseType: ["token", "id_token"],
        scopes: ["openid", "profile", "email", "groups"],
        nonce: "uSeRInfo",
        state: encodeURIComponent(window.location.pathname + window.location.search),
      });
    }
  });
  authClient.tokenManager.get("idToken");
};

export const setupRefresh = (authClient: OktaAuth) => {
  authClient.tokenManager.on("expired", () => {
    authClient.tokenManager.get("accessToken");
    authClient.tokenManager.get("idToken");
  });
};

export type OktaConfig = {
  url: string;
  clientId: string;
  issuer: string;
  redirectUri: string;
};

export const useOkta = (config: OktaConfig, onLogin: LoginFuncType) => {
  const [loginFunction, setLoginFunction] = useState({ loginFunction: () => {} });

  useEffect(() => {
    if (config) {
      const authClient: OktaAuth = new OktaAuth({
        clientId: config.clientId,
        issuer: config.issuer,
        redirectUri: config.redirectUri,
      });

      setupRefresh(authClient);
      handleLogin(authClient, onLogin);
      const loginFunc = () => handleLogin(authClient, onLogin);
      setLoginFunction({ loginFunction: loginFunc });
    }
  }, [config]);

  return loginFunction;
};
