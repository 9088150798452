import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types";
import DesktopTabletView from "../../view/DesktopTabletView";
import MobileView from "../../view/MobileView";
import { NavBarHidden, NavBarStyled } from "./VerticalNavStyles";

type NavigationVerticalProps = {
  loggedIn: boolean;
  displayName?: string;
  styles: NavigationValueThemeType;
};

const NavigationVertical: React.FC<NavigationVerticalProps> = ({ children, loggedIn = false, styles }) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  return (
    <div>
      {loggedIn && (
        <>
          <MobileView>
            <NavBarHidden>{loggedIn ? children : null}</NavBarHidden>
          </MobileView>
          <DesktopTabletView>
            <NavBarStyled styles={StylesOverride}>{loggedIn ? children : null}</NavBarStyled>
          </DesktopTabletView>
        </>
      )}
    </div>
  );
};

NavigationVertical.defaultProps = {
  displayName: "navigation-vertical",
};

export default NavigationVertical;
