import React, { createContext, useContext, useReducer } from "react";
import { PageLayoutActionType, PageLayoutActions } from "../actions/PageLayoutActions";

interface PageLayoutState {
  ref: HTMLDivElement;
}

type PageLayoutContext = {
  pageLayoutState: PageLayoutState;
  dispatchPageLayout: React.Dispatch<PageLayoutActions>;
};

const initialState: PageLayoutState = {
  ref: null,
};

const PageLayoutContext = createContext<PageLayoutContext>({
  pageLayoutState: initialState,
  dispatchPageLayout: null,
});
const reducer = (state: PageLayoutState, action: PageLayoutActions) => {
  switch (action.type) {
    case PageLayoutActionType.SetPageLayoutRef:
      return {
        ...state,
        ref: action.payload.ref,
      };
    default:
      return state;
  }
};

const PageLayoutContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PageLayoutContext.Provider
      value={{
        pageLayoutState: state,
        dispatchPageLayout: dispatch,
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};

const usePageLayout = (): PageLayoutContext => {
  const context = useContext<PageLayoutContext>(PageLayoutContext);

  if (context == undefined) {
    throw new Error("usePageLayout must be used within a PageLayoutContextProvider");
  }
  return context;
};

export { PageLayoutContextProvider, usePageLayout };
