import styled from "styled-components";
import { StepperThemeType } from "../../types";

type StepperStylesProps = {
  complete?: boolean;
  active?: boolean;
  styles: StepperThemeType;
};

export const StepContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const StepStyled = styled.div<StepperStylesProps>`
  color: ${(props) =>
    props.active ? props.styles.active.color : props.complete ? props.styles.complete.color : props.styles.color};
  background: ${(props) =>
    props.active
      ? props.styles.active.background
      : props.complete
      ? props.styles.complete.background
      : props.styles.background};
  border: 2px solid
    ${(props) =>
      props.active
        ? props.styles.active.borderColor
        : props.complete
        ? props.styles.complete.borderColor
        : props.styles.borderColor};
  width: ${(props) => props.styles.size};
  height: ${(props) => props.styles.size};
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Circle = styled(StepStyled)<StepperStylesProps>`
  border-radius: 50%;
`;

export const Diamond = styled(StepStyled)<StepperStylesProps>`
  transform: rotateX(0deg) rotateZ(45deg);
`;

export const Base = styled(StepStyled)<StepperStylesProps>`
  transform: rotateX(0deg) rotateZ(45deg);
  border-radius: 4px;
`;

export const DiamondContent = styled.div<StepperStylesProps>`
  width: calc((${(props) => props.styles.size}) - 5px);
  transform: rotateX(-25deg) rotateZ(-45deg);
`;

export const StepSpacerStyled = styled.div<StepperStylesProps>`
  height: calc((${(props) => props.styles.size} / 2) - (4 * ${(props) => props.styles.spacerWidth}));
  display: block;
  border-color: ${(props) => props.styles.spacerColor};
  border-top-style: solid;
  border-top-width: ${(props) => props.styles.spacerWidth};
  width: 100%;
  margin: 0;
`;
