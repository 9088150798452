import styled from "styled-components";
import { CollapseItemThemeType } from "../../types";

type AccordionStylesProps = {
  styles: CollapseItemThemeType;
};

// Accordion Header
export const Header = styled.div.attrs(() => ({
  className: "best-accordion-header d-flex justify-content-between",
}))<AccordionStylesProps>`
  &&& {
    width: 100%;
    background: ${(props) => props.styles.background};
    color: ${(props) => props.styles.color};
    &:hover {
      cursor: pointer;
    }
    border: ${(props) => props.styles.border};
    border-radius: 0;
  }
`;

export const ChildrenWrapper = styled.div<AccordionStylesProps>`
  color: ${(props) => props.styles.color};
  width: 100%;
  background: ${(props) => props.styles.background};
  padding: ${(props) => props.styles.padding};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

//Accordion Body
export const Body = styled.div.attrs(() => ({
  className: "best-accordion-body",
}))<AccordionStylesProps>`
  background: ${(props) => props.styles.background};
  color: ${(props) => props.styles.color};
  padding: ${(props) => props.styles.padding};
  &&& {
    border: ${(props) => props.styles.border};
    border-top: none;
  }
`;
