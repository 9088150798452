import { ColorType, ThemeType } from "../types";
import Traditional, { TraditionalColors } from "./Traditional";

export type MidnightColorType = ColorType & {
  cobalt: string;
  midnight: string;
  gold: string;
  navy: string;
  "night-blue": string;
  "background-blue": string;
  "midnight-disabled": string;
  "deep-blue": string;
};

export const MidnightColors: MidnightColorType = {
  // Default BCR Colors
  ...TraditionalColors,
  // Application Specific Colors
  cobalt: "#1b4980",
  midnight: "#010f21",
  gold: "#b29512",
  navy: "#03152e",
  slate: "#77879a",
  fog: "#dee5ed",
  "night-blue": "#061b36",
  "background-blue": "#132e4e",
  "mlb-red": "#bf0d3e",
  "midnight-disabled": "#223e5f",
  "deep-blue": "#07254d",
};

const Midnight: ThemeType = {
  ...Traditional,
  name: "Midnight",
  colors: MidnightColors,
  // Add component style overrides below.
  // See BCR themes source for examples
  label: {
    ...Traditional.label,
    color: MidnightColors.slate,
    "font-weight": "normal",
    display: "flex",
    "asterisk-color": MidnightColors.slate,
    "asterisk-margin": "0rem 0rem 0rem 0.3rem",
  },
  checkbox: {
    ...Traditional.checkbox,
    color: MidnightColors["mlb-red"],
    labelColor: MidnightColors.slate,
    background: MidnightColors.black,
    borderColor: MidnightColors["cobalt"],
  },
  icon: {
    ...Traditional.icon,
    color: MidnightColors.slate,
    hoverColor: MidnightColors["mlb-red"],
    size: "1.1rem",
  },
  dashboardIcon: {
    color: MidnightColors.slate,
    activeColor: MidnightColors.midnight,
    activeBackground: MidnightColors["mlb-red"],
  },
  button: {
    ...Traditional.button,
    "border-radius": "0.875rem",
    default: {
      "background-color": MidnightColors.slate,
      "border-color": MidnightColors["background-blue"],
      color: MidnightColors["midnight"],
      hover: MidnightColors.slate,
    },
    primary: {
      "background-color": MidnightColors["mlb-red"],
      "border-color": MidnightColors["mlb-red"],
      color: MidnightColors["midnight"],
      hover: MidnightColors["mlb-red"],
    },
    secondary: {
      "background-color": MidnightColors["night-blue"],
      "border-color": MidnightColors["night-blue"],
      color: MidnightColors.slate,
      hover: MidnightColors["night-blue"],
    },
    disabled: {
      "background-color": MidnightColors["background-blue"],
      "border-color": MidnightColors["background-blue"],
      color: MidnightColors["midnight-disabled"],
      hover: MidnightColors["background-blue"],
    },
  },
  navigation: {
    ...Traditional.navigation,
    vertical: {
      ...Traditional.navigation.vertical,
      borderColor: MidnightColors["background-blue"],
      //@ts-ignore
      "background-image": `linear-gradient(165deg, ${MidnightColors["background-blue"]} 3%, ${MidnightColors["night-blue"]} 6%)`,
      color: MidnightColors["slate"],
      background: MidnightColors["night-blue"],
    },
    //@ts-ignore
    signout: {
      color: MidnightColors["slate"],
    },
  },
  select: {
    ...Traditional.select,
    placeholder: {
      fontWeight: 300,
      color: TraditionalColors["dark-grey"],
      fontStyle: "italic",
    },
    hover: {
      color: MidnightColors["night-blue"],
      background: MidnightColors.slate,
    },
    background: MidnightColors["background-blue"],
    borderColor: MidnightColors["black"],
    color: MidnightColors["fog"],
    multiBackground: MidnightColors["slate"],
    multiBorderRadius: "15px",
    actionColor: MidnightColors["slate"],
    required: {
      color: MidnightColors["slate"],
      background: `${MidnightColors["background-blue"]} !important`,
      borderColor: MidnightColors["black"],
    },
    disabled: {
      background: MidnightColors["background-blue"],
    },
    clearIndicator: {
      padding: "8px",
    },
  },
  hint: {
    ...Traditional.hint,
    color: MidnightColors["slate"],
  },
  input: {
    ...Traditional.input,
    color: MidnightColors["fog"],
    "border-color": MidnightColors["black"],
    size: "0.875rem",
    background: `${MidnightColors["background-blue"]} !important`,
    missed: {
      color: TraditionalColors["slate"],
      background: `${MidnightColors["background-blue"]} !important`,
      "border-color": TraditionalColors["black"],
    },
    required: {
      color: TraditionalColors["slate"],
      background: `${MidnightColors["background-blue"]} !important`,
      "border-color": TraditionalColors["black"],
    },
    disabled: {
      color: TraditionalColors["slate"],
      background: TraditionalColors["midnight"],
      "border-color": TraditionalColors["black"],
    },
  },
  datatable: {
    ...Traditional.datatable,
    datatable: {
      border: "",
    },
    scrollBar: {
      background: MidnightColors["midnight"],
      element: {
        color: MidnightColors["background-blue"],
        borderColor: MidnightColors["background-blue"],
      },
    },
    header: {
      ...Traditional.datatable.header,
      background: MidnightColors["night-blue"],
      color: MidnightColors["slate"],
      borderBottomColor: MidnightColors["night-blue"],
    },
    row: {
      background: MidnightColors["midnight"],
      selectedBackground: MidnightColors["night-blue"],
      color: MidnightColors["fog"],
      separatorColor: MidnightColors["night-blue"],
      disabledBackground: TraditionalColors["grey"],
      expandedBackground: TraditionalColors["almost-white"],
    },
    column: {
      background: TraditionalColors.white,
      sortedBackground: "none",
      color: MidnightColors["fog"],
    },
    sortIcon: {
      ...Traditional.datatable.sortIcon,
      color: MidnightColors.slate,
      hoverColor: MidnightColors["mlb-red"],
    },
    moreActions: {
      ...Traditional.datatable.moreActions,
      addRowText: {
        color: MidnightColors["mlb-red"],
      },
    },
  },
  modal: {
    ...Traditional.modal,
    backdrop: MidnightColors["midnight"],
    borderColor: MidnightColors["background-blue"],
    header: {
      background: MidnightColors["night-blue"],
      fontColor: MidnightColors["slate"],
      bottomBorderColor: "unset",
      closeColor: MidnightColors["midnight"],
      padding: "1rem 1rem",
      fontWeight: "600",
      fontStyle: "italic",
      fontSize: "1.3125rem",
    },
    body: {
      background: MidnightColors["night-blue"],
      fontColor: MidnightColors["fog"],
      //@ts-ignore
      fontSize: "0.875rem",
      padding: "1rem",
    },
    footer: {
      background: MidnightColors["night-blue"],
      fontColor: MidnightColors["slate"],
      borderTopColor: "unset",
      padding: "1rem 1rem",
    },
  },
  datepicker: {
    ...Traditional.datepicker,
    input: {
      ...Traditional.datepicker.input,
      actionColor: MidnightColors["slate"],
      actionHoverColor: MidnightColors["mlb-red"],
    },
    calendar: {
      borderColor: MidnightColors["black"],
      header: {
        background: MidnightColors["night-blue"],
        color: MidnightColors["fog"],
        borderColor: MidnightColors["black"],
        select: {
          background: MidnightColors["background-blue"],
          color: MidnightColors["fog"],
        },
        dayOfWeek: {
          color: MidnightColors["slate"],
        },
      },
      body: {
        background: MidnightColors["background-blue"],
        color: MidnightColors["fog"],
        borderColor: MidnightColors["black"],
        day: {
          color: MidnightColors["fog"],
          colorOutsideMonth: MidnightColors["fog"],
          active: {
            color: MidnightColors["midnight"],
            background: MidnightColors["mlb-red"],
            borderColor: MidnightColors["midnight"],
          },
          disabled: {
            color: MidnightColors["slate"],
            background: MidnightColors["background-blue"],
            borderColor: MidnightColors["background-blue"],
          },
          hover: {
            background: MidnightColors["midnight"],
          },
        },
      },
    },
  },
  textarea: {
    ...Traditional.textarea,
    input: {
      ...Traditional.textarea.input,
      color: MidnightColors["fog"],
      background: MidnightColors["background-blue"],
      borderColor: MidnightColors["black"],
      disabled: {
        ...Traditional.textarea.input.disabled,
        color: MidnightColors["slate"],
        background: MidnightColors["midnight"],
        borderColor: MidnightColors["black"],
      },
      missed: {
        ...Traditional.textarea.input.missed,
        color: MidnightColors["slate"],
        background: `${MidnightColors["background-blue"]} !important`,
        borderColor: MidnightColors["black"],
      },
      required: {
        ...Traditional.textarea.input.required,
        color: MidnightColors["slate"],
        background: `${MidnightColors["background-blue"]} !important`,
        borderColor: MidnightColors["black"],
      },
    },
  },
  link: {
    ...Traditional.link,
    color: MidnightColors["mlb-red"],
    hover: MidnightColors["mlb-red"],
  },
  tooltip: {
    background: TraditionalColors["fog"],
    color: TraditionalColors["background-blue"],
  },
};

export default Midnight;
