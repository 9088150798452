import styled from "styled-components";

export type ModalStyles = {
  borderColor?: string;
};

type ModalThemeStyles = {
  styles: ModalStyles;
};

export const GenericModalDiv = styled.div.attrs(() => ({
  className: "bcr-modal",
}))<ModalThemeStyles>`
  &&& {
    border: 1px solid ${(props) => props.styles.borderColor};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
  }
`;
