import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types";
import { NavigationDropdownItemNavItem } from "./NavigationElementStyles";

type NavigationDropdownItemProps = {
  navisactive?: boolean;
  name: string;
  onClick?: () => void;
  styles: NavigationValueThemeType;
};

const NavigationDropdownItem: React.FC<NavigationDropdownItemProps> = ({
  navisactive = false,
  onClick,
  name,
  styles,
}) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  return (
    <li className="nav-item best-nav-dropdown-item">
      <NavigationDropdownItemNavItem navisactive={navisactive} onClick={onClick} styles={StylesOverride}>
        {name}
      </NavigationDropdownItemNavItem>
    </li>
  );
};

export default NavigationDropdownItem;
