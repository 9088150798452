import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import InactivityWarning from "./InactivityWarning";

export type InactivityMonitorModalProps = {
  header?: string;
  body?: string;
  confirm?: string;
  cancel?: string;
};

type InactivityMonitorProps = {
  duration: number;
  warningDuration: number;
  logout: () => void;
  modalProps?: InactivityMonitorModalProps;
};

const InactivityMonitor: React.FC<InactivityMonitorProps> = ({
  duration = 1000 * 60 * 25,
  warningDuration,
  logout,
  modalProps,
  children,
}) => {
  const [displayWarning, setDisplayWarning] = useState(false);

  useIdleTimer({
    timeout: duration,
    onIdle: () => setDisplayWarning(true),
  });

  return (
    <>
      {displayWarning ? (
        <InactivityWarning
          duration={warningDuration}
          onClose={() => setDisplayWarning(false)}
          logout={logout}
          modalProps={modalProps}
        />
      ) : (
        children
      )}
    </>
  );
};

export default InactivityMonitor;
