import styled from "styled-components";
import { FileAttachmentThemeType } from "types";

type FileAttachmentStylesType = {
  isDragActive?: boolean;
  styles: FileAttachmentThemeType;
};

export const DropArea = styled.div<FileAttachmentStylesType>`
  flex: 1;
  margin-right: 5px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  border: ${(props) =>
    props.isDragActive
      ? `2px solid ${props.styles.dropArea.activeBorderColor}`
      : `2px dashed ${props.styles.dropArea.borderColor}`};
`;

export const DropAreaWrapper = styled.div`
  min-height: 36px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
`;

export const DropAreaText = styled.span<FileAttachmentStylesType>`
  font-size: 14px;
  font-weight: 300;
  font-style: oblique;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${(props) => props.styles.dropAreaText.color};
`;

export const Label = styled.div<FileAttachmentStylesType>`
  width: 156px;
  height: 25px;
  background-color: ${(props) => props.styles.label.backgroundColor};
  border: solid 1px ${(props) => props.styles.label.border};
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadLinkHolder = styled.div.attrs(() => ({
  className: "d-flex justify-content-between align-items-center px-2",
}))`
  width: 100%;
  height: 100%;
`;

export const DownloadLink = styled.a.attrs(() => ({
  className: "mr-1",
}))`
  &&& {
    color: #2b77eb;
    &:hover {
      cursor: pointer;
    }
    text-decoration: none;
  }
`;
