import React from "react";
import { FormatterType } from "../../../../types";
import { DATE_FORMATTER_STR_WITH_TIME, formatDate } from "../../../../utils";
import { DataTableDataType } from "../DataTable";
import TextFormatter from "./TextFormatter";

const DateTimeFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value, ...rest } = props;
  if (!!value) {
    const dateValue = formatDate(new Date(value), DATE_FORMATTER_STR_WITH_TIME);
    return <TextFormatter value={dateValue} {...rest} />;
  } else {
    return <span />;
  }
};

export default DateTimeFormatter;
