import { Icon, Label } from "best-common-react";
import { LabelStyles } from "best-common-react/lib/esm/form/inputs/label/LabelStyles";
import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { WidgetWrapper } from "../common/CommonStyledComponents";

export const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props): string => props.theme.label.color};
  padding-bottom: 10px;
`;

export const SubTitle = styled(Title)`
  font-size: 0.775rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableLabel = styled.div`
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${(props): string => props.theme.label.color};
  text-align: left;
`;

export const TableValue = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
  font-size: 0.875rem;
  font-weight: normal;
  color: ${(props): string => props.theme.colors.fog};
`;

export const Row = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem;
  ${props =>
    props.disabled &&
    `
    .fa-caret-down {
      color: ${props.theme.colors.midnight} !important;
    }
  `}
`;

export const ReadOnlyValue = styled.div`
  font-size: 0.875rem;
  color: ${(props): string => props.theme.colors.fog};
`;
export const ReadOnlyTableValue = styled(ReadOnlyValue)`
  margin-left: 16px;
`;

export const DashboardWidgetWrapper = styled(WidgetWrapper)`
  padding: 18px;
  .center {
    justify-content: center;
  }
`;

export const DashboardWidgetWrapperNoGradient = styled(DashboardWidgetWrapper)`
  background: ${(props): string => props.theme.colors["night-blue"]};
  height: fit-content;
  width: fit-content;
`;

type LeftLabelledRowItemProps = {
  required?: boolean;
  label: string;
  labelWidth?: number;
  valueWidth?: number;
  utilElement?: ReactElement;
  labelStyles?: LabelStyles;
  rowHeight?: number;
};

// TODO: Should we replace instances of this with LeftLabelledRow?
export const LeftLabelledRowItem: React.FC<LeftLabelledRowItemProps> = ({
  required = false,
  label,
  children,
  utilElement,
  labelWidth = 150,
  valueWidth = 200,
  labelStyles,
  rowHeight,
}) => {
  return (
    <Row style={{ height: rowHeight ? `${rowHeight}px` : "" }} disabled={children?.props?.disabled}>
      <Column style={{ width: `${labelWidth}px`, marginRight: "0.5rem" }}>
        <Label htmlFor={label} required={required} styles={labelStyles}>
          {label}
        </Label>
      </Column>
      <Column style={{ width: `${valueWidth}px` }}>{children}</Column>
      {utilElement && <Column style={{ marginLeft: "0.5rem" }}>{utilElement}</Column>}
    </Row>
  );
};

type LeftLabelledRowProps = {
  required?: boolean;
  label: string;
  utilElement?: ReactElement;
  rowStyles?: any;
  labelColStyles?: any;
  labelStyles?: LabelStyles;
  valueColStyles?: any;
};

export const LeftLabelledRow: React.FC<LeftLabelledRowProps> = ({
  required = false,
  label,
  children,
  utilElement,
  rowStyles = {},
  labelColStyles = {},
  labelStyles,
  valueColStyles,
}) => {
  const labelColStyle = { minWidth: "150px", width: "150px", arginRight: "0.5rem", ...labelColStyles };
  const valueColStyle = { width: "200px", ...valueColStyles };

  return (
    <Row style={rowStyles} disabled={children?.props?.disabled}>
      <Column style={labelColStyle}>
        <Label htmlFor={label} required={required} styles={labelStyles}>
          {label}
        </Label>
      </Column>
      <Column style={valueColStyle}>{children}</Column>
      {utilElement && <Column style={{ marginLeft: "0.5rem" }}>{utilElement}</Column>}
    </Row>
  );
};

export enum StatusColorType {
  Red = "RED",
  Green = "GREEN",
  Yellow = "YELLOW",
  Orange = "ORANGE",
}

export const getStatusColorTypeToggle = (isGreen: boolean): StatusColorType => {
  return isGreen ? StatusColorType.Green : StatusColorType.Red;
};

export type StatusProps = {
  color?: StatusColorType;
  diameter?: number;
};

export const StatusIcon = styled.div<StatusProps>`
  background-color: ${(props): string => {
    switch (props.color) {
      case StatusColorType.Orange:
        return props.theme.colors.orange;
      case StatusColorType.Green:
        return props.theme.colors.green;
      case StatusColorType.Yellow:
        return props.theme.colors.gold;
      case StatusColorType.Red:
        return props.theme.colors["mlb-red"];
      default:
        return "white";
    }
  }};
  border: 1px solid ${(props): string => props.theme.colors.midnight};
  border-radius: 50%;
  height: ${(props): string => (props.diameter ? `${props.diameter}rem` : "1rem")};
  width: ${(props): string => (props.diameter ? `${props.diameter}rem` : "1rem")};
`;

export const CollapseToggle = styled.div`
  margin-top: 0.5rem;
  cursor: pointer;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: bold;
  color: ${(props): string => props.theme.colors["mlb-red"]};
`;

export const getStatusColor = (count: number, total: number): StatusColorType => {
  if (count === total) {
    return StatusColorType.Green;
  } else if (count > 0 && count < total) {
    return StatusColorType.Yellow;
  }
  return StatusColorType.Red;
};

export const InputCollapse: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div>
      <div>{open && children}</div>
      <CollapseToggle onClick={() => setOpen(!open)}>{open ? "show less" : "show more"}</CollapseToggle>
    </div>
  );
};

export const InputRowBackground = styled(Row)`
  background-color: ${(props): string => props.theme.colors.midnight};
  padding: 2px;
  margin-bottom: 2px;
  > div:not(:last-child) {
    margin-right: 1px;
  }
`;

type SelectorProps = {
  selected: boolean;
};

export const Selector = styled.div<SelectorProps>`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${(props): string =>
    props.selected ? props.theme.colors["mlb-red"] : props.theme.colors.midnight};
  color: ${(props): string => (props.selected ? props.theme.colors.black : props.theme.colors["mlb-red"])};
  padding: 0.75rem;
  cursor: pointer;
  border: 1px solid ${(props): string => (props.selected ? props.theme.colors.black : props.theme.colors.midnight)};
  margin-left: 0.25rem;
`;

export const ActiveIcon = styled(Icon).attrs({
  iconName: "fa-chevron-right",
})`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(props): string => props.theme.colors.midnight} !important;
  &:hover {
    color: ${(props): string => props.theme.colors.midnight} !important;
  }
`;
