import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { StepperThemeType } from "../../types/ThemeType";
import { StepSpacerStyled } from "./StepperStyles";

type StepSpacerProps = {
  styles?: StepperThemeType;
};

const StepSpacer: React.FC<StepSpacerProps> = ({ styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: StepperThemeType = { ...Theme.stepper, ...styles };

  return <StepSpacerStyled styles={StylesOverride} />;
};

export default StepSpacer;
