import React from "react";
import { useTheme } from "../../../hooks";
import { SubSectionThemeType } from "../../../types";
import { SubSectionBody, SubSectionHeader } from "./SubSectionStyles";

type SubSectionProps = {
  header: React.ReactNode;
  styles?: SubSectionThemeType;
};

const SubSection: React.FC<SubSectionProps> = ({ children, header, styles }) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.subSection, ...styles };

  return (
    <div className="bcr-subsection">
      <SubSectionHeader styles={StylesOverride}>{header}</SubSectionHeader>
      <SubSectionBody>{children}</SubSectionBody>
    </div>
  );
};

export default SubSection;
