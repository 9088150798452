import { FormatterType, Select, ValueOpt } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { DashboardConstants } from "../../../constants/DashboardConstants";
import { GameAdditionalSsmo, GameAnglesNotShown, GameCameraInput, GameHfrSsmo } from "../../../types/CamTypes";
import { getPatch, Patch } from "../../common/Patch";
import { primitiveToOption } from "../../common/ValueOptHelper";
import { ReadOnlyTableValue } from "../DashboardStyledComponents";
import { MidnightColors } from "../../../theme/Midnight";

type StyledSelectProps = {
  $highlighted?: boolean;
};

const StyledSelect = styled(Select)<StyledSelectProps>`
  ${props => (!!props.$highlighted ? `border: 1px solid ${MidnightColors.yellow};` : "")}
`;

export type GameAngle = GameHfrSsmo | GameAdditionalSsmo | GameAnglesNotShown | GameCameraInput;

export function saveAngle<T>(
  form: T,
  selected: ValueOpt<any>,
  field: string,
  sequence: number,
  gamePk: number,
  dispatch: React.Dispatch<any>,
  saveApi: (data: T) => Promise<T>,
  updateApi: (dashboard: string, id: number, patch: Patch[]) => Promise<T>,
  type: string,
  dashboard: string,
): void {
  const updateForm = (response: T): void => {
    dispatch({
      type: "UPDATE_ANGLE",
      payload: {
        field: type,
        data: response,
      },
    });
  };

  const getState = () => {
    if (dashboard !== DashboardConstants.CAM.value) return "COPY";
    return "INITIAL";
  };

  // POST
  if (!form?.["id"]) {
    saveApi({ ...form, state: getState(), sequence, gamePk, [field]: selected?.value }).then(res => updateForm(res));
  }
  // PATCH
  else {
    updateApi(dashboard, form?.["id"], getPatch(true, `/${field}`, selected?.value)).then(res => updateForm(res));
  }
}

export const getFeedOptions = (options: ValueOpt<any>[], angleName: string) => {
  if (angleName?.includes("MLB")) {
    return options.filter(opt => opt.label === "MLB");
  }
  return options;
};

export interface AngleSelectProps<T> extends FormatterType<any> {
  gamePk: number;
  form: T;
  options?: ValueOpt<any>[];
  field: string;
  width?: number;
  getValue: (data: any) => ValueOpt<any>;
  saveApi: (data: T) => Promise<T>;
  updateApi: (dashboard: string, id: number, patch: Patch[]) => Promise<T>;
  type: string;
  dispatch: React.Dispatch<any>;
  locked: boolean;
  dashboard: string;
  highlighted?: boolean;
}

export const AngleSelect: React.FC<AngleSelectProps<
  GameHfrSsmo | GameAnglesNotShown | GameCameraInput | GameAdditionalSsmo
>> = ({
  row,
  gamePk,
  form,
  options,
  dispatch,
  field,
  width,
  getValue,
  saveApi,
  updateApi,
  type,
  locked,
  dashboard,
  highlighted = false,
}) => {
  const style = { padding: "2px" };

  if (width) {
    style["width"] = `${width}px`;
  } else {
    style["width"] = "100%";
  }

  return (
    <>
      {locked ? (
        <ReadOnlyTableValue>{getValue(form?.[field])?.label}</ReadOnlyTableValue>
      ) : (
        <div style={style}>
          <StyledSelect
            id={row?.label}
            placeholder=""
            value={getValue(form?.[field])}
            clearable={true}
            options={options}
            onChange={(data: ValueOpt<any>) =>
              saveAngle(form, data, field, row.sequence, gamePk, dispatch, saveApi, updateApi, type, dashboard)
            }
            $highlighted={highlighted}
          />
        </div>
      )}
    </>
  );
};
