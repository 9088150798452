import React from "react";
import { useTheme } from "../../../hooks";
import { CardThemeType } from "../../../types/ThemeType";
import { CardFooterDiv } from "./CardStyles";

export type CardFooterProps = {
  displayName?: string;
  styles?: CardThemeType;
};

const CardFooter: React.FC<CardFooterProps> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: CardThemeType = { ...Theme.card, ...styles };

  return (
    <CardFooterDiv styles={StylesOverride} {...rest}>
      {children}
    </CardFooterDiv>
  );
};

export default CardFooter;
