import React from "react";
import { useTheme } from "../../../hooks";
import { EnviromentType, NavigationValueThemeType } from "../../../types";
import DesktopTabletView from "../../view/DesktopTabletView";
import EnvIndicator from "../env/EnvIndicator";
import { NavbarSignoutEnvHolder, NavbarSignoutPermissionsHolder, NavBarSignoutStyle } from "./NavigationElementStyles";

type NavbarSignoutProps = {
  loggedIn: boolean;
  horizontal: boolean;
  displayEnvIndicator?: boolean;
  env: EnviromentType;
  displayPermissionsIndicator?: boolean;
  permissions?: string;
  displayName?: string;
  styles: NavigationValueThemeType;
};

const NavbarSignout: React.FC<NavbarSignoutProps> = ({
  loggedIn,
  children,
  displayEnvIndicator = false,
  env,
  displayPermissionsIndicator = false,
  permissions = "",
  horizontal,
  styles,
}) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation, ...styles };
  return (
    <div>
      <DesktopTabletView>
        {loggedIn ? (
          <NavBarSignoutStyle styles={StylesOverride} horizontal={horizontal}>
            {displayEnvIndicator ? (
              <NavbarSignoutEnvHolder>
                <EnvIndicator env={env} />
              </NavbarSignoutEnvHolder>
            ) : (
              ""
            )}
            {displayPermissionsIndicator ? (
              <NavbarSignoutPermissionsHolder>{permissions}</NavbarSignoutPermissionsHolder>
            ) : (
              ""
            )}
            <div className="ms-auto">{children}</div>
          </NavBarSignoutStyle>
        ) : (
          ""
        )}
      </DesktopTabletView>
    </div>
  );
};

NavbarSignout.defaultProps = {
  displayName: "nav-bar-signout",
};

export default NavbarSignout;
