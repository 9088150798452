import React from "react";
import { HeroImageThemeType } from "types";
import { useTheme } from "../../hooks/useTheme";
import { HeroImageStyles } from "./HeroImageStyles";

type HeroImageProps = {
  /** background image used */
  image: string;
  /** Overrideable styles */
  styles?: HeroImageThemeType;
};

const HeroImage: React.FC<HeroImageProps> = ({ children, image, styles = {} }) => {
  const { Theme } = useTheme();
  const StyleOverrides: HeroImageThemeType = {
    ...Theme.heroImage,
    ...styles,
  };

  return (
    <HeroImageStyles image={image} styles={StyleOverrides}>
      {children}
    </HeroImageStyles>
  );
};

export default HeroImage;
