import React, { useEffect } from "react";
import styled from "styled-components";
import { getSFGameSetting } from "../../../../api/CamApi";
import StickyComponent from "../../../common/StickyComponent";
import { CameraAction, useCamera } from "../../../../contexts/CameraContext";
import { GameDataDTO, ScheduleDTO } from "../../../../types/DailyAssignmentTypes";
import CopyFrom from "./CopyFrom";
import Games from "./Games";
import SeriesDetails from "./SeriesDetails";
import { format } from "date-fns";
import SetDefault from "./SetDefault";
import { usePageLayout } from "../../../../src-scheduler/contexts/PageLayoutContext";

const CameraGrid = styled.div`
  display: grid;
  grid-template-columns: 310px auto;
`;

interface Props {
  dashboardGame: ScheduleDTO;
}

export const getGameInfo = (game: GameDataDTO): React.ReactElement => {
  let gameDateTime: Date;
  let formattedDateTime: string;

  if (game?.displayTime?.includes("TBD")) {
    gameDateTime = new Date(`${game.gameDt}`);
    formattedDateTime = format(gameDateTime, "M/dd • ").toLowerCase() + "TBD";
  } else {
    gameDateTime = new Date(`${game.gameDt} ${game.gameTime}`);
    formattedDateTime = format(gameDateTime, "M/dd • h:mm a").toLowerCase();
  }
  return (
    <>
      <div>
        {game?.away?.abbreviation || "TBD"} @ {game?.home?.abbreviation || "TBD"} • {game?.gamePk}
      </div>
      <div>{game.status === "PPD" ? "PPD" : formattedDateTime}</div>
    </>
  );
};

const CameraGameView: React.FC<Props> = ({ dashboardGame }) => {
  const { dispatchCamera } = useCamera();
  const { pageLayoutState } = usePageLayout();

  useEffect(() => {
    if (dashboardGame) {
      getSFGameSetting(
        dashboardGame.gameDt
          .split("")
          .map((c: string): string => (c === "/" ? "-" : c))
          .join(""),
        dashboardGame.home.orgId.toString(),
        dashboardGame.gamePk.toString(),
      ).then(response => {
        dispatchCamera({
          type: CameraAction.LOAD_SERIES,
          payload: { series: response, selectedGame: dashboardGame },
        });
      });
    }
  }, [dashboardGame]);

  return (
    <CameraGrid>
      <StickyComponent scrollParent={pageLayoutState?.ref}>
        <div style={{ marginRight: "0.5rem", height: "100vh" }}>
          <Games />
          <SetDefault />
          <CopyFrom />
        </div>
      </StickyComponent>
      <SeriesDetails />
    </CameraGrid>
  );
};

export default CameraGameView;
