import styled from "styled-components";

export const LabelHolder = styled.div`
  margin-top: -3px;
`;

export type RadioStyles = {
  paddingBottom?: string;
  paddingTop?: string;
  marginBottom?: string;
  marginTop?: string;
  height?: number | string;
  width?: number | string;
  fontSize?: number | string;
};

type RadioStylesProps = {
  styles: RadioStyles;
};

export const RadioInputStyled = styled.input.attrs(() => ({
  className: "bcr-radio-input",
  type: "radio",
}))<RadioStylesProps>`
  &&& {
    margin-right: 0.5rem;
    height: ${(props) => (props.styles.height ? props.styles.height : 13)};
    width: ${(props) => (props.styles.width ? props.styles.width : 13)};
  }
`;

export const RadioStyled = styled.div.attrs(() => ({
  className: "bcr-radio d-flex align-items-center",
}))<RadioStylesProps>`
  &&& {
    margin-right: 1rem;
    margin-bottom: ${(props) => (props.styles.marginBottom ? props.styles.marginBottom : "0")};
    margin-top: ${(props) => (props.styles.marginTop ? props.styles.marginTop : "0")};
    padding-bottom: ${(props) => (props.styles.paddingBottom ? props.styles.paddingBottom : "0")};
    padding-top: ${(props) => (props.styles.paddingTop ? props.styles.paddingTop : "0")};
  }
`;
