import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import { useTheme } from "../../../hooks";
import { ModalThemeType } from "../../../types";
import { ModalBodyProps, ModalFooterProps, ModalHeaderProps } from "../../modal";
import GenericModal from "../../modal/genericModal/GenericModal";
import GenericPopover, { GenericPopoverProps, PopoverRef } from "../generic/GenericPopover";
import PopoverBody from "./PopoverBody";
import PopoverFooter from "./PopoverFooter";
import PopoverHeader from "./PopoverHeader";

export interface PopoverProps extends GenericPopoverProps {
  children: React.ReactElement[] | React.ReactElement;
  styles?: ModalThemeType;
}

//allow undefined at construction
export interface PopoverComponentSubType
  extends ForwardRefExoticComponent<PropsWithoutRef<PopoverProps> & RefAttributes<PopoverRef>> {
  Header?: React.FC<ModalHeaderProps>;
  Body?: React.FC<ModalBodyProps>;
  Footer?: React.FC<ModalFooterProps>;
}

//force them to be defined when exported
export interface PopoverComponentType extends PopoverComponentSubType {
  Header: React.FC<ModalHeaderProps>;
  Body: React.FC<ModalBodyProps>;
  Footer: React.FC<ModalFooterProps>;
}

const PopoverSub: PopoverComponentSubType = forwardRef<PopoverRef, PopoverProps>(
  ({ children, styles = {}, ...rest }, ref) => {
    const { Theme } = useTheme();
    const StylesOverride: ModalThemeType = { ...Theme.popover, ...styles };
    return (
      <GenericPopover ref={ref} {...rest}>
        <GenericModal styles={StylesOverride}>{children}</GenericModal>
      </GenericPopover>
    );
  },
);

PopoverSub.Header = PopoverHeader;
PopoverSub.Body = PopoverBody;
PopoverSub.Footer = PopoverFooter;

const Popover = PopoverSub as PopoverComponentType;

export default Popover;
