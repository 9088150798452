import React from "react";
import { useTheme } from "../../../hooks";
import { NavigationValueThemeType } from "../../../types";
import { Hamburger } from "./NavigationElementStyles";

type NavHamburgerProps = {
  open: boolean;
  toggle: (value: boolean) => void;
  styles?: NavigationValueThemeType;
};

const NavHamburger: React.FC<NavHamburgerProps> = ({ open, toggle, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: NavigationValueThemeType = { ...Theme.navigation.horizontal, ...styles };
  const mobileNavClass = open ? "mobile-nav-open" : "mobile-nav-closed";
  return (
    <Hamburger
      className={`navbar-toggler navbar-toggler-right custom-toggler pull-left me-1 ${mobileNavClass}`}
      onClick={() => toggle(!open)}
      styles={StylesOverride}
    >
      <span className="icon-bar top-bar" />
      <span className="icon-bar middle-bar" />
      <span className="icon-bar bottom-bar" />
      <span className="sr-only">Toggle navigation</span>
    </Hamburger>
  );
};

export default NavHamburger;
