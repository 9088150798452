import React from "react";
import { InputProps } from "../input";
import Input from "../input/Input";

const Zipcode: React.FC<InputProps> = ({ onChange, ...rest }) => {
  const handleChange = (value: string) => {
    value = value.substring(0, 5);
    onChange(value);
  };

  return <Input {...rest} onChange={handleChange} type="number" />;
};

export default Zipcode;
