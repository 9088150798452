import React from "react";
import Typography from "../../../layout/typography/Typography";
import { CategoryResponses, NavigationLinkType, NavigationValueThemeType } from "../../../types";
import Icon from "../../icon/Icon";
import DesktopTabletView from "../../view/DesktopTabletView";
import MobileView from "../../view/MobileView";
import {
  MobileCategory,
  WithSublinksDropdownHolder,
  WithSublinksDropdownMenu,
  WithSublinksDropdownMenuItem,
  WithSublinksMobileHolder,
  WithSublinksMobileLink,
  WithSublinksNavLink,
} from "./NavigationElementStyles";

type IconNavigationWithSubLinksType = {
  toggleMobileNav?: (value: boolean) => void;
  subLinks: NavigationLinkType[];
  icon?: string;
  iconCategory?: CategoryResponses;
  name: string;
  navisactive: boolean;
  styles: NavigationValueThemeType;
  horizontal: boolean;
};

const checkForActive = (to?: string): boolean => to === location?.pathname;

const IconNavigationWithSubLinks: React.FC<IconNavigationWithSubLinksType> = ({
  toggleMobileNav,
  subLinks,
  icon = "",
  iconCategory,
  name,
  navisactive,
  styles,
  horizontal,
}) => {
  return (
    <>
      <DesktopTabletView>
        <WithSublinksDropdownHolder>
          <WithSublinksNavLink navisactive={navisactive} styles={styles}>
            {icon && <Icon iconName={icon} styles={{ color: navisactive ? styles.active.color : styles.color }} />}
            {name && (
              <span style={{ marginLeft: icon ? "10px" : "" }}>
                <Typography
                  elementStyles={{
                    ...styles,
                    size: styles.fontSize,
                    color: navisactive ? styles.active.color : styles.color,
                  }}
                  variant="div"
                >
                  {name}
                </Typography>
              </span>
            )}
          </WithSublinksNavLink>
          <WithSublinksDropdownMenu styles={styles} horizontal={horizontal}>
            {subLinks.map(({ to, name }) => {
              const sublinkActive = checkForActive(to);
              return (
                <WithSublinksDropdownMenuItem
                  styles={styles}
                  navisactive={sublinkActive}
                  key={name}
                  to={to || ""}
                  onClick={() => {
                    if (!!toggleMobileNav) {
                      toggleMobileNav(false);
                    }
                  }}
                >
                  <Typography
                    elementStyles={{
                      ...styles,
                      size: styles.fontSize,
                      color: sublinkActive ? styles.sublinks.hover.color : styles.sublinks.color,
                    }}
                    variant="div"
                  >
                    {name}
                  </Typography>
                </WithSublinksDropdownMenuItem>
              );
            })}
          </WithSublinksDropdownMenu>
        </WithSublinksDropdownHolder>
      </DesktopTabletView>
      <MobileView>
        <MobileCategory>
          <Icon
            iconName={icon}
            iconCategory={iconCategory}
            styles={{ color: styles.color, hoverColor: styles?.hover?.color }}
            className="me-3"
          />{" "}
          {name}
        </MobileCategory>
        <WithSublinksMobileHolder styles={styles}>
          {subLinks.map(({ to, name }) => (
            <li key={name}>
              <WithSublinksMobileLink
                styles={styles}
                to={to || ""}
                onClick={() => {
                  if (toggleMobileNav) {
                    toggleMobileNav(false);
                  }
                }}
              >
                <Typography elementStyles={{ ...styles, size: styles.fontSize }} variant="div">
                  {name}
                </Typography>
              </WithSublinksMobileLink>
            </li>
          ))}
        </WithSublinksMobileHolder>
      </MobileView>
    </>
  );
};

export default IconNavigationWithSubLinks;
