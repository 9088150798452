import React, { useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import styled from "styled-components";
import { useTheme } from "../../hooks/useTheme";
import { DatatableThemeType } from "../../types";
import Pagination, { PaginationType } from "../table/datatable/Pagination";
import { AccordionProps } from "./index";

type MobileAccordionStyleProps = {
  height?: number;
};

const Holder = styled.div`
  position: relative;

  *:focus {
    outline: none;
  }
`;

const SearchResultsHolder = styled.div<MobileAccordionStyleProps>`
  overflow: visible;
  height: ${(props) => props.height}px;
`;

export type CustomMobileAccordionType<MobileAccordingDataType> = AccordionProps & {
  data: MobileAccordingDataType;
  index: number;
};

type MobileAccordionProps<MobileAccordingDataType> = {
  /** height of the mobile holder */
  holderHeight: number;
  /** data for accordions */
  data: MobileAccordingDataType[];
  /** component to display the data */
  accordion: React.FC<CustomMobileAccordionType<MobileAccordingDataType>>;
  /** scroll to the specfic value */
  scrollToIndex?: number;
  /** if the mobile accordions are virtualized */
  virtualized?: boolean;
  /** object to display and control pagination */
  pagination?: PaginationType;
};

const MobileAccordions = <MobileAccordingDataType,>(props: MobileAccordionProps<any>) => {
  const { holderHeight, data, accordion, scrollToIndex, virtualized = true, pagination } = props;
  let openAccordions = {};
  const list = useRef<List>();
  const CustomAccordion: React.FC<CustomMobileAccordionType<MobileAccordingDataType>> = accordion;
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable };

  const cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100,
  });

  const recompute = (index?: number) => {
    setTimeout(() => {
      if (typeof index != "undefined" && index > -1) {
        cellMeasurerCache.clear(index, 0);
      }
      if (!!list?.current?.recomputeRowHeights) {
        list!.current!.recomputeRowHeights(index);
      }
    }, 10);
  };

  const accordionChanged = (index: number, newState: boolean) => {
    const value = { ...openAccordions };
    value[index] = newState;
    openAccordions = value;
    recompute(index);
  };

  type RowRendererProps = {
    index: number;
    key: string;
    style: object;
    parent: any;
  };

  const RowRenderer: React.FC<RowRendererProps> = ({ index, key, style, parent }) => (
    <CellMeasurer key={key} cache={cellMeasurerCache} parent={parent} columnIndex={0} rowIndex={index}>
      <div className="pb-2 mb-2" key={index} style={style}>
        {/*@ts-ignore*/}
        <CustomAccordion
          data={data[index]}
          index={index}
          accordionKey={`mobile-accordion-${index}`}
          startOpen={openAccordions[index]}
          onClick={(newState: boolean) => accordionChanged(index, newState)}
          animation={false}
        />
      </div>
    </CellMeasurer>
  );

  const paginationChanged = () => {
    if (!!list?.current?.scrollToRow) {
      list.current.scrollToRow(0);
    }
  };

  return (
    <>
      <Holder>
        {virtualized ? (
          <SearchResultsHolder height={holderHeight}>
            {!!data?.length && (
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <List
                      //@ts-ignore
                      ref={list}
                      width={width}
                      height={height}
                      rowCount={data.length}
                      rowHeight={cellMeasurerCache.rowHeight}
                      rowRenderer={RowRenderer}
                      scrollToIndex={scrollToIndex}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </SearchResultsHolder>
        ) : (
          <>
            {data.map((d: MobileAccordingDataType, index: number) => (
              <div className="pb-2 mb-2" key={index}>
                {/*@ts-ignore*/}
                <CustomAccordion
                  data={d}
                  index={index}
                  accordionKey={`mobile-accordion-${index}`}
                  startOpen={openAccordions[index]}
                  onClick={(newState: boolean) => accordionChanged(index, newState)}
                  animation={false}
                />
              </div>
            ))}
          </>
        )}
      </Holder>
      {!!pagination && (
        <Pagination
          pagination={pagination}
          onValueChanged={paginationChanged}
          styles={StylesOverride}
          className="mt-2"
        />
      )}
    </>
  );
};

export default MobileAccordions;
